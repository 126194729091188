/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';

import { stripslashes } from "../../Helpers/SettingHelper";
var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestContactinfoData,requestFooterData,requestFollowusData,requestMenuData
} from '../../modules/ui/actions';

import footerbg from "../../common/images/footerbg.png";
import logo from "../../common/images/logo-footer.png";
import FoodLine_Logo from "../../common/images/FoodLine_Logo.png";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {menuData:[], };
	}
	componentDidMount() {
		 this.props.requestContactinfoData();
		 this.props.requestFooterData();
		 this.props.requestFollowusData();
         this.props.requestMenuData('Main-menu-u1g09mdhvtev7lrawiqp8ofqs');

	}
	
		componentWillReceiveProps(nextProps){
				if(nextProps.menuData !==this.props.menuData){
					this.setState({menuData: nextProps.menuData.result_set});
				}
		 
		}
  
	  createLink(menu){

		if(menu.nav_type==="0")
		{
	   
		return  <Link to={"/page/"+menu.nav_title_slug} title={menu.nav_title}><span>{menu.nav_title}</span></Link>;
		
		
		}
		else if(menu.nav_type==="3")
		{  

			return  <a href={menu.nav_pages} title={menu.nav_title} target={ (menu.nav_link_type == 'blank') ?'_blank':''}><span>{menu.nav_title}</span></a>;
		}
		
		
		
	  }
	  
	  listMainNavigation(){
		
		   if (this.state.menuData) {
	 
            return this.state.menuData.map(function (menu, i) {
                  return  (<li key={i+100}>{this.createLink(menu)}</li>);
            },this)
                     
        }

	}

	render() {
	
		let conactinfo = '';

		if(this.props.footercontactinfoData) {

			if(this.props.footercontactinfoData.status == 'ok') {

				conactinfo = Parser(stripslashes(this.props.footercontactinfoData.result_set[0].staticblocks_description));
			}
		}

		
		let footerinfo = '';

		if(this.props.footerData) {

			if(this.props.footerData.status == 'ok') {

				footerinfo = Parser(stripslashes(this.props.footerData.result_set[0].staticblocks_description));
			}
		}

		let facebook_url = '';
		let youtube_url = '';
		let instagram_url = '';
		
		let followus;

		if(this.props.followusData) {

			facebook_url = (this.props.followusData.result_set.socailnetwork_facebook_url!='')?<li><a href={this.props.followusData.result_set.socailnetwork_facebook_url}><i className="fa fa-facebook-square"></i></a></li>:'';

			youtube_url = (this.props.followusData.result_set.socailnetwork_youtube_url!='')?<li><a href={this.props.followusData.result_set.socailnetwork_youtube_url}><i className="fa fa-youtube"></i></a></li>:'';

			instagram_url = (this.props.followusData.result_set.socailnetwork_instagram_url!='')?<li><a href={this.props.followusData.result_set.socailnetwork_instagram_url}><i className="fa fa-instagram"></i></a></li>:'';

			if(facebook_url != '' || youtube_url != '' || instagram_url != '') {
				followus = <li><a href="javascript:void(0);">Follow Us</a></li>;
			}

		}


		return (
			<footer>
				<div className="footer">
				   <div className="footer_top"><img src={footerbg}/> </div>
				   <div className="footer-inner">
					  <div className="row">
						 <div className="col-sm-3 addr">
							{conactinfo}
						 </div>
						 <div className="col-sm-6 foot-menu">
						 
							<Link to={"/"} className="logof" title="Ishiro">
							<span><img src={logo} alt="Ishiro"/></span>
							</Link>
						 
							
							<ul>
							   {this.state.menuData && this.listMainNavigation()}
							</ul>
						 </div>
						 <div className="col-sm-3 stay-connect">
						{/* <a href="https://www.foodline.sg/" target="_blank" className="FoodLine_Logo">
							<img src={FoodLine_Logo} alt="FoodLine Logo" />
						 </a>*/}
							<ul>
							  {followus}
							  {facebook_url}
							  {instagram_url}
							  {youtube_url}
							</ul>
						 </div>
					  </div>
					  <div className="clearfix"></div>					  
				   </div>
				   <div className="copyright">
					 <div className="copyright_inner">
						{footerinfo}
					 </div>
				  </div>
				</div>
				</footer>
		);
	}
}


function mapStateToProps(appState, ownProps) {

  return {
    footercontactinfoData: appState.ui.footercontactinfoData,
    footerData: appState.ui.footerData,
    followusData: appState.ui.followusData,
    menuData: appState.ui.MenuData

  }

}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
       requestContactinfoData,
       requestFooterData,
       requestFollowusData,
       requestMenuData
    }, dispatch)
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);



