import { createAction } from 'redux-actions';
// action     type
// request*   REQUEST_*
// receive*   RECEIVE_*


/*Static block*/
export const REQUEST_STATICBLK_DATA = 'REQUEST_STATICBLK_DATA';
export const RECEIVE_STATICBLK_DATA = 'RECEIVE_STATICBLK_DATA';

export const requestStaicblkData = createAction(REQUEST_STATICBLK_DATA);
export const receiveStaicblkData = createAction(RECEIVE_STATICBLK_DATA);


/*Banner*/
export const REQUEST_BANNER_DATA = 'REQUEST_BANNER_DATA';
export const RECEIVE_BANNER_DATA = 'RECEIVE_BANNER_DATA';

export const requestBannerData = createAction(REQUEST_BANNER_DATA);
export const receiveBannerData = createAction(RECEIVE_BANNER_DATA);

/*Promo info*/
export const REQUEST_PROMO_DATA = 'REQUEST_PROMO_DATA';
export const RECEIVE_PROMO_DATA = 'RECEIVE_PROMO_DATA';

export const requestPromoData = createAction(REQUEST_PROMO_DATA);
export const receivePromoData = createAction(RECEIVE_PROMO_DATA);

/*Header Place order Info*/
export const REQUEST_HEADERORDERINFO_DATA = 'REQUEST_HEADERORDERINFO_DATA';
export const RECEIVE_HEADERORDERINFO_DATA = 'RECEIVE_HEADERORDERINFO_DATA';

export const requestOrderinfoData = createAction(REQUEST_HEADERORDERINFO_DATA);
export const receiveOrderinfoData = createAction(RECEIVE_HEADERORDERINFO_DATA);

/*Footer contact Info*/
export const REQUEST_FOOTERCONTACTINFO_DATA = 'REQUEST_FOOTERCONTACTINFO_DATA';
export const RECEIVE_FOOTERCONTACTINFO_DATA = 'RECEIVE_FOOTERCONTACTINFO_DATA';

export const requestContactinfoData = createAction(REQUEST_FOOTERCONTACTINFO_DATA);
export const receiveContactinfoData = createAction(RECEIVE_FOOTERCONTACTINFO_DATA);

/*Footer content Info*/
export const REQUEST_FOOTER_DATA = 'REQUEST_FOOTER_DATA';
export const RECEIVE_FOOTER_DATA = 'RECEIVE_FOOTER_DATA';

export const requestFooterData = createAction(REQUEST_FOOTER_DATA);
export const receiveFooterData = createAction(RECEIVE_FOOTER_DATA);

/*Footer Follow us info*/
export const REQUEST_FOLLOWUS_DATA = 'REQUEST_FOLLOWUS_DATA';
export const RECEIVE_FOLLOWUS_DATA = 'RECEIVE_FOLLOWUS_DATA';

export const requestFollowusData = createAction(REQUEST_FOLLOWUS_DATA);
export const receiveFollowusData = createAction(RECEIVE_FOLLOWUS_DATA);

/*Catering Category list*/
export const REQUEST_CATLIST_DATA = 'REQUEST_CATLIST_DATA';
export const RECEIVE_CATLIST_DATA = 'RECEIVE_CATLIST_DATA';

export const requestCatlistData = createAction(REQUEST_CATLIST_DATA);
export const receiveCatlistData = createAction(RECEIVE_CATLIST_DATA);

/*Catering Outlet list*/
export const REQUEST_CATOUTLET_DATA = 'REQUEST_CATOUTLET_DATA';
export const RECEIVE_CATOUTLET_DATA = 'RECEIVE_CATOUTLET_DATA';

export const requestCatoutletData = createAction(REQUEST_CATOUTLET_DATA);
export const receiveCatoutletData = createAction(RECEIVE_CATOUTLET_DATA);

/*Catering Hall list*/
export const REQUEST_CATHALL_DATA = 'REQUEST_CATHALL_DATA';
export const RECEIVE_CATHALL_DATA = 'RECEIVE_CATHALL_DATA';

export const requestCathallData = createAction(REQUEST_CATHALL_DATA);
export const receiveCathallData = createAction(RECEIVE_CATHALL_DATA);

/*Catering Date list*/
export const REQUEST_CATDATE_DATA = 'REQUEST_CATDATE_DATA';
export const RECEIVE_CATDATE_DATA = 'RECEIVE_CATDATE_DATA';

export const requestCatdateData = createAction(REQUEST_CATDATE_DATA);
export const receiveCatdateData = createAction(RECEIVE_CATDATE_DATA);

/*Catering Datetime list*/
export const REQUEST_CATTIME_DATA = 'REQUEST_CATTIME_DATA';
export const RECEIVE_CATTIME_DATA = 'RECEIVE_CATTIME_DATA';

export const requestCattimeData = createAction(REQUEST_CATTIME_DATA);
export const receiveCattimeData = createAction(RECEIVE_CATTIME_DATA);

/*Catering product list*/
export const REQUEST_PROLIST_DATA = 'REQUEST_PROLIST_DATA';
export const RECEIVE_PROLIST_DATA = 'RECEIVE_PROLIST_DATA';

export const requestProlistData = createAction(REQUEST_PROLIST_DATA);
export const receiveProlistData = createAction(RECEIVE_PROLIST_DATA);

/*Catering product detail*/
export const REQUEST_PRODETAIL_DATA = 'REQUEST_PRODETAIL_DATA';
export const RECEIVE_PRODETAIL_DATA = 'RECEIVE_PRODETAIL_DATA';

export const requestProdetailData = createAction(REQUEST_PRODETAIL_DATA);
export const receiveProdetailData = createAction(RECEIVE_PRODETAIL_DATA);

/*Cart details*/
export const REQUEST_CARTLIST_DATA = 'REQUEST_CARTLIST_DATA';
export const RECEIVE_CARTLIST_DATA = 'RECEIVE_CARTLIST_DATA';

export const requestCartlistData = createAction(REQUEST_CARTLIST_DATA);
export const receiveCartlistData = createAction(RECEIVE_CARTLIST_DATA);

/*Individual page list*/
export const REQUEST_PAGES_DATA = 'REQUEST_PAGES_DATA';
export const RECEIVE_PAGES_DATA = 'RECEIVE_PAGES_DATA';

export const requestPageData = createAction(REQUEST_PAGES_DATA);
export const receivePageData = createAction(RECEIVE_PAGES_DATA);


/*menu list*/
export const REQUEST_MENU_DATA = 'REQUEST_MENU_DATA';
export const RECEIVE_MENU_DATA = 'RECEIVE_MENU_DATA';

export const requestMenuData = createAction(REQUEST_MENU_DATA);
export const receiveMenuData = createAction(RECEIVE_MENU_DATA);


/*facebook data */
export const REQUEST_FBLOGIN_DATA = 'REQUEST_FBLOGIN_DATA';
export const RECEIVE_FBLOGIN_DATA = 'RECEIVE_FBLOGIN_DATA';

export const requestFbLoginData = createAction(REQUEST_FBLOGIN_DATA);
export const receiveFbLoginData = createAction(RECEIVE_FBLOGIN_DATA);

/*customer update */
export const REQUEST_CUSTOMERUPDATE_DATA = 'REQUEST_CUSTOMERUPDATE_DATA';
export const RECEIVE_CUSTOMERUPDATE_DATA = 'RECEIVE_CUSTOMERUPDATE_DATA';

export const requestCustomerUpdateData = createAction(REQUEST_CUSTOMERUPDATE_DATA);
export const receiveCustomerUpdateData = createAction(RECEIVE_CUSTOMERUPDATE_DATA);


/*forget password */
export const REQUEST_FORGETPASSWORD_DATA = 'REQUEST_FORGETPASSWORD_DATA';
export const RECEIVE_FORGETPASSWORD_DATA = 'RECEIVE_FORGETPASSWORD_DATA';

export const requestForgetPasswordData = createAction(REQUEST_FORGETPASSWORD_DATA);
export const receiveForgetPasswordData = createAction(RECEIVE_FORGETPASSWORD_DATA);


/*registration */
export const REQUEST_REGISTRATION_DATA = 'REQUEST_REGISTRATION_DATA';
export const RECEIVE_REGISTRATION_DATA = 'RECEIVE_REGISTRATION_DATA';

export const requestRegistrationData = createAction(REQUEST_REGISTRATION_DATA);
export const receiveRegistrationData = createAction(RECEIVE_REGISTRATION_DATA);


/*login */
export const REQUEST_LOGIN_DATA = 'REQUEST_LOGIN_DATA';
export const RECEIVE_LOGIN_DATA = 'RECEIVE_LOGIN_DATA';

export const requestLoginData = createAction(REQUEST_LOGIN_DATA);
export const receiveLoginData = createAction(RECEIVE_LOGIN_DATA);


/*customer detail */
export const REQUEST_CUSTOMERDETAIL_DATA = 'REQUEST_CUSTOMERDETAIL_DATA';
export const RECEIVE_CUSTOMERDETAIL_DATA = 'RECEIVE_CUSTOMERDETAIL_DATA';

export const requestCustomerDetailData = createAction(REQUEST_CUSTOMERDETAIL_DATA);
export const receiveCustomerDetailData = createAction(RECEIVE_CUSTOMERDETAIL_DATA);

/*update customer profile */
export const REQUEST_UPDATECUSTOMERPROFILE_DATA = 'REQUEST_UPDATECUSTOMERPROFILE_DATA';
export const RECEIVE_UPDATECUSTOMERPROFILE_DATA = 'RECEIVE_UPDATECUSTOMERPROFILE_DATA';

export const requestUpdateCustomerProfileData = createAction(REQUEST_UPDATECUSTOMERPROFILE_DATA);
export const receiveUpdateCustomerProfileData = createAction(RECEIVE_UPDATECUSTOMERPROFILE_DATA);

/*Check user emailID exists*/
export const REQUEST_EMAILEXIST_DATA = 'REQUEST_EMAILEXIST_DATA';
export const RECEIVE_EMAILEXIST_DATA = 'RECEIVE_EMAILEXIST_DATA';

export const requestEmailExistData = createAction(REQUEST_EMAILEXIST_DATA);
export const receiveEmailExistData = createAction(RECEIVE_EMAILEXIST_DATA);


/*Check user Phone exists*/
export const REQUEST_PHONEEXIST_DATA = 'REQUEST_PHONEEXIST_DATA';
export const RECEIVE_PHONEEXIST_DATA = 'RECEIVE_PHONEEXIST_DATA';

export const requestPhoneExistData = createAction(REQUEST_PHONEEXIST_DATA);
export const receivePhoneExistData = createAction(RECEIVE_PHONEEXIST_DATA);


/*get settings address exists */
export const REQUEST_ADDRESS_DATA = 'REQUEST_ADDRESS_DATA';
export const RECEIVE_ADDRESS_DATA = 'RECEIVE_ADDRESS_DATA';

export const requestAddressData = createAction(REQUEST_ADDRESS_DATA);
export const receiveAddressData = createAction(RECEIVE_ADDRESS_DATA);



/*Change Password  */
export const REQUEST_CHANGEPASSWORD_DATA = 'REQUEST_CHANGEPASSWORD_DATA';
export const RECEIVE_CHANGEPASSWORD_DATA = 'RECEIVE_CHANGEPASSWORD_DATA';

export const requestChangePasswordData = createAction(REQUEST_CHANGEPASSWORD_DATA);
export const receiveChangePasswordData = createAction(RECEIVE_CHANGEPASSWORD_DATA);


/*Secondary address remove */
export const REQUEST_SECONDARYADDREMOVE_DATA = 'REQUEST_SECONDARYADDREMOVE_DATA';
export const RECEIVE_SECONDARYADDREMOVE_DATA = 'RECEIVE_SECONDARYADDREMOVE_DATA';

export const requestSecondaryAddRemoveData = createAction(REQUEST_SECONDARYADDREMOVE_DATA);
export const receiveSecondaryAddRemoveData = createAction(RECEIVE_SECONDARYADDREMOVE_DATA);


/*get all user Secondary address */
export const REQUEST_ALLUSERSECADDR_DATA = 'REQUEST_ALLUSERSECADDR_DATA';
export const RECEIVE_ALLUSERSECADDR_DATA = 'RECEIVE_ALLUSERSECADDR_DATA';

export const requestAllUserSecAddrData = createAction(REQUEST_ALLUSERSECADDR_DATA);
export const receiveAllUserSecAddrData = createAction(RECEIVE_ALLUSERSECADDR_DATA);


/*get p-order history */
export const REQUEST_PORDERHISTORY_DATA = 'REQUEST_PORDERHISTORY_DATA';
export const RECEIVE_PORDERHISTORY_DATA = 'RECEIVE_PORDERHISTORY_DATA';

export const requestPorderHistoryData = createAction(REQUEST_PORDERHISTORY_DATA);
export const receivePorderHistoryData = createAction(RECEIVE_PORDERHISTORY_DATA); 


/*get c-order history */
export const REQUEST_CORDERHISTORY_DATA = 'REQUEST_CORDERHISTORY_DATA';
export const RECEIVE_CORDERHISTORY_DATA = 'RECEIVE_CORDERHISTORY_DATA';

export const requestCorderHistoryData = createAction(REQUEST_CORDERHISTORY_DATA);
export const receiveCorderHistoryData = createAction(RECEIVE_CORDERHISTORY_DATA); 


/*get order history */
export const REQUEST_ORDERHISTORY_DATA = 'REQUEST_ORDERHISTORY_DATA';
export const RECEIVE_ORDERHISTORY_DATA = 'RECEIVE_ORDERHISTORY_DATA';

export const requestOrderHistoryData = createAction(REQUEST_ORDERHISTORY_DATA);
export const receiveOrderHistoryData = createAction(RECEIVE_ORDERHISTORY_DATA); 

/*Apply Promotion */
export const REQUEST_APPLYPROMOTION_DATA = 'REQUEST_APPLYPROMOTION_DATA';
export const RECEIVE_APPLYPROMOTION_DATA = 'RECEIVE_APPLYPROMOTION_DATA';

export const requestApplyPromotionData = createAction(REQUEST_APPLYPROMOTION_DATA);
export const receiveApplyPromotionData = createAction(RECEIVE_APPLYPROMOTION_DATA);

/*Customer Reset Password */
export const REQUEST_RESETPASSWORD_DATA = 'REQUEST_RESETPASSWORD_DATA';
export const RECEIVE_RESETPASSWORD_DATA = 'RECEIVE_RESETPASSWORD_DATA';

export const requestResetPasswordData = createAction(REQUEST_RESETPASSWORD_DATA);
export const receiveResetPasswordData = createAction(RECEIVE_RESETPASSWORD_DATA);


/*Get promotion List */
export const REQUEST_PROMOTIONLIST_DATA = 'REQUEST_PROMOTIONLIST_DATA';
export const RECEIVE_PROMOTIONLIST_DATA = 'RECEIVE_PROMOTIONLIST_DATA';

export const requestPromotionListData = createAction(REQUEST_PROMOTIONLIST_DATA);
export const receivePromotionListData = createAction(RECEIVE_PROMOTIONLIST_DATA);


/*Post contact us data */
export const REQUEST_CONTACTUS_DATA = 'REQUEST_CONTACTUS_DATA';
export const RECEIVE_CONTACTUS_DATA = 'RECEIVE_CONTACTUS_DATA';

export const requestContactUsData = createAction(REQUEST_CONTACTUS_DATA);
export const receiveContactUsData = createAction(RECEIVE_CONTACTUS_DATA); 


/*Contact content*/
export const REQUEST_CONTACTCONTENT_DATA = 'REQUEST_CONTACTCONTENT_DATA';
export const RECEIVE_CONTACTCONTENT_DATA = 'RECEIVE_CONTACTCONTENT_DATA';

export const requestContactcontentData = createAction(REQUEST_CONTACTCONTENT_DATA);
export const receiveContactcontentData = createAction(RECEIVE_CONTACTCONTENT_DATA);


/*Settings*/
export const REQUEST_SETTING_DATA = 'REQUEST_SETTING_DATA';
export const RECEIVE_SETTING_DATA = 'RECEIVE_SETTING_DATA';

export const requestSettingData = createAction(REQUEST_SETTING_DATA);
export const receiveSettingData = createAction(RECEIVE_SETTING_DATA);

/*print order receipt  */
export const REQUEST_PRINTORDERRECEIPT_DATA = 'REQUEST_PRINTORDERRECEIPT_DATA';
export const RECEIVE_PRINTORDERRECEIPT_DATA = 'RECEIVE_PRINTORDERRECEIPT_DATA';

export const requestPrintOrderReceiptData = createAction(REQUEST_PRINTORDERRECEIPT_DATA);
export const receivePrintOrderReceiptData = createAction(RECEIVE_PRINTORDERRECEIPT_DATA);

/*Get promotion List */
export const REQUEST_RECEIPTPROMOTION_DATA = 'REQUEST_RECEIPTPROMOTION_DATA';
export const RECEIVE_RECEIPTPROMOTION_DATA = 'RECEIVE_RECEIPTPROMOTION_DATA';

export const requestReceiptPromotionData = createAction(REQUEST_RECEIPTPROMOTION_DATA);
export const receiveReceiptPromotionData = createAction(RECEIVE_RECEIPTPROMOTION_DATA);


/*dqonload quotation */
export const REQUEST_DOWNLOADQUOTATION_DATA = 'REQUEST_DOWNLOADQUOTATION_DATA';
export const RECEIVE_DOWNLOADQUOTATION_DATA = 'RECEIVE_DOWNLOADQUOTATION_DATA';

export const requestDownloadQuotationData = createAction(REQUEST_DOWNLOADQUOTATION_DATA);
export const receiveDownloadQuotationData = createAction(RECEIVE_DOWNLOADQUOTATION_DATA);


/*login download quotation */
export const REQUEST_LOGINDOWNLOADQUOTATION_DATA = 'REQUEST_LOGINDOWNLOADQUOTATION_DATA';
export const RECEIVE_LOGINDOWNLOADQUOTATION_DATA = 'RECEIVE_LOGINDOWNLOADQUOTATION_DATA';

export const requestLoginDownloadQuotationData = createAction(REQUEST_LOGINDOWNLOADQUOTATION_DATA);
export const receiveLoginDownloadQuotationData = createAction(RECEIVE_LOGINDOWNLOADQUOTATION_DATA);
