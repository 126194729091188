/* eslint-disable */
import React, { Component } from 'react';

import { stripslashes, addressFormat, showAlert, callImage } from "../../Helpers/SettingHelper";

import { hashHistory } from 'react-router';


import {createBrowserHistory as history } from 'history';

import {  baseUrl, appId, apiUrl, cateringId} from "../../Helpers/Config";

import cookie from 'react-cookies';

var dateFormat = require('dateformat');

import validator from 'validator'
import update from 'immutability-helper'
import { validated } from 'react-custom-validation' 
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
var Parser = require('html-react-parser');

var qs = require('qs');

import moment from 'moment';
import {
  requestPromotionListData,
  requestReceiptPromotionData,
  requestApplyPromotionData,
  requestCartlistData,
} from '../../modules/ui/actions';

import promotionImage from "../../common/images/no-image.png";

 
class Mypromotions extends Component {

    constructor(props) {
        super(props);
        this.state = { promoused:[],
	        promotions: [],
            status: '',
            promo: [],
            cartItems: [],
            promodetails: [],
            cartDetails: [],
            cart_sub_total: 0,
            cartStatus: '',
          
			};
			
			 if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
            props.history.push("/");
        }
 
    }
    
    componentDidMount() {
		 var customerParam = "&customer_id=" + cookie.load('UserId');
		 this.props.requestPromotionListData(customerParam);
         $('#dvLoading').fadeOut(2000);
                   
          this.props.requestCartlistData({UserId:cookie.load('UserId'),referenceId:cookie.load('referenceId')});


    }
	
	componentWillReceiveProps(nextProps){
	      
		if(nextProps.promotionListData !==this.props.promotionListData){
       
        if (nextProps.promotionListData.status === "ok") {
			this.setState({status: nextProps.promotionListData.status});
			this.setState({promotions: nextProps.promotionListData.result_set.my_promo});
            this.setState({promoused: nextProps.promotionListData.result_set.promo_history});

          
		}
        
      }		
      
      
    /* for receipt promotion */
    
          if(nextProps.receiptPromotionData !==this.props.receiptPromotionData){
        if (nextProps.receiptPromotionData.status === "ok") {
					 this.setState({ promodetails: nextProps.receiptPromotionData.result_set });
  
                     $('#dvLoading').fadeOut(2000);
                        
                        $.magnificPopup.open({
                            items: {
                                src: '.receipt_popup'
                            },
                            type: 'inline'
                        });
                    
                } else {
                    this.setState({ promodetails: [] });
                }
        
      }
      
     /*for apply promotion code get cart details */
     
       if(nextProps.cartlistData !==this.props.cartlistData){
		    if (nextProps.cartlistData.status === "ok") {
                    if (typeof nextProps.cartlistData.result_set !== "undefined") {
                        var cart_sub_total = parseFloat(nextProps.cartlistData.result_set.cart_details.cart_sub_total);
                        this.setState({ cart_sub_total: cart_sub_total.toFixed(2) });
                        this.setState({ cartStatus: nextProps.cartlistData.status });
                        this.setState({ cartDetails: nextProps.cartlistData.result_set.cart_details });
                        this.setState({ cartItems: nextProps.cartlistData.result_set.cart_items });

                    }
                }
	   }
	   
	    if(nextProps.applyPromotionData !==this.props.applyPromotionData){
			
			const{history} = this.props;
			
			                $('#dvLoading').fadeOut(2000);

		    if (nextProps.applyPromotionData.status === "success") {
				
                    if (nextProps.applyPromotionData.result_set.promotion_delivery_charge_applied === "Yes" && this.state.cartDetails.cart_availability_id !== deliveryId) {

                        showAlert('Success', 'This promo code only for delivery orders..');
                        $.magnificPopup.open({
                            items: {
                                src: '.alert_popup'
                            },
                            type: 'inline'
                        });
                        this.resetPromoState();
                        return false;

                    }

                    $.magnificPopup.open({
                        items: {
                            src: '#promotion-popup'
                        },
                        type: 'inline'
                    });

                    cookie.save('promotion_id', nextProps.applyPromotionData.result_set.promotion_id);
                    cookie.save('promotion_applied', "Yes");
                    cookie.save('promotion_code', nextProps.applyPromotionData.result_set.promotion_code);
                    cookie.save('promotion_delivery_charge_applied', nextProps.applyPromotionData.result_set.promotion_delivery_charge_applied);
                    var promoAmountval = parseFloat(nextProps.applyPromotionData.result_set.promotion_amount);
                    cookie.save('promotion_amount', promoAmountval.toFixed(2));
                    cookie.save('promotion_category', nextProps.applyPromotionData.result_set.promotion_category);
                    cookie.save('prmo_type', 'promoCode');
                    setTimeout(function () {	
						/* const{history} = this.props;
                            history.push("/checkout");*/
                        
                    }, 4000);
                   


                } else {

                    if (res.data.clear_offer === 'Yes') {
                        this.resetPromotion();

                    }

                    showAlert('Error', nextProps.applyPromotionData.message);
                    $.magnificPopup.open({
                        items: {
                            src: '.alert_popup'
                        },
                        type: 'inline'
                    });
                    this.resetPromotion();
                    setTimeout(function () {

                        window.location.reload();

                    }, 3000);

                }
              
	   }
     
	}
	
	/*  get promo category details  */
    getPromoCategory() {

        var product_cartid = "";
        var cartItems = this.state.cartItems;
        var cartCount = cartItems.length;
        if (cartCount > 0) {
            for (var p = 0; p < cartCount; p++) {
                if (product_cartid !== '' && cartItems[p].cart_item_product_id !== '') {
                    product_cartid += ";";
                }
                product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price;
            }
        }

        return product_cartid;
    }
    
	/*apply coupon  function*/
    applyCoupon(promocode) {
        var promoObject = {};

        if (promocode === '') {

            showAlert('Error', 'Pleae enter your Promo Code.');
            $.magnificPopup.open({
                items: {
                    src: '.alert_popup'
                },
                type: 'inline'
            });
            return false;
        }
        var product_cartid = "";
        var cartItems = this.state.cartItems;
        var cartCount = cartItems.length;
        if (cartCount < 1) {

            showAlert('Error', 'Cart Quantity is not enough to apply promotion');
            $.magnificPopup.open({
                items: {
                    src: '.alert_popup'
                },
                type: 'inline'
            });
            return false;
        }

        var cartSubToatl = parseFloat(this.state.cart_sub_total);
        var cartItemsCount = this.state.cartDetails.cart_total_items;
        promoObject = {
            /* cart details */
            'app_id': appId,
            'reference_id': cookie.load('UserId'),
            'promo_code': promocode,
            'cart_amount': parseFloat(cartSubToatl),
            'cart_quantity': cartItemsCount,
            'category_id': this.getPromoCategory(),
        }
        $('#dvLoading').fadeIn();
        
         this.props.requestApplyPromotionData(qs.stringify(promoObject));
    }
   
    
    /*get receipt for promotion */
    
     getReceipt(promotion_id, promo) {
      $('#dvLoading').fadeIn();
      this.setState({ promo: promo });
      var params = '&promotion_id=' + promotion_id;
      this.props.requestReceiptPromotionData(params);
	 
	}
	
	
	promotioncheckout(){
		$.magnificPopup.close();
		const{history} = this.props;
          cookie.save('fromCkeckOutVld','Yes')
		history.push("/checkout");
	}
    /*promotion list */
	
	__promotionListing() { 
		if (this.state.status === 'ok') {

			 if (this.state.promotions.length) {

            return this.state.promotions.map((promo, index) =>
                <div className="promo-earned-col" key={index}>
                
                    <div className="promo-earned-col-item">
                        <div className="promo-earned-col-image ">
                            <div className="innerproduct-item-image"><img src={promotionImage} /></div>
                            <div className="tag">
                                <span>{promo.promo_days_left}</span>
                            </div>
                        </div>
                        <div className="promo-earned-info">
                        
                            <div className="promo-earned-content">
                                <h4>{promo.promo_code}</h4>
                                
                                
                                {(promo.promotion_percentage>0)?<h2><span>{promo.promotion_percentage}%</span> OFF</h2>:<h2><span><sup>$</sup>{promo.promotion_max_amt}</span></h2>}
                                
                            </div>
                        
                        <ul className="promation_btn">
                            <li><a className="btn btn_grey" href="javascript:void(0);" onClick={this.getReceipt.bind(this, promo.promotion_id, promo)} >View</a></li>
                            <li><a className="btn btn_pink" onClick={this.applyCoupon.bind(this, promo.promo_code)} href="javascript:void(0);">Redeem</a></li>
                        </ul>
                        </div>
                    </div>
                </div>);
            } else {
				return (<div>No Records Found</div>);  
			}
        } else {
            return false;
        }

    }
    
  /* promotion used list*/  
      __promotionUsedListing() {

          if (this.state.status === 'ok') {
			  
			  if (this.state.promoused.length) {

				return this.state.promoused.map((promo, index) =>

					<div className="promo-earned-col" key={index}>
						<div className="promo-earned-col-item">
							<div className="promo-earned-col-image">
								<div className="innerproduct-item-image"><img src={promotionImage} /></div>
							</div>
							<div className="promo-earned-content">
								<h4>{promo.promo_title}</h4>
							   {(promo.promotion_percentage>0)?<h2><span>{promo.promotion_percentage}%</span> OFF</h2>:<h2><span><sup>$</sup>{promo.promotion_max_amt}</span></h2>}

							</div>

						</div>
					</div>);
                
            } else {
				return (<div>No Records Found</div>);  
			} 
                
        } else {
            return false;
        }

    }
   
    render() {
		
	    return (
					<div className="Home">	<Header />
					<div className="catering_wrap myadmin_wrap">
			<div className="mainacc_menusec">
					<div className="mainacc_menuout">					
					<ul className="mainacc_menulist">
					<li><a href="/myaccount" ><span>Account Information</span></a></li>
					<li><a href="/myorders"><span>My Orders</span></a></li>
					<li><a href="#"><span>Rewards</span></a></li>
					<li className="active"><a href="/mypromotions"><span>Promotions</span></a></li>
				</ul>  
                <div className="mbacc_mslidersec mbacc_mslider">
                <div className="mbacc_mslide"><a href="/myaccount" title="">Account Information</a></div>
                <div className="mbacc_mslide"><a href="/myorders" title="">Orders</a></div>
                <div className="mbacc_mslide"><a href="#" title="">Rewards</a></div>
                <div className="mbacc_mslide"><a href="/mypromotions" title="">Promotions</a></div>					
                </div>
            </div>
            <div className="mainacc_menucontent">
            <div className="main_tabsec">
            <div className="order-tab-section">							  	
            <div className="mainacc_mobrow">
                
                
                
                
            <div className="tab_sec main_tabsec_inner">               
          
            <div className="myacc_filter">
            <div className="tab_sec filter_tabsec">
            <ul className="nav nav-tabs text-center">
            <li className="active"><a data-toggle="tab" href="#tab-id-inn5" aria-expanded="true"><span>Promotions</span></a></li>
            <li className=""><a data-toggle="tab" href="#tab-id-inn6" aria-expanded="false"><span>Promotions Used</span></a></li>
            </ul>            
            <div className="tab-content">
            <div id="tab-id-inn5" className="tab-pane fade active in">
            <h4 className="tab_mobtrigger inner_tab_border active">Promotions<i></i></h4>
            <div className="tab_mobrow filter_tabin">
            
              {this.__promotionListing()}
                                                                           
            
            </div>
            </div>
            <div id="tab-id-inn6" className="tab-pane fade">
				<h4 className="tab_mobtrigger inner_tab_border">Promotions Used<i></i></h4>
				<div className="tab_mobrow filter_tabin">
				   {this.__promotionUsedListing()}
				</div>
            </div>
            </div>
            </div>   
            </div>
            </div>          
                
                
            </div>							  	
            </div>														                         
            </div>														                         
            </div>
            </div>
    </div>
	<Footer />
    <div id="receipt-popup" className="white-popup mfp-hide popup_sec receipt_popup redeem_popsec">
                <div className="redeem_bansec">
                    <div className="promo-earned-col-image">
                        <div className="innerproduct-item-image" style={{ backgroundImage: 'url(' + promotionImage + ')' }}></div>
                        <div className="tag">
							<span>{this.state.promo.promo_days_left}</span>
						</div>
                    </div>					
					<div className="promo-earned-content">
						<h4>{this.state.promodetails.promotion_name}</h4>
						
						 {(this.state.promodetails.promotion_percentage>0)?<h2><span>{this.state.promodetails.promotion_percentage}%</span> OFF</h2>:<h2><span><sup>$</sup>{this.state.promodetails.promotion_max_amt}</span></h2>}

						<p>{Parser(stripslashes(this.state.promodetails.promo_desc+''))}</p>
					</div>                    
                </div>
                <div className="pouup_in">
                    <h3 className="title3 text-center">{this.state.promodetails.promotion_title}</h3>
                    {this.state.promodetails.promotion_desc}
                </div>
                <a className="btn btn_black btn-block" onClick={this.applyCoupon.bind(this, this.state.promo.promo_code)} href="javascript:void(0);">Redeem</a>
            </div>
        {/*} <div id="dvLoading"></div> {*/}
        
        
        <div className="white-popup mfp-hide popup_sec" id="promotion-popup" style={{maxWidth: 500}}><div className="custom_alert"><div className="custom_alertin"><div className="alert_height"><div className="alert_header">Success</div><div className="alert_body"><p>Promotion is applied successfully</p><div className="alt_btns"><a href="javascript:;" className="btn btn-sm btn_yellow" onClick={this.promotioncheckout.bind(this)}>Ok</a></div></div></div></div></div></div>


</div>
        );
    }
}
 

function mapStateToProps(appState, ownProps) {

  
  return {
    promotionListData: appState.ui.PromotionListData,
    receiptPromotionData: appState.ui.ReceiptPromotionData,
    applyPromotionData: appState.ui.ApplyPromotionData,
    cartlistData: appState.ui.cartlistData,
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
       requestPromotionListData,
       requestReceiptPromotionData,
       requestApplyPromotionData,
       requestCartlistData,
    }, dispatch)
  }
}


Mypromotions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mypromotions));

