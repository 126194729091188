/* eslint-disable */
import React, { Component } from 'react';
 
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

var Parser = require('html-react-parser');

import { fbAppId, baseUrl, appId, apiUrl, cateringId} from "../../Helpers/Config";
import { addressFormat, stripslashes, showPriceValue,timeToConv12 } from "../../Helpers/SettingHelper";

import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import {
  requestCorderHistoryData,
  requestPorderHistoryData,
  requestOrderHistoryData,
  requestPrintOrderReceiptData
} from '../../modules/ui/actions';

import cookie from 'react-cookies';

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
class Orders extends Component {

    constructor(props) {
        super(props);
        
         this.state = {
		corderHistoryData:[],
    porderHistoryData:[],
    cartItems:[],
    cartData:[],
    cart_brktime_list:[],
     

    };

    if(cookie.load('UserId')===undefined){
     props.history.push("/");
    }
    }
   componentDidMount() {
		this.props.requestCorderHistoryData(cookie.load('UserId'));
        this.props.requestPorderHistoryData(cookie.load('UserId'));
        $('#dvLoading').fadeOut(2000);
						
    }
    
   componentWillReceiveProps(nextProps){
 
	   if(nextProps.corderHistoryData !==this.props.corderHistoryData){

		    $('#dvLoading').fadeOut(2000);
       
				this.setState({corderHistoryData: nextProps.corderHistoryData.result_set});
		}else {
		
			$('#dvLoading').fadeOut(2000);
		}
			
	  if(nextProps.porderHistoryData !==this.props.porderHistoryData){
      
				this.setState({porderHistoryData: nextProps.porderHistoryData.result_set});
      }
      
      if(nextProps.OrderHistoryData !==this.props.OrderHistoryData){
       
        if (nextProps.OrderHistoryData.status === "ok") {
					
          

          this.setState({ catrngCartItems: nextProps.OrderHistoryData.result_set[0]['items'], catrngReceiptDetails: nextProps.OrderHistoryData.result_set[0] }, function () { this.makeBrkTimeList(); }.bind(this));

 
					setTimeout(function () {
					
            $('#dvLoading').fadeOut(2000);
						$.magnificPopup.open({
							items: {
								src: '.receipt_popup'
							},
							type: 'inline'
						});
					}, 3000);

				} else {
					this.setState({ receiptDetails: [] });
        }
        
      }
      if(nextProps.PrintOrderReceiptData !==this.props.PrintOrderReceiptData){
      if (nextProps.PrintOrderReceiptData.status === "ok") {
        $('#dvLoading').fadeOut(2000);
        window.open(
          nextProps.PrintOrderReceiptData.pdf_url,
          '_blank'
        );
      } 

    }
      
			
	}
  

  makeBrkTimeList() {
		var cartDetailsSate = this.state.catrngReceiptDetails;
		var cartItems = this.state.catrngCartItems;
		var cartBrkTmList = [];
		if(Object.keys(cartDetailsSate).length > 0 && Object.keys(cartItems).length > 0) {
			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			var breaktimeCount = cartDetailsSate.order_breaktime_count
			if(breaktimeEnable === 'Yes' && parseInt(breaktimeCount)>0) {
				var tempKeyArr = Array();
				var tempKey = 0;
				for (var key in cartItems) {
					var chkKey = cartItems[key].item_breaktime_indexflag;
					if(!(chkKey in tempKeyArr)) {
						tempKeyArr[chkKey] = tempKey;
						var tempItemArr = Array();
						var tempItemLst = [];
						var intVl = tempKey + 1;
						var time_lable = "Break "+intVl;
						tempItemArr['cart_detaile'] = {
							'break_time_lable' 		: time_lable,
							'break_time_index' 		: cartItems[key].item_breaktime_indexflag,
							'break_time_start' 		: cartItems[key].item_breaktime_started,
							'break_time_end' 		: cartItems[key].item_breaktime_ended,
						};
						tempItemLst[0] = cartItems[key] 
						tempItemArr['cart_items'] = tempItemLst;
						cartBrkTmList[tempKey] = tempItemArr;
						tempKey++;
					} else {
						var extKey = tempKeyArr[chkKey];
						cartBrkTmList[extKey]['cart_items'].push(cartItems[key]);
					}
				}
			}
		}
		
		this.setState({cart_brktime_list: cartBrkTmList});
		
		setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
			$(".mCustomScrollbar").mCustomScrollbar();
			} }, 800);

	}
  
  getReceipt(orderId) {
    $('#dvLoading').fadeIn();
    var params = '&customer_id=' + cookie.load('UserId') + '&order_id=' + orderId;
    this.props.requestOrderHistoryData(params);
	 
	}

  printReceipt(orderId, availabilityId) {
    $('#dvLoading').fadeIn();
    this.props.requestPrintOrderReceiptData(orderId);
	 
	}
	
  
  
	getOrderItemData = (dataProp) =>{
      if (dataProp) { 
        return dataProp.map((item) =>{
			const orderDate = Moment(item.order_date).format('DD/MM/YYYY');
			const orderTime = Moment(item.order_date).format('h:mm A');

            return (<div key={item.order_id} className="current_order">
   <div className="myacc_head_sec">
      <div className="head_left">
         <div className="head-group">
            <h4>ORDER NO - {item.order_local_no}</h4>
         </div>
      </div>
      <div className="head_right">
         <div className="head-group">
       
            <h4>{item.status_name}</h4>
         </div>
      </div>
   </div>
   <div className="order_no_deatails">
     Order placed at :{Moment(item.order_created_on).format('DD/MM/YYYY')+" "+Moment(item.order_created_on).format('h:mm A')}{/*}<br/>Pay by : {item.order_method_name} {*/}
   </div>
   <div className="delivery_total">
      <div className="delivery_total_left">
         <img src={scotterImg} />
         <h3>Order Handeling By</h3>
         <span>{stripslashes(item.order_availability_name)}</span><span>{stripslashes(item.order_customer_address_line1)}</span>
      </div>
      <div className="delivery_total_left delivery_total_right">
         <img src={mapImg} />
         <h3>Event Location</h3>
         <span>{stripslashes(item.order_customer_billing_address_line1)}</span>
      </div>
   </div>
   <div className="delivery_total delivery_total_number">
      <div className="delivery_total_left">
         <h2>Event Date</h2>
         <h4 className="checkoutDate">{orderDate}</h4>
      </div>
      <div className="delivery_total_left delivery_total_right">
         <h2>Event time</h2>
         <h4 className="checkoutTime">{orderTime}</h4>
      </div>
   </div>
   <div className="order_amt">
      <div className="row">
         <div className="col-sm-8 col-xs-8">
            <h3>Order Amount</h3>
         </div>
         <div className="col-sm-4 col-xs-4">
            <h3 className="text-right">$ {item.order_total_amount}</h3>
         </div>
      </div>
   </div>
   

  {/*onClick={this.printReceipt.bind(this, item.order_primary_id)} onClick={this.getReceipt.bind(this, item.order_id)}*/}
   <div className="order_btns"><a href="#" onClick={this.printReceipt.bind(this, item.order_primary_id)}   className="btn btn_pink print_invoice" title="">Print Invoice</a>
   <a href="#" onClick={this.getReceipt.bind(this, item.order_id)}  className="btn view_recipt" title="">View Recipt</a></div>
</div>);
        })
      }
      else {
       return (<div>No Records Found</div>);
      }
	}
    render() {

		setTimeout(function () {
			
            $('#dvLoading').remove();
						
					}, 500);
		
        return (
					<div className="Home">	<Header />
					<div className="catering_wrap myadmin_wrap">
			<div className="mainacc_menusec">
					<div className="mainacc_menuout">					
					<ul className="mainacc_menulist">
					<li ><a href="/myaccount" ><span>Account Information</span></a></li>
					<li className="active"><a href="/myorders"><span>My Orders</span></a></li>
					<li><a href="#"><span>Rewards</span></a></li>
					<li><a href="/mypromotions"><span>Promotions</span></a></li>
				</ul>  
                <div className="mbacc_mslidersec mbacc_mslider">
                <div className="mbacc_mslide"><a href="/myaccount" title="">Account Information</a></div>
                <div className="mbacc_mslide"><a href="/myorders" title="">Orders</a></div>
                <div className="mbacc_mslide"><a href="#" title="">Rewards</a></div>
                <div className="mbacc_mslide"><a href="/mypromotions" title="">Promotions</a></div>					
                </div>
            </div>
            <div className="mainacc_menucontent">
            <div className="main_tabsec">
            <div className="order-tab-section">							  	
            <div className="mainacc_mobrow">
                
                
                
                
            <div className="tab_sec main_tabsec_inner">               
          
            <div className="myacc_filter">
            <div className="tab_sec filter_tabsec">
            <ul className="nav nav-tabs text-center">
            <li className="active"><a data-toggle="tab" href="#tab-id-inn5" aria-expanded="true"><span>Current Orders</span></a></li>
            <li className=""><a data-toggle="tab" href="#tab-id-inn6" aria-expanded="false"><span>Past Orders</span></a></li>
            </ul>            
            <div className="tab-content">
            <div id="tab-id-inn5" className="tab-pane fade active in">
            <h4 className="tab_mobtrigger inner_tab_border active">Current orders<i></i></h4>
            <div className="tab_mobrow filter_tabin">
            <div className="order-delivery">
            <div className="ord-body">
            {/*}<div className="ord-pagination-col">
            <p>Show</p>
            <div className="form-group">
            <select data-placeholder="Select Your Options" className="chosen-select">
            <option>5</option>
            <option>6</option>
            <option>7</option>
            </select>
            </div>

            <span>Per Page</span>
            </div>{*/}
                
            <div className="cur-order-body">
            <div className="myacc_order_details"> 
           	{this.getOrderItemData(this.state.corderHistoryData)}      
            </div>                 
            </div>                

            </div>  
            </div>   
            </div>
            </div>
            <div id="tab-id-inn6" className="tab-pane fade">
				<h4 className="tab_mobtrigger inner_tab_border">Past Orders<i></i></h4>
				<div className="tab_mobrow filter_tabin">
				  {this.getOrderItemData(this.state.porderHistoryData)}        
				</div>
            </div>
            </div>
            </div>   
            </div>
            </div>          
                
                
            </div>							  	
            </div>														                         
            </div>														                         
            </div>
            </div>
    </div>
	<Footer />
  <div id="receipt-popup" className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup">
				<div className="pouup_in">
					<Viewcateringreceipt details={this.state.catrngReceiptDetails} cartItems={this.state.catrngCartItems} brktimeList={this.state.cart_brktime_list} />
				</div>
			</div>
      <div id="dvLoading"></div> 

</div>
        );
    }
}
 

function mapStateToProps(appState, ownProps) {

  
  return {
    corderHistoryData: appState.ui.CorderHistoryData,
    porderHistoryData: appState.ui.PorderHistoryData,
    OrderHistoryData: appState.ui.OrderHistoryData,
    PrintOrderReceiptData: appState.ui.PrintOrderReceiptData
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
       requestCorderHistoryData,
       requestPorderHistoryData,
       requestOrderHistoryData,
       requestPrintOrderReceiptData
    }, dispatch)
  }
}


Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));



class Viewcateringreceipt extends Component {
	constructor(props) {
		super(props);
	}
	
	getCartDetList(details, cartItems, brktimeList) {
		var cartDetailsSate = details;
		var cartBrktimeLst = brktimeList;
		if(Object.keys(cartDetailsSate).length > 0) {
			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			if(breaktimeEnable === 'Yes' && Object.keys(cartBrktimeLst).length > 0) {
				return (this.cartBrkTimeList(details, cartItems, brktimeList));
			} else {
				return (this.cartListOnly(details, cartItems, brktimeList));
			}
		}
	}
  
  
  componentWillReceiveProps(nextProps){
   

    if(nextProps.brktimeList !==this.props.brktimeList){
     
       
     }

    }
	cartListOnly(details, cartItems, brktimeList) {
		
		return (<div className="panel panel-default">
					<div className="panel-heading act" role="tab" id="headingTb0" onClick={this.handlerActTab.bind(this,0)}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" href="#collapse0" className="">Order Item Details</a>
						</h4>
					</div>
					<div id="collapse0" className="panel-collapse collapse mCustomScrollbar in" aria-expanded="true" href="#collapse0">
						<div className="panel-body">
						 
							{ this.cartItemlist(cartItems) }
						 
						</div>
					</div>	
				</div>);
	}
	
	cartBrkTimeList(details, cartItems, brktimeList) {
		var cartBrktimeLst = brktimeList;
		
		const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) =>
								  <div className="panel panel-default" key={indx}>
									<div className={(indx===0)?"panel-heading act":"panel-heading"} role="tab" id={"headingTb"+indx} onClick={this.handlerActTab.bind(this,indx)}>
										<h4 className="panel-title">
											<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}  className={(indx===0)?"":"collapsed"}>
												{cartBrk['cart_detaile'].break_time_lable} : <span className="hr">{timeToConv12(cartBrk['cart_detaile'].break_time_start)}</span></a>
										</h4>
									</div>
									<div id={"collapse"+indx} className={(indx===0)?"panel-collapse collapse mCustomScrollbar in":"panel-collapse collapse mCustomScrollbar"} aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}>
										<div className="panel-body">
										 
											{ this.cartItemlist(cartBrk['cart_items']) }
										 
										</div>
									</div>	
								  </div>); 
		return cartBrktmhtml;
	}

	handlerActTab(indx) {
		var ariaAxp = $("#headingTb"+indx).find('a').attr('aria-expanded');
		if(ariaAxp === 'true') {
			$("#headingTb"+indx).addClass("act");
		} else {
			$("#headingTb"+indx).removeClass("act");
		}
		$('.panel-heading').not($("#headingTb"+indx)).removeClass("act");
	}
	
	cartItemlist(itemLst) {
		
		if(Object.keys(itemLst).length > 0) {
			
		const cartlsthtml = itemLst.map((cartLst, indx1) =>
								  <div key={indx1}>
								  
								   {/* package div start */}
									<div className="package">
									
									  <div className="row package_cnt">
									    <div className="col-sm-4 col-xs-4">
											<a href="javascript:void(0)">
											<span className="title">{cartLst.item_name}</span></a>
										</div>
										<div className="col-sm-4 col-xs-4">
										    <div className="qtybxs"> {cartLst.item_qty} pax </div>
									   </div>
									   <div className="col-sm-4 col-xs-4">
									        <div className="amt">
									         <span>{showPriceValue(cartLst.item_total_amount)}</span>
									        </div>
									   </div>
									 </div>	

									 {this.showModifiers(cartLst.modifiers)}	
									 
									</div>
									{/* package div end */}
									
									<div className="brline"></div>
	
									{(Object.keys(cartLst.addons_setup).length > 0)?<div className="addon"><h4>ADD-ONS</h4>{this.addonsSetupLst(cartLst)}</div>:""}

									<div className="brline"></div>

									{(Object.keys(cartLst.setup).length > 0)?this.setupDet(cartLst):""}
									
									<div className="brline"></div>
									
									{(cartLst.item_specification !== '')?this.specialNotes(cartLst):""}

								  </div>); 
		return cartlsthtml;
		
		}
		
	}
	
	showModifiers(mdfLst) {

		if(Object.keys(mdfLst).length > 0) {

			const mdflsthtml = mdfLst.map((mdfLt, indx3) =>
								  <div key={indx3} className="package-list">
									<p className="one">{mdfLt.order_modifier_name}</p>
									<p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
								  </div>);
			return mdflsthtml;
		}

	}

	showMdfValues(mdfValLst) {
		var mdfValueText = '';	
		if(Object.keys(mdfValLst).length > 0) {
			var tempVl = Object.keys(mdfValLst).length - 1;
			for (var key in mdfValLst) {
				
				/*mdfValueText += (parseInt(tempVl) === parseInt(key)) ? mdfValLst[key].order_modifier_name : mdfValLst[key].order_modifier_name+', ';
*/

			mdfValueText += ((parseInt(tempVl) === parseInt(key)) ?( mdfValLst[key].order_modifier_name+(parseFloat(mdfValLst[key].order_modifier_price)?' (+'+mdfValLst[key].order_modifier_price+')':'')) :( mdfValLst[key].order_modifier_name+(parseFloat(mdfValLst[key].order_modifier_price)?' (+'+mdfValLst[key].order_modifier_price+')':'')+', '));

				
			}
		}
		return mdfValueText;
	}
	
	addonsSetupLst(cartLstDat) {
			var addonsSet = cartLstDat.addons_setup;
			const addonhtml = addonsSet.map((addonSt, indx4) =>
								  <div key={indx4} className="row addon_list">
									<div className="col-sm-6 col-xs-9">		
									  <h5><a href="javascript:void(0)">
										<span className="title">{addonSt.as_setup_title}</span>
									  </a></h5>
									  {this.addonsSetupValues(addonSt.addons_setup_values)}
									</div>		
									<div className="col-sm-6 col-xs-3">	
									  <div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									  </div>
									</div>
								  </div>);
			return addonhtml;
	}

	addonsSetupValues(addonsVal) {
		if(Object.keys(addonsVal).length > 0) {
			const addonValhtml = addonsVal.map((addonVl, indx5) =>
								  <p key={indx5}>
									{addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X)
								  </p>);
			return addonValhtml;
		}
	}

	setupDet(cartLstDat) {

		var setupDt = cartLstDat.setup;
		var setupLbl = '';
		var setupPrice = 0;

		for (var key in setupDt) {
			setupLbl = setupDt[key].os_setup_type;
			setupPrice += parseFloat(setupDt[key].os_setup_total_price);
		}

		return (<div className="buffet_setup">
				  <div className="row">
					<div className="col-sm-6 col-xs-9">		
						<h5><a href="javascript:void(0)"><span className="title">{setupLbl} Setup</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-3">	
						<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
						</div>
					</div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">	
					{this.setupList(setupDt)}	
				 </div>
				</div>
			</div>);

	}

	setupList(setupDats) {
		if(Object.keys(setupDats).length > 0) {
			const setupDathtml = setupDats.map((setupVl, indx6) =>
								  <p key={indx6}>
									{setupVl.os_setup_name} : {setupVl.os_setup_description}
								  </p>);
			return setupDathtml;
		}
	}
	
	specialNotes(cartLstDat) {

		var special_not = cartLstDat.item_specification;
		
		return (<div className="buffet_setup" style={{paddingTop: '0px'}}>
				  <div className="row">
					<div className="col-sm-6 col-xs-8">		
						<h5><a href=""><span className="title">Special Notes</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-4"></div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">
				 <p>{special_not}</p>
				 </div>
				</div>
			</div>);

	}
	
	cartHallData(details, cartItems, brktimeList) {
		var cartDetailsMn = details;
		if(Object.keys(cartDetailsMn).length > 0) {
		 if(cartDetailsMn.order_venue_type === "hall") {
		   return (<div className="panel panel-default">
					<div className="panel-heading" role="tab" id="headingTbH1" onClick={this.handlerActTab.bind(this,'H1')}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" href="#collapseH1" className="collapsed">Catering Hall Details</a>
						</h4>
					</div>
					<div id="collapseH1" className="panel-collapse fixed-height-cls collapse mCustomScrollbar" aria-expanded="false" href="#collapseH1">
						<div className="panel-body">
						
						 <div className="buffet_setup" style={{padding: '0px'}}>
						 <div className="row">
							<div className="col-sm-6 col-xs-8">		
								<h5><a href="javascript:void(0)"><span className="title">{cartDetailsMn.order_hall_name}</span></a></h5>
							</div>
							<div className="col-sm-6 col-xs-4">	
								<div className="amt">
									<span>{showPriceValue(cartDetailsMn.order_hall_charges)}</span>
								</div>
							</div>
						 </div>
						 </div>
						 
						</div>
					</div>	
				</div>);
		 }
		}
	}
	
	render() {

    const { details, cartItems, brktimeList } = this.props;

    if(details!== undefined){
		return (<div className="Viewreceipt" >
					<div className="tnk-you">
					
						<div className="tnk-header">
							<div className="tnk-detail">
								<h2>YOUR ORDER DETAILS</h2>
								<div className="tnk-order">
									<h3>Order No - {details.order_local_no}</h3>
									<p>Order placed at :  {Moment(details.order_created_on).format('DD-MM-YYYY hh:mm A')}</p>
								</div>
							</div>
						</div>	
						
							<div className="tnk-delivery">

								<div className="delivery_total">
									<div className="delivery_total_left">
										<img src={scotterImg} />
										<h3>Catering Handeling By</h3> <span>{details.outlet_name}</span><span>{addressFormat(details.outlet_unit_number1, details.outlet_unit_number2, details.outlet_address_line1, details.outlet_address_line2, details.outlet_postal_code)}</span> </div>
									<div className="delivery_total_left delivery_total_right">
										<img src={mapImg} />
										<h3>Catering Location</h3>
										{(details.order_hall_name !== '')?<span>{details.order_hall_name}</span>:''}	
										<span>
											{addressFormat(details.order_customer_unit_no1,details.order_customer_unit_no2,details.order_customer_address_line1,details.order_customer_address_line2,details.order_customer_postal_code)}
										</span></div>
								</div>

								<div className="delivery_total delivery_total_number">
									<div className="delivery_total_left">
										<h2>Event Date</h2>
										<h4 className="checkoutDate">{Moment(details.order_date).format('DD-MM-YYYY')}</h4>
									</div>
									<div className="delivery_total_left delivery_total_right">
										<h2>Event time</h2>
										<h4 className="checkoutTime">{Moment(details.order_date).format('hh:mm A')}</h4>
									</div>
								</div>

								<div className="order_details">
									<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
									{this.getCartDetList(details, cartItems, brktimeList)}
									{this.cartHallData(details, cartItems, brktimeList)}
									</div>
								</div>
								
								<div className="cart_footer tnk_cart_footer">
									<div className="cart_row">
										<div className="row">
											<div className="col-xs-7">
												<p className="text-uppercase">SUBTOTAL</p>
											</div>
											<div className="col-xs-5 text-right">
												<span>${details.order_sub_total}</span>
											</div>
										</div>
									</div>

									{parseFloat(details.order_discount_amount) > 0 &&

										<div className="cart_row">

											<div className="row">
												<div className="col-xs-7">
													<p className="text-uppercase">Promocode (-)</p>
												</div>
												<div className="col-xs-5 text-right">
													<span>${details.order_discount_amount}</span>
												</div>
											</div>

										</div>

									}


									{details.order_tax_calculate_amount > 0 && <div className="cart_row">

										<div className="row">
											<div className="col-xs-7">
												<p className="text-uppercase">GST({details.order_tax_charge}%)</p>
											</div>
											<div className="col-xs-5 text-right">
												<span>${details.order_tax_calculate_amount}</span>
											</div>
										</div>

									</div>}

									<div className="cart_row cart_footer_totrow">
										<div className="row">
											<div className="col-xs-7">
												<p className="text-uppercase">Total</p>
											</div>
											<div className="col-xs-5 text-right">
												<span>${details.order_total_amount}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							
						
					</div>
				</div>
        );
      }else{
        return (<div ></div>);
      }

	}

}
