/* eslint-disable */
import React, { Component } from 'react';

import { withRouter, Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import { appId, apiUrl, deliveryId, cateringId, pickupId, apiUrlV2, CountryTxt, productNoImg, baseUrl, timThumpUrl } from "../../Helpers/Config";
import { getReferenceID, showLoader, hideLoader, callImage, showAlert, timeToConv12, showPriceValue, showCustomAlert, showCartItemCount, stripslashes } from "../../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import tickImg from "../../common/images/tick.png";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
var Parser = require('html-react-parser');

class Thankyou extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {pay_orderid:'', pay_amount: 0, status: 'loading' };
		
    }
    
	componentDidMount() {
		
		const{history} = this.props;

		var userIdTxt = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
		var payOrderid = (typeof cookie.load('payOrderid') === 'undefined') ? '' : cookie.load('payOrderid');
		var payAmount = (typeof cookie.load('payAmount') === 'undefined') ? '' : cookie.load('payAmount');

		if(userIdTxt !== '' && payOrderid !== '') {
			cookie.remove('payOrderid');
            cookie.remove('payAmount');
			this.setState({status: 'ok', pay_orderid: payOrderid, pay_amount: payAmount});
		}
		
		var pay_orderid = this.state.pay_orderid;
		if(userIdTxt === '' || (payOrderid === '' && pay_orderid === '')) {
			history.push("/");
		}	
	}

	setActiveTab = (tabid) => {

		const{history} = this.props;

		cookie.save('fromOtherPageFlg', tabid);
		history.push("/");

	}

  render() {
    
	if (this.state.status === "ok") {
	
		return (
		  <div className="thankyou-page">
		  
		  {/* Header start */}
		  <Header categoryState={this.state} setActiveTab={this.setActiveTab} />
		  {/* Header End */}
		  
		  <div className="tnk-you">
			<div className="container catering_thak_you">
				<div className="tnk-header">
		  
					<div className="tick">
						<img src={tickImg} />
						<h2>Thank You </h2>
						<span>Your paymemt has been paid successfully</span>
					</div>
		  
					<div className="tnk-detail" style={{paddingBottom: '25px'}}>
						<h2>YOUR PAYMENT DETAILS</h2>
						<div className="tnk-order">
							<h3>Order No - {this.state.pay_orderid}</h3>
							<p style={{fontSize: '20px', fontWeight: 'bold'}}>Paid Amount - {showPriceValue(this.state.pay_amount)}</p>
						</div>
					</div>
					
					<div className="tnk-chk-order">
						<a className="btn btn_black btn_minwid" href="/myaccount">Go To My Account </a>
					</div>
		  
				</div>
			</div>	
		  </div>	
	  
		 <Footer />	
		 
		</div>

		);
	
	} else {

		return (<div><div id="dvLoading"></div></div>);

	}

	
  }
}
 
Thankyou.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(Thankyou);
