/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";

import { stripslashes } from "../../Helpers/SettingHelper";
import { appId, apiUrl } from "../../Helpers/Config";
var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestBannerData,
} from '../../modules/ui/actions';


import Promoinfo from "./Promoinfo";


class Banner extends Component {

    constructor(props) {
        super(props);

        this.state = { bannerlist:[],bannerimagesource:'', bannerstatus:''};

    }

    componentWillUnmount() {

    }

    componentWillMount(){

    }

    componentDidMount() {
		 this.props.requestBannerData();

    }

    render() {

		let bannerstatus = '';
		let bannerlist = [];
		let bannerimagesource = '';

		if(this.props.bannerData) {
			
			bannerstatus = this.props.bannerData.status;
			bannerlist = this.props.bannerData.result_set;
			bannerimagesource = this.props.bannerData.common.image_source;

		}
		
		 var settingsGallery = {
			infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:1,
            speed: 500,
            swipe:1
        };
		

        return (
      <div className="banner_section">

		   <div className="banner_slider">
			<Slider {...settingsGallery}>
                         
                       
			 
			  {
				  (bannerlist || []).map(
				  (banner,index) => {
					  return (
						<div className="item_slide" key={index}>
							<img src={bannerimagesource + banner.banner_image} />
							{(banner.banner_description!='')?Parser(stripslashes(banner.banner_description)):''}
							
						</div>
					  
					  )
					}
					) 
			  }
			  
			   </Slider>

		   </div>

	
		<Promoinfo/>


	</div>

        );
    }
}

function mapStateToProps(appState, ownProps) {

  return {
    bannerData: appState.ui.bannerData
    
   
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestBannerData
    }, dispatch)
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(Banner);
