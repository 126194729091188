import React from 'react';
import { render } from 'react-dom';

/*Store*/
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
/*import { logger } from 'redux-logger';*/
import createSagaMiddleware from 'redux-saga';
import {rootReducer, rootSaga } from './modules';
/*Store*/
/* import css files */
import './common/css/font-awesome.min.css';
import './common/css/bootstrap.min.css';
import './common/css/slick.css';
import './common/css/custom.css';
import './common/css/responsive.css';
import App from './components/App';
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(
 applyMiddleware(sagaMiddleware),
  // other store enhancers if any
));
sagaMiddleware.run(rootSaga);

render(
	<Provider store={store}>
 		<App />
	</Provider>,
document.getElementById('root'));
