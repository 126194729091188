import axios from 'axios';

import { appId, apiUrlV2, cateringId } from "../../../Helpers/Config";

var qs = require('qs');

/*Catering category list*/
export async function getcatlistData(obj){
  return await axios.get(apiUrlV2 + "catering/categories?app_id=" + appId + "&cat_product_val=no"+((typeof obj.highlight_option !== 'undefined')?'&highlight_option='+obj.highlight_option:'')+((typeof obj.pro_cate_id !== 'undefined')?'&pro_cate_id='+obj.pro_cate_id:''))
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

/*Catering Outlet list*/
export async function getcatoutletData(){

  return await axios.get(apiUrlV2 + "outlets/getAllOutles?app_id=" + appId + "&availability_id=" + cateringId)
  .then(function (response) {

    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

/*Catering Hall list*/
export async function getcathallData(){
  return await axios.get(apiUrlV2 + "catering/cateringHalls?app_id=" + appId)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

/*Catering date list*/
export  function getcatdateData(obj){

  return  axios.get(apiUrlV2 + "catering/availableDates?app_id=" + appId + "&availability_id=" + cateringId + "&dateFlagkey=" + obj.dateFlagkey + "&outletId=" + obj.outletId + "&nextDate=" + obj.nextDate + "&prevDate=" + obj.prevDate + "&")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

/*Catering time list*/
export  function getcattimeData(obj){
  return  axios.post(apiUrlV2 + "catering/availableTime", qs.stringify(obj))
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

/*Catering product list*/
export async function getprolistData(obj){

  const {categoryId,eventType,outletId,HallId} = obj;

  return await axios.get(apiUrlV2 + "catering/cateringprodcutlist?app_id=" + appId + "&category_id=" + categoryId + "&catering_type=" + eventType + "&catering_outlet_id=" + outletId + "&catering_hall_id=" + HallId + "&")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });

}

/*Catering prodcut detail list*/
export async function getprodetailData(obj){

  const {productId,cateringId} = obj;

  return await axios.get(apiUrlV2 + "catering/cateringproducts_detail?app_id=" + appId + "&product_id=" + productId + "&availability=" + cateringId + "&status=A&")
  .then(function (response) {

    return response.data;
  })
  .catch(function (error) {
    return error;
  });

}


/*Catering category list*/
export async function getcartlistData(obj){
	const {UserId,referenceId} = obj;
	
	let customerParam; 
	
	if (typeof UserId === 'undefined') {
		 customerParam = "&reference_id=" + referenceId;
	} else {
		 customerParam = "&customer_id=" + UserId;
	}

  return await axios.get(apiUrlV2 + "cateringcart/contents?app_id=" + appId + customerParam)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}



/*Catering dwonload quotation*/
export async function getCateringQuotationData(params){

  return await axios.get(apiUrlV2 + 'cateringcart/downloadQtnItems?app_id=' + appId + params)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}



/*Catering dwonload quotation for not login*/
export async function getloginQuotationData(params){
  return await axios.get(apiUrlV2 + 'cateringcart/downloadQtnItems?app_id=' + appId + params)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
