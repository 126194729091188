import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getResetPasswordData(postData){
  return await axios.post(apiUrl + 'customer/resetpassword',postData)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
