/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../../Helpers/Config";
import axios from 'axios';
import cookie from 'react-cookies';

var dateFormat = require('dateformat');

import validator from 'validator'
import update from 'immutability-helper'
import { validated } from 'react-custom-validation'
import Moment from 'moment'
import Select from 'react-select';
/*import 'react-select/dist/react-select.css';*/

import { stripslashes, addressFormat, showAlert, callImage } from "../../Helpers/SettingHelper";
import { requestAllUserSecAddrData, requestUpdateCustomerProfileData, requestCustomerDetailData } from '../../modules/ui/actions';


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


/* import modules */
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
/*import Logout from "../Layout/Logout"
import Breadcrumb from "../Layout/Breadcrumb"
import SubMenuListing from "../Products/Listing/SubMenuListing"*/

import Form from "./Form"

import paymentImage from "../../common/images/payment.png";
import giftImage from "../../common/images/gift.png";
import logoImage from "../../common/images/logo.png";

var qs = require('qs');


import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

class Myaccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                firstname: '',
                lastname: '',
                nickname: '',
                email: '',
                mobile: '',
                postal: '',
                unitnumber2: '',
                unitnumber1: '',
                company: '',
                birthdate: "",
                fbid: '',
                image_source: '',
                photo: '',
                gender: ''
            },
            status: 'OK',
            date1: '',
            defaultDispaly: "",

            activityPoints: {
                reward_ponits: '',
                reward_ponits_monthly: '',
                order_all: '',
                promotion: ''
            }, ordercount: 0
        };

        if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
            props.history.push("/");
        }


    }

    
    componentDidMount() {

       // this.getActivityCounts();
       var params = "&status=A&refrence=" + cookie.load('UserId');
       $('#dvLoading').fadeOut(2000);
       this.props.requestCustomerDetailData(params);

    }

    fieldChange = (field, value) => {
        
        
        this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
    }


    /* To Submit the personal informartion form */
    handleFormSubmit = () => {

        const formPayload = this.state.fields;

       
      
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.firstname,
            "customer_last_name": formPayload.lastname,
            "customer_nick_name": formPayload.nickname,
            "customer_phone": formPayload.mobile,
            "customer_email": formPayload.email,
            "customer_birthdate": cookie.load('birthdate').replace("/", "-").replace("/", "-"),
            'customer_postal_code': formPayload.postal,
            'customer_address_name2': formPayload.address1,
            'customer_company_name': formPayload.company,
            'customer_gender': formPayload.gender.value,
            'customer_address_line1': formPayload.unitnumber1,
            'customer_address_line2': formPayload.unitnumber2,
            'customer_id': cookie.load('UserId')
        };
        $(".myaccount_update").append('<b class="gloading_img"></b>');
        this.props.requestUpdateCustomerProfileData(qs.stringify(postObject));
 
    }



    /* Get Redeem Points Count History Details */
    getActivityCounts() {
        const inputKeys = ["notify", "reward", "promotion", "order", "order_all", "reward_monthly"];
        var postObject = {};
        postObject = {
            'app_id': appId,
            'customer_id': cookie.load('UserId'),
            'act_arr': JSON.stringify(inputKeys)
        };
        axios.post(apiUrl + "reports/activity_counts", qs.stringify(postObject)).then(res => {
            if (res.data.status === "ok") {
                this.setState({ activityPoints: res.data.count, ordercount: res.data.count.order_all });
            } else {
                this.setState({ activityPoints: [] });
            }
        });
    }


    componentWillReceiveProps(nextProps) {
        
    if(nextProps.UpdateCustomerProfileData !== this.props.UpdateCustomerProfileData){
        
        const {UpdateCustomerProfileData} = nextProps;
        if (UpdateCustomerProfileData.status === "ok") {
            $('#dvLoading').fadeOut(2000);
            //this.setStateLoading('ok');
            cookie.save("UserFname", UpdateCustomerProfileData.result_set.customer_first_name);
            cookie.save("UserLname", UpdateCustomerProfileData.result_set.customer_last_name);
            cookie.save("UserMobile", UpdateCustomerProfileData.result_set.customer_phone);
            cookie.save("UserEmail", UpdateCustomerProfileData.result_set.customer_email);
            cookie.save("UserDefaultAddress", UpdateCustomerProfileData.result_set.customer_address_name);
            cookie.save("UserDefaultUnitOne", UpdateCustomerProfileData.result_set.customer_address_line1);
            cookie.save("UserDefaultUnitTwo", UpdateCustomerProfileData.result_set.customer_address_line2);
            cookie.save("UserDefaultPostalCode", UpdateCustomerProfileData.result_set.customer_postal_code);

            /* Redirect to checkout page .. */
            if (cookie.load('isChecoutRedirect') === "Yes" && cookie.load('cartValue') === "Yes") {
                cookie.save('isChecoutRedirect', 'No');
                /*hashHistory.push("/checkout");*/
            }
            window.location.reload();

        } else {
            //this.setStateLoading('ok');

            if (UpdateCustomerProfileData.form_error) {
                document.getElementById("form-msg").innerHTML = '<div className="box_error"><ul><li>' + UpdateCustomerProfileData.form_error + '</li></ul></div>';
            } else {
                document.getElementById("form-msg").innerHTML = '<div className="box_error"><ul><li>' + UpdateCustomerProfileData.message + '</li></ul></div>';
            }
        }

    }
        if(nextProps.CustomerDetailData !== this.props.CustomerDetailData){

            const {CustomerDetailData} = nextProps;
            if (CustomerDetailData.status === "ok") {
                $('#dvLoading').fadeOut(2000);
                if (CustomerDetailData.result_set.customer_birthdate == null) {
                    var birthdate = '';
                } else {
                    var birthdate = CustomerDetailData.result_set.customer_birthdate;
                    var birthyear = birthdate.substring(0, 4);
                    var birthmonth = birthdate.substring(5, 7);
                    var birthdatev = birthdate.substring(8, 10);
                    birthdate = birthdatev + "/" + birthmonth + "/" + birthyear;
                    cookie.save('birthdate', birthdate);
                }
               
                this.setState({
                    fields: {
                        firstname: (CustomerDetailData.result_set.customer_first_name !== null ? CustomerDetailData.result_set.customer_first_name : ''),
                        birthdate: birthdate,
                        lastname: (CustomerDetailData.result_set.customer_last_name !== null ? CustomerDetailData.result_set.customer_last_name : ''),
                        nickname: (CustomerDetailData.result_set.customer_nick_name !== null ? CustomerDetailData.result_set.customer_nick_name : ''),
                        email: (CustomerDetailData.result_set.customer_email !== null ? CustomerDetailData.result_set.customer_email : ''),
                        mobile: (CustomerDetailData.result_set.customer_phone !== null ? CustomerDetailData.result_set.customer_phone : ''),
                        photo: (CustomerDetailData.result_set.customer_photo !== null &&  CustomerDetailData.result_set.customer_photo !== '' ? CustomerDetailData.common.image_source + CustomerDetailData.result_set.customer_photo : ''),
                        postal: (CustomerDetailData.result_set.customer_postal_code !== null ? CustomerDetailData.result_set.customer_postal_code : ''),
                        unitnumber2: (CustomerDetailData.result_set.customer_address_line2 !== null ? CustomerDetailData.result_set.customer_address_line2 : ''),
                        unitnumber1: (CustomerDetailData.result_set.customer_address_line1 !== null ? CustomerDetailData.result_set.customer_address_line1 : ''),
                        address: (CustomerDetailData.result_set.customer_address_name !== null ? CustomerDetailData.result_set.customer_address_name : ''),
                        address1: (CustomerDetailData.result_set.customer_address_name2 !== null ? CustomerDetailData.result_set.customer_address_name2 : ''),
                        company: (CustomerDetailData.result_set.customer_company_name !== null ? CustomerDetailData.result_set.customer_company_name : ''),
                        fbid: CustomerDetailData.result_set.customer_fb_id,
                        image_source: CustomerDetailData.common.image_source,
                        juicedid: (CustomerDetailData.result_set.customer_unique_id !== null ? CustomerDetailData.result_set.customer_unique_id : ''),
                        joinedOn: (CustomerDetailData.result_set.customer_created_on !== null ? CustomerDetailData.result_set.customer_created_on : ''),
                        gender: (CustomerDetailData.result_set.customer_gender !== null ? CustomerDetailData.result_set.customer_gender : ''),

                    }, status: CustomerDetailData.status

                });


                var vDefaultAddr = '';
                var list = document.getElementsByClassName('form-group-input');
                var n;
                for (n = 0; n < list.length; ++n) {
                    if (list[n].getElementsByTagName('input')[0].value !== '') {
                        list[n].classList.remove("is-empty");
                    } else {
                        list[n].classList.add("is-empty");
                    }
                }
            }
        }



    }


    render() {
        return (<div className="Home">	<Header />
        <div className="catering_wrap myadmin_wrap">
<div className="mainacc_menusec">
<div className="mainacc_menuout">					
<ul className="mainacc_menulist">
                      <li className="active"><a href="/myaccount" ><span>Account Information</span></a></li>
                      <li ><a href="/myorders"><span>Orders</span></a></li>
                      <li><a href="#"><span>Rewards</span></a></li>
                      <li><a href="/mypromotions"><span>Promotions</span></a></li>
                  </ul>  
      <div className="mbacc_mslidersec mbacc_mslider">
      <div className="mbacc_mslide"><a href="/myaccount" title="">Account Information</a></div>
      <div className="mbacc_mslide"><a href="/myorders" title="">Orders</a></div>
      <div className="mbacc_mslide"><a href="#" title="">Rewards</a></div>
      <div className="mbacc_mslide"><a href="/mypromotions" title="">Promotions</a></div>						
  </div>
</div>
<div className="mainacc_menucontent">
<div className="main_tabsec">
<div className="order-tab-section">							  	
<div className="mainacc_mobrow">
     
<div className="tab_sec main_tabsec_inner">               

<div className="myacc_filter">
<div className="tab_sec filter_tabsec">
<Form {...this.props}
                fields={this.state.fields}

                activityPoints={this.state.activityPoints}
                onChange={this.fieldChange}
                onValid={this.handleFormSubmit}
                onInvalid={() => console.log('Form invalid!')}
    />  
</div>   
</div>
</div>          
    
    
</div>							  	
</div>														                         
</div>														                         
</div>
</div>
</div>
<Footer />
</div> 
          );
    }
}


function mapStateToProps(appState, ownProps) {
    return {
        CustomerDetailData: appState.ui.CustomerDetailData,
        UpdateCustomerProfileData: appState.ui.UpdateCustomerProfileData
      
     
    }
    
     
  }
  
  
  function mapDispatchToProps(dispatch) {
  
    return {
      ...bindActionCreators({
        requestCustomerDetailData,
        requestUpdateCustomerProfileData
      }, dispatch)
    }
  }
  
  
  
  
  Myaccount.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }) 
  };
  
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Myaccount));
  
   
