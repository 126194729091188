/* eslint-disable */
import React, { Component } from 'react';

import { withRouter, Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import Moment from 'moment';

import { appId, apiUrl, cateringId, apiUrlV2, CountryTxt, productNoImg, baseUrl, timThumpUrl, stripeCurrency, stripeImage, stripeCompany, stripeReference, stripeKey } from "../../Helpers/Config";

import { getReferenceID, showLoader, hideLoader, callImage, showAlert, timeToConv12, showPriceValue, showCustomAlert, showCartItemCount, stripslashes } from "../../Helpers/SettingHelper";

import cookie from 'react-cookies';
import axios from 'axios';

import StripeCheckout from 'react-stripe-checkout';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import tickImg from "../../common/images/tick.png";
import tickImage from "../../common/images/tick_popup.png";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import paymentImg from "../../common/images/payment.png";
import loadingImage from "../../common/images/loading_popup.gif";

var qs = require('qs');
var Parser = require('html-react-parser');
var base64 = require('base-64');

class Payment extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {pageFromTxt:'others', cartTotalItmCount: 0, discountAmount: 0, deliveryCharge: 0, gstPercentage: 0, gstAmount: 0, result_set: [], cartData: [], cartItems: [], final_total_amount: 0, cart_brktime_list: [], status: 'loading', requestAmountArr: [], paymentList: [], requestAmount: 0, balanceAmount: 0, payment_error_txt: '', payment_action: 'Yes', min_payment_inf: 'No', paidAmount: 0, paydisable: true, validateimage: loadingImage,  placingorderimage: loadingImage, payment_id: '', order_id: '', local_orderno: '',stripe_envir: '',stripe_public_key: ''};
		
    }
    
	componentDidMount() {

		var orderId = (typeof this.props.match.params.orderId === 'undefined') ? '' : this.props.match.params.orderId;
		var paymentId = (typeof this.props.match.params.paymentId === 'undefined') ? '' : this.props.match.params.paymentId;
		var cateringPay = (typeof cookie.load('cateringPay') === 'undefined') ? '' : cookie.load('cateringPay');
		var userIdTxt = (typeof cookie.load('UserId') === 'undefined') ? '' : cookie.load('UserId');
	
		if(cateringPay === '' && orderId !== '' && paymentId !== '') {
			cookie.save('orderIdTxt', orderId);
			cookie.save('paymentIdTxt', paymentId);
		} else if(cateringPay === 'start') {
			orderId = (typeof cookie.load('orderIdTxt') === 'undefined') ? '' : cookie.load('orderIdTxt');
		    paymentId = (typeof cookie.load('paymentIdTxt') === 'undefined') ? '' : cookie.load('paymentIdTxt');
		}
	
	
		const{history} = this.props;

		if(orderId === '' || paymentId === '') {
			showAlert('Success', 'Sorry!. Invalid payment request.');

			$.magnificPopup.open({
			  items: {
				src: '.alert_popup'
			  },
			  type: 'inline'
			});

			history.push("/");
		}
		
		if(userIdTxt === '') {
			cookie.save('cateringPay', 'initial');
			history.push("/");
		}
	
		if(userIdTxt !== '' && orderId !== '' && paymentId !== '') {
			
			$('.dvLoader_inpmt').show();
			
			var paymentId = base64.decode(paymentId);
			var orderId = base64.decode(orderId);
			
			axios.all([
			 axios.get(apiUrl+'cateringorders/order_requestlist?app_id='+appId+"&order_id="+orderId+ "&primary_id="+paymentId),
			 axios.get(apiUrl+'cateringreports/cateringPaymentDetails?app_id='+appId+ "&order_id="+orderId),
			 axios.get(apiUrl+'cateringreports/order_history?app_id='+appId+"&order_id="+orderId+"&customer_id="+userIdTxt),
			 axios.get(apiUrl + 'settings/get_common_settings?app_id=' + appId),
			]).then(axios.spread((rqlist,paylist,ordlist,setingLst) => { 
					  
					  var requestAmountArr = [];
					  var paymentList = [];
					  var cartItems = [];
					  var cartData = [];
					  var statusText = 'loading';
					  var stripe_envir = '';
					  var stripe_public_key = '';
					  
					  if(rqlist.data.status === "ok"){
						   requestAmountArr = rqlist.data.result_set;
					  } else {
						   history.push("/");
					  }
					  
					  if(paylist.data.status === "ok"){
							paymentList = paylist.data.result_set;
					  }

					  if(ordlist.data.status === "ok"){
							statusText = ordlist.data.status;
							cartItems = ordlist.data.result_set[0]['items'];
							cartData = ordlist.data.result_set[0];
					  } else {
						   history.push("/");
					  }
					  
					  
					   if(setingLst.data.status === "ok"){
							
						stripe_public_key = setingLst.data.result_set.stripe_public_key;
						stripe_envir = setingLst.data.result_set.stripe_envir;
							
					  } else {
						   history.push("/");
					  }
					  
					  

					  this.setState({status: statusText, cartItems: cartItems, cartData: cartData, requestAmountArr: requestAmountArr, paymentList: paymentList, payment_id: paymentId, order_id: orderId,stripe_envir: stripe_envir,stripe_public_key: stripe_public_key}, function () { this.makeBrkTimeList(); }.bind(this));	
					  
					  $('.dvLoader_inpmt').fadeOut(500);
						  		 
			 }));
			
		}
	}
	
	makeBrkTimeList() {
		var cartDetailsSate = this.state.cartData;
		var cartItems = this.state.cartItems;
		var cartBrkTmList = [];
		if(Object.keys(cartDetailsSate).length > 0 && Object.keys(cartItems).length > 0) {
			
			var localOrderNo = cartDetailsSate.order_local_no;
			var grandTotalAmt = cartDetailsSate.order_total_amount;
			var balanceAmount = cartDetailsSate.order_total_amount;
			
			var paymentListArr= this.state.paymentList;
			if(Object.keys(paymentListArr).length > 0) {
				var temBlnc = (Object.keys(paymentListArr.balanceamount).length > 0)?paymentListArr.balanceamount[0].balanceamount:0;
				if(temBlnc !== '' && parseFloat(temBlnc)>0) {
					balanceAmount = parseFloat(temBlnc);
				}
			}
			
			var paidAmount = this.state.paidAmount;
			if(parseFloat(grandTotalAmt) > parseFloat(balanceAmount)) {
				paidAmount = parseFloat(grandTotalAmt) - parseFloat(balanceAmount);
			}
			
			var requestAmountArr= this.state.requestAmountArr;
			var requestAmount= this.state.requestAmount;
			if(Object.keys(requestAmountArr).length > 0) {
				var tmpAmt = requestAmountArr.order_catering_request_amount;
				if(tmpAmt !== '' && parseFloat(tmpAmt)>0) {
					requestAmount = parseFloat(tmpAmt);
				}
			}
			
			var payment_error_txt = '';
			var payment_action = this.state.payment_action;
			if(parseFloat(requestAmount) > parseFloat(balanceAmount)) {
				payment_error_txt = 'Sorry!. You can not use this payment request, so please contact to administrator.';
				payment_action = 'No';
			}
			
			var min_payment_inf = this.state.min_payment_inf;
			if(parseFloat(requestAmount) < 0.50) {
				min_payment_inf = 'Yes';
			}
			
			this.setState({balanceAmount: balanceAmount, paidAmount: paidAmount, requestAmount: requestAmount, payment_error_txt: payment_error_txt, payment_action: payment_action, min_payment_inf: min_payment_inf, local_orderno: localOrderNo});

			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			var breaktimeCount = cartDetailsSate.order_breaktime_count;
			if(breaktimeEnable === 'Yes' && parseInt(breaktimeCount)>0) {
				var tempKeyArr = Array();
				var tempKey = 0;
				for (var key in cartItems) {
					var chkKey = cartItems[key].item_breaktime_indexflag;
					if(!(chkKey in tempKeyArr)) {
						tempKeyArr[chkKey] = tempKey;
						var tempItemArr = Array();
						var tempItemLst = [];
						var intVl = tempKey + 1;
						var time_lable = "Break "+intVl;
						tempItemArr['cart_detaile'] = {
							'break_time_lable' 		: time_lable,
							'break_time_index' 		: cartItems[key].item_breaktime_indexflag,
							'break_time_start' 		: cartItems[key].item_breaktime_started,
							'break_time_end' 		: cartItems[key].item_breaktime_ended,
						};
						tempItemLst[0] = cartItems[key] 
						tempItemArr['cart_items'] = tempItemLst;
						cartBrkTmList[tempKey] = tempItemArr;
						tempKey++;
					} else {
						var extKey = tempKeyArr[chkKey];
						cartBrkTmList[extKey]['cart_items'].push(cartItems[key]);
					}
				}
			}
		}
		
		this.setState({cart_brktime_list: cartBrkTmList});
		
		setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
			$(".mCustomScrollbar").mCustomScrollbar();
			} }, 800);

	}
	
	getCartDetList() {
		var cartDetailsSate = this.state.cartData;
		var cartBrktimeLst = this.state.cart_brktime_list;
		if(Object.keys(cartDetailsSate).length > 0) {
			var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
			if(breaktimeEnable === 'Yes' && Object.keys(cartBrktimeLst).length > 0) {
				return (this.cartBrkTimeList());
			} else {
				return (this.cartListOnly());
			}
		}
	}
	
	handlerActTab(indx) {
		var ariaAxp = $("#headingTb"+indx).find('a').attr('aria-expanded');
		if(ariaAxp === 'true') {
			$("#headingTb"+indx).addClass("act");
		} else {
			$("#headingTb"+indx).removeClass("act");
		}
		$('.panel-heading').not($("#headingTb"+indx)).removeClass("act");
	}
	
	cartListOnly() {
		var cartItems = this.state.cartItems;
		return (<div className="panel panel-default">
					<div className="panel-heading act" role="tab" id="headingTb0" onClick={this.handlerActTab.bind(this,0)}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" href="#collapse0" className="">Order Item Details</a>
						</h4>
					</div>
					<div id="collapse0" className="panel-collapse collapse mCustomScrollbar in" aria-expanded="true" href="#collapse0">
						<div className="panel-body">
						 
							{ this.cartItemlist(cartItems) }
						 
						</div>
					</div>	
				</div>);
	}
	
	cartBrkTimeList() {
		var cartBrktimeLst = this.state.cart_brktime_list;
		
		const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) =>
								  <div className="panel panel-default" key={indx}>
									<div className={(indx===0)?"panel-heading act":"panel-heading"} role="tab" id={"headingTb"+indx} onClick={this.handlerActTab.bind(this,indx)}>
										<h4 className="panel-title">
											<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}  className={(indx===0)?"":"collapsed"}>
												{cartBrk['cart_detaile'].break_time_lable} : <span className="hr">{timeToConv12(cartBrk['cart_detaile'].break_time_start)}</span></a>
										</h4>
									</div>
									<div id={"collapse"+indx} className={(indx===0)?"panel-collapse collapse mCustomScrollbar in":"panel-collapse collapse mCustomScrollbar"} aria-expanded={(indx===0)?"true":"false"} href={"#collapse"+indx}>
										<div className="panel-body">
										 
											{ this.cartItemlist(cartBrk['cart_items']) }
										 
										</div>
									</div>	
								  </div>); 
		return cartBrktmhtml;
	}
	
	cartItemlist(itemLst) {
		
		if(Object.keys(itemLst).length > 0) {
			
		const cartlsthtml = itemLst.map((cartLst, indx1) =>
								  <div key={indx1}>
								  
								   {/* package div start */}
									<div className="package">
									
									  <div className="row package_cnt">
									    <div className="col-sm-4 col-xs-4">
											<a href="javascript:void(0)">
											<span className="title">{cartLst.item_name}</span></a>
										</div>
										<div className="col-sm-4 col-xs-4">
										    <div className="qtybxs"> {cartLst.item_qty} pax </div>
									   </div>
									   <div className="col-sm-4 col-xs-4">
									        <div className="amt">
									         <span>{showPriceValue(cartLst.item_total_amount)}</span>
									        </div>
									   </div>
									 </div>	

									 {this.showModifiers(cartLst.modifiers)}	
									 
									</div>
									{/* package div end */}
									
									<div className="brline"></div>
	
									{(Object.keys(cartLst.addons_setup).length > 0)?<div className="addon"><h4>ADD-ONS</h4>{this.addonsSetupLst(cartLst)}</div>:""}

									<div className="brline"></div>

									{(Object.keys(cartLst.setup).length > 0)?this.setupDet(cartLst):""}
									
									<div className="brline"></div>
									
									{(cartLst.item_specification !== '')?this.specialNotes(cartLst):""}

								  </div>); 
		return cartlsthtml;
		
		}
		
	}
	
	showModifiers(mdfLst) {

		if(Object.keys(mdfLst).length > 0) {

			const mdflsthtml = mdfLst.map((mdfLt, indx3) =>
								  <div key={indx3} className="package-list">
									<p className="one">{mdfLt.order_modifier_name}</p>
									<p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
								  </div>);
			return mdflsthtml;
		}

	}

	showMdfValues(mdfValLst) {
		var mdfValueText = '';	
		if(Object.keys(mdfValLst).length > 0) {
			var tempVl = Object.keys(mdfValLst).length - 1;
			for (var key in mdfValLst) {
				mdfValueText += (parseInt(tempVl) === parseInt(key)) ? mdfValLst[key].order_modifier_name : mdfValLst[key].order_modifier_name+', ';
			}
		}
		return mdfValueText;
	}
	
	addonsSetupLst(cartLstDat) {
			var addonsSet = cartLstDat.addons_setup;
			const addonhtml = addonsSet.map((addonSt, indx4) =>
								  <div key={indx4} className="row addon_list">
									<div className="col-sm-6 col-xs-9">		
									  <h5><a href="javascript:void(0)">
										<span className="title">{addonSt.as_setup_title}</span>
									  </a></h5>
									  {this.addonsSetupValues(addonSt.addons_setup_values)}
									</div>		
									<div className="col-sm-6 col-xs-3">	
									  <div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									  </div>
									</div>
								  </div>);
			return addonhtml;
	}

	addonsSetupValues(addonsVal) {
		if(Object.keys(addonsVal).length > 0) {
			const addonValhtml = addonsVal.map((addonVl, indx5) =>
								  <p key={indx5}>
									{addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X)
								  </p>);
			return addonValhtml;
		}
	}

	setupDet(cartLstDat) {

		var setupDt = cartLstDat.setup;
		var setupLbl = '';
		var setupPrice = 0;

		for (var key in setupDt) {
			setupLbl = setupDt[key].os_setup_type;
			setupPrice += parseFloat(setupDt[key].os_setup_total_price);
		}

		return (<div className="buffet_setup">
				  <div className="row">
					<div className="col-sm-6 col-xs-9">		
						<h5><a href="javascript:void(0)"><span className="title">{setupLbl} Setup</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-3">	
						<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
						</div>
					</div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">	
					{this.setupList(setupDt)}	
				 </div>
				</div>
			</div>);

	}

	setupList(setupDats) {
		if(Object.keys(setupDats).length > 0) {
			const setupDathtml = setupDats.map((setupVl, indx6) =>
								  <p key={indx6}>
									{setupVl.os_setup_name} : {setupVl.os_setup_description}
								  </p>);
			return setupDathtml;
		}
	}
	
	specialNotes(cartLstDat) {

		var special_not = cartLstDat.item_specification;
		
		return (<div className="buffet_setup" style={{paddingTop: '0px'}}>
				  <div className="row">
					<div className="col-sm-6 col-xs-8">		
						<h5><a href=""><span className="title">Special Notes</span></a></h5>		
					</div>
					<div className="col-sm-6 col-xs-4"></div>
				</div>
				<div className="row">
				 <div className="col-sm-10 col-xs-10">
				 <p>{special_not}</p>
				 </div>
				</div>
			</div>);

	}
	
	cartHallData() {
		var cartDetailsMn = this.state.cartData;
		if(Object.keys(cartDetailsMn).length > 0) {
		 if(cartDetailsMn.order_venue_type === "hall") {
		   return (<div className="panel panel-default">
					<div className="panel-heading" role="tab" id="headingTbH1" onClick={this.handlerActTab.bind(this,'H1')}>
						<h4 className="panel-title">
							<a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" href="#collapseH1" className="collapsed">Catering Hall Details</a>
						</h4>
					</div>
					<div id="collapseH1" className="panel-collapse fixed-height-cls collapse mCustomScrollbar" aria-expanded="false" href="#collapseH1">
						<div className="panel-body">
						
						 <div className="buffet_setup" style={{padding: '0px'}}>
						 <div className="row">
							<div className="col-sm-6 col-xs-8">		
								<h5><a href="javascript:void(0)"><span className="title">{cartDetailsMn.order_hall_name}</span></a></h5>
							</div>
							<div className="col-sm-6 col-xs-4">	
								<div className="amt">
									<span>{showPriceValue(cartDetailsMn.order_hall_charges)}</span>
								</div>
							</div>
						 </div>
						 </div>
						 
						</div>
					</div>	
				</div>);
		 }
		}
	}
	
	getCartTotalDet() {
		var cartDeta = this.state.cartData;
		if(Object.keys(cartDeta).length > 0) {
		
		var promotionApplied = cartDeta.order_discount_applied;
		var promotionAmount = (cartDeta.order_discount_amount !== '') ? parseFloat(cartDeta.order_discount_amount) : 0;
		
		var paymentAction = this.state.payment_action;
		var minPaymentInf = this.state.min_payment_inf;
		
		return (<div className="total_amt">
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">SUBTOTAL</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_sub_total)}</div>
					</div>
					{(promotionApplied === 'Yes') && <div className="subtotal">
						<div className="col-sm-6 col-xs-6">Discount Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(promotionAmount)}</div>
					</div>}
					{(parseFloat(cartDeta.order_tax_charge) > 0) && <div className="subtotal">
						<div className="col-sm-6 col-xs-6">GST ({cartDeta.order_tax_charge} %)</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_tax_calculate_amount)}</div>
					</div>}
					<div className="total">
						<div className="col-sm-6 col-xs-6">Total</div>
						<div className="col-sm-6 rt">{showPriceValue(cartDeta.order_total_amount)}</div>
					</div>
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">Paid Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.paidAmount)}</div>
					</div>
					<div className="subtotal">
						<div className="col-sm-6 col-xs-6">Balance Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.balanceAmount)}</div>
					</div>
					<div className="total" style={{fontSize: '20px'}}>
						<div className="col-sm-6 col-xs-6">Payable Amount</div>
						<div className="col-sm-6 rt">{showPriceValue(this.state.requestAmount)}</div>
					</div>
					{(paymentAction === 'Yes' && minPaymentInf === 'Yes')?<div className="total" style={{fontSize: '12px', textAlign:'right', color: 'red'}}>
						<div className="col-sm-12 col-xs-12"> * Minimum Payable Amount : S$0.50</div>
					</div>:''}
					<div className="clearfix"></div>
				</div>);
		}
	}
	
	showUnitNumber(unit1,unit2) {
		unit1 = (typeof unit1 !== 'undefined') ? unit1 : '';
		unit2 = (typeof unit2 !== 'undefined') ? unit2 : '';
		
		if(unit1 !== '') {
			var unitNo = (unit2 !== '') ? unit1+' - '+unit2 : unit1;
		} else {
			var unitNo = unit2;
		}
		
		return unitNo;
	}
	
	setActiveTab = (tabid) => {
		
		const{history} = this.props;
		
		cookie.save('fromOtherPageFlg', tabid);
		history.push("/");
	}
	
	closeAlrt() {
		$('.customized_pick_msgdiv').hide();
	}
	
	/* post stripe account */
    onToken = (token) => {

        $.magnificPopup.open({
            items: {
                src: '.processing'
            },
            type: 'inline'
        });

        /*  load process html */
        var postObject = {};
        postObject = {
            'app_id': appId,
            'token': token.id,
            'stripe_envir': this.state.stripe_envir,
            'stripe_key': this.state.stripe_public_key,
            "customer_id": cookie.load('UserId'),
            "paid_amount": this.state.requestAmount,
            "outlet_name": '',
            "payment_reference": stripeReference
        }


        axios.post(apiUrlV2 + "payment/stripeTokenPay", qs.stringify(postObject)).then(res => {
            if (res.data.status === "ok") {
                var captureData = res.data.result_set;
				if(Object.keys(captureData).length > 0) {
					this.setState({ validateimage: tickImage });
					this.makePayment(captureData, 'Stripe');
				} else {
					$.magnificPopup.close();
					showCustomAlert('error', 'Stripe response was empty.');
				}
            }
            else if (res.data.status === "error") {
				    $.magnificPopup.close();
					/*showCustomAlert('error', res.data.message);*/
					showCustomAlert('error', 'Sorry!, geting error response from stripe.');
            }
        });

    }
	
	/* show online payment mode loading */
    onlinePaymentLoading() {
        
            return (<div className="process_col">
                <div className="process_left">
                    <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                    <h5>Processing</h5>
                    <p>Validating card information.</p>
                </div>
            </div>);
    }
	
	/* show online payment mode loading */
    orderBCLoading() {

        return (<div className="process_col">
            <div className="process_left">
                <img src={this.state.placingorderimage} />
            </div>
            <div className="process_right">
                <h5>Processing</h5>
                <p>Placing your order now.</p>
            </div>
        </div>);

    }

	 /* stripe description  */
    stripeDescription() {
        //userName
        if (typeof cookie.load('UserLname') !== 'undefined' && cookie.load('UserLname') !== "") {
            return cookie.load('UserFname') + " " + cookie.load('UserLname');
        } else {
            return cookie.load('UserLname');
        }
    }
	
	/* this fuction used to post order to biz panel */
    makePayment(captureData = '', payGetWayType = '') {
		
		const{history} = this.props;

        if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
            history.push("/");
            return false;
        }
		
		var paymentDetails = {};
		paymentDetails = { payment_type:'stripe', payment_status:'Success', payment_reference_1:captureData.payment_reference_1, payment_transaction_amount:captureData.payment_transaction_amount, payment_reference_2:captureData.payment_reference_2, payment_date:captureData.payment_date, payment_currency:captureData.payment_currency }
		
		 /*  load process html */
        var postObject = {};
        postObject = {
            'app_id': appId,
            'request_tableid': this.state.payment_id,
            'order_id': this.state.order_id,
            "payment_details": JSON.stringify(paymentDetails)
        }

		if(this.state.payment_id !== '' && this.state.order_id !== '') {
			
			axios.post(apiUrlV2 + "cateringorders/request_catering", qs.stringify(postObject)).then(res => {
				if (res.data.status === "ok") {
					this.setState({ placingorderimage: tickImage });
					this.deletePaymentCookie();
					setTimeout(function () {
						$.magnificPopup.close();
						history.push("/payment/thankyou");
					}, 1000);
				}
				else if (res.data.status === "error") {
					showAlert('Error', res.data.message);
				}
			});
		 
		} else {
			showAlert('Error', 'Payment id was null.');
		}
		
	}
	
   deletePaymentCookie() {

		var localOrderNo = this.state.local_orderno;
		var requestAmount = this.state.requestAmount;
		cookie.save('payOrderid', localOrderNo);
		cookie.save('payAmount', requestAmount);
   
        cookie.remove('cateringPay');
        cookie.remove('orderIdTxt');
        cookie.remove('paymentIdTxt');
		
    }	

  render() {
    
	if (this.state.status === "ok") {
	
		var orderDetail = this.state.cartData;
		var custAdrr = (typeof orderDetail.order_customer_address_line1 !== 'undefined') ? orderDetail.order_customer_address_line1 : '';
		custAdrr = custAdrr.replace('Singapore', ''); 
		
		var paymentErrorTxt = this.state.payment_error_txt;
		
		var stripePublicKey = this.state.stripe_public_key;


		return (
		  <div className="thankyou-page">
		  
		  {/* Header start */}
		  <Header categoryState={this.state} setActiveTab={this.setActiveTab} />
		  {/* Header End */}
		  
		  <div className="tnk-you">
			<div className="container catering_thak_you">
				<div className="tnk-header">
				
					{/* Title Info */}
					{(paymentErrorTxt !== '')?<div className="customized-pick customized_pick_msgdiv">
					<a href="javascript:void(0)" onClick={this.closeAlrt.bind(this)} className="close_info_cls" ><i className="fa fa-times" aria-hidden="true"></i></a>
					<p className="catering_info_msg">{paymentErrorTxt}</p>
					</div>:''}
					
					<div className="tick">
						<h2>Make Your Payment</h2>
						<span>Requested Payment Amount - {showPriceValue(this.state.requestAmount)} </span>
					</div>
		  
					<div className="tnk-detail">
						<h2>YOUR ORDER DETAILS</h2>
						<div className="tnk-order">
							<h3>Order No - {orderDetail.order_local_no}</h3>
							<p>Order placed at :
							{Moment(orderDetail.order_created_on).format('DD/MM/YYYY')} {Moment(orderDetail.order_created_on).format('h:mm a')}
						</p>
						</div>
					</div>
					
		  
					<div className="tnk-delivery" style={{paddingBottom: '10px'}}>
					
						<div className="delivery_total">
							<div className="delivery_total_left">
								<img src={scotterImg} />
								<h3>Catering Handeling By</h3> 
								<span>{orderDetail.outlet_name}</span>
								<span>{orderDetail.outlet_address_line1}</span> 
								<span>{CountryTxt+" "+orderDetail.outlet_postal_code}</span> 
							</div>
							<div className="delivery_total_left delivery_total_right">
								<img src={mapImg} />
								<h3>Catering Location</h3>
								{(orderDetail.order_hall_name !== '')?<span>{orderDetail.order_hall_name}</span>:''} 
								<span>{custAdrr}</span>
								{(orderDetail.order_customer_address_line2 !== '')?<span>{orderDetail.order_customer_address_line2}</span>:''}							
								<span>{CountryTxt+" "+orderDetail.order_customer_postal_code}</span> 
								<span>{this.showUnitNumber(orderDetail.order_customer_unit_no1,orderDetail.order_customer_unit_no2)}</span>
							</div>
						</div>
						
						<div className="delivery_total delivery_total_number">
							<div className="delivery_total_left">				
								<h2>Event Date</h2>
								<h4 className="checkoutDate">{Moment(orderDetail.order_date).format('DD-MM-YYYY')}</h4>
							</div>
							<div className="delivery_total_left delivery_total_right">
								<h2>Event time</h2>
								<h4 className="checkoutTime">{Moment(orderDetail.order_date).format('h:mm a')}</h4>
							</div>
						</div>
						
						<div className="order_details">
							<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							{this.getCartDetList()}
							{this.cartHallData()}
							</div>
						</div>
						
						{this.getCartTotalDet()}
					
					</div>
					
					
					<div className="chk-payment">
						<div className="chk-payment-row">
							<div className="ttsplitter ttsplitter_md text-center">
								<div className="ttsplitter_in">
									<span className="text-uppercase">Select Your Payment Method</span>
								</div>	
							</div>
							<div className="chk-payment-col">
								<ul className="chk-payment-col-radio">
								  <li>
									<input type="radio" id="card" name="payment-mode" value='2' defaultChecked />
									<label></label>
									<div className="radio_con"><img src={paymentImg} /></div>
								  </li>
								</ul>
							</div>
							<div className="chk-payment-col"></div>
						</div>
					</div>
					
					<div className="tnk-chk-order">
					{(this.state.payment_action === 'Yes' && stripePublicKey !== '')?<StripeCheckout name={stripeCompany} image={stripeImage} description={this.stripeDescription()} token={this.onToken}
                                        stripeKey={stripePublicKey} amount={(this.state.requestAmount * 100)}
                                        email={cookie.load('UserEmail')} currency={stripeCurrency}  > <a className="btn btn_black btn_minwid" href="javascript:void(0);" >Continue </a>
                                    </StripeCheckout>:<a className="btn btn_black btn_minwid" href="javascript:void(0);" disabled={this.state.paydisable} >Continue </a>}
					</div>
		  
				</div>
			</div>	
		  </div>	
			
		
		 <div id="processing-popup" className="white-popup mfp-hide popup_sec processing">
                <div className="pouup_in">
                    <h3 className="title1 text-center">We Are Processing Your Order</h3>
                    <div className="process_inner">

                        {this.onlinePaymentLoading()}
                        {this.orderBCLoading()}


                    </div>
                </div>
            </div>	
			
			
		 <Footer />	
		  <div id="dvLoading" className="dvLoader_inpmt"></div>
		</div>

		);
	
	} else {

		return (<div><div id="dvLoading" className="dvLoader_inpmt"></div></div>);

	}

	
  }
}

Payment.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(Payment);


 
