import axios from 'axios';
import { appId, apiUrl, apiUrlV2 } from "../../../Helpers/Config";

export async function getOrderHistoryData(params){
  return await axios.get(apiUrl + 'cateringreports/order_history?app_id='+appId+params)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  }); 
      
}
 
 
/* for order pdf generate */   

export async function getOrderPdfGenerateData(orderprimaryId){
  return await axios.get(apiUrlV2+'cateringorders/orderPdfgenerate?app_id='+appId+'&order_primary_id=' +orderprimaryId)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
} 


    
