import React,{Component} from 'react';
import cookie from 'react-cookies';

import PropTypes from 'prop-types';


import { withRouter, Link, Redirect} from 'react-router-dom';



class Logout extends Component {

    componentWillMount(){
        cookie.remove("UserId");
        cookie.remove("UserFname");
        cookie.remove("UserLname");
        cookie.remove("UserMobile");
        cookie.remove("UserEmail");
        cookie.remove('orderPaymentMode');
        cookie.remove('orderOutletId');
        cookie.remove('orderTableNo');
        cookie.remove('product_remarks');
        cookie.remove('orderOutletName');
        cookie.remove('orderOutletId');
        cookie.remove('carttotalitems');
        cookie.remove('cartsubtotal');
        cookie.remove('cartid');
        /* Delivery avilablity */
        cookie.remove('DeliveryDate');
        cookie.remove('DeliveryTime');
        cookie.remove('unitNoOne');
        cookie.remove('unitNoTwo');

        cookie.remove('promotion_id');
        cookie.remove('promotion_applied');
        cookie.remove('promotion_code');
        cookie.remove('promotion_delivery_charge_applied');
        cookie.remove('promotion_amount');
        cookie.remove('promotion_category');
        cookie.remove('prmo_type');
        
        /*Remove voucher*/
        cookie.remove('voucher_applied');
        cookie.remove('voucher_code');
        cookie.remove('voucher_amount');
		    


    }

  render() {

    if(this.props.history){
      return(<Redirect to="/" />);
    }
    
    return (
      <h1 className="loading-text">
        Logging out...
      </h1>
    );
  }
}


Logout.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }) 
  };
  
  
  export default withRouter(Logout);
  
  
