import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";



export async function getstaticblkData(obj){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug="+obj.slug)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}


export async function getplaceorderinfoData(url){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug=header-place-order-information")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

export async function getPromoData(url){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug=home-promotion-content")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

export async function getfootercontactinfoData(url){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug=footer-contact-info")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}


export async function getfootercontentData(url){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug=footer-content")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

export async function getfollowusData(url){
  return await axios.get(apiUrl + "settings/get_social_settings?app_id=" + appId)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

export async function getcontactcontentData(url){
  return await axios.get(apiUrl + "cms/staticblocks?app_id=" + appId + "&slug=Contactformcontent")
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
