import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getCustomerDetailData(postData){
  return await axios.get(apiUrl + 'customer/customerdetail?app_id='+appId+postData)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
