import React from 'react';
import cookie from 'react-cookies';

import $ from 'jquery';

import noimage from "../common/images/no-image.png";

/* stripslashes  */
export const stripslashes = function(str) {
	
	if(str !== '' && str !== null && str !== undefined) {
	str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    
	}
    
    return str;
};

/* Random ID  */
export const randomId = function() {
    var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < 50; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
};

/* Call image */
export const callImage = function(image_source,image,width,height,timthumb,imageTag = 'Yes') {

	var imagesource='';

	if(image!==null && image!==''){
		imagesource = timthumb+image_source+"/"+image+"&w="+width+"&h="+height+"&q=80";
	}else{
		imagesource = noimage;
	}
	if(imageTag === 'Yes'){
		return (<img src={imagesource} alt="" width={width} height={height} />);
	}else{
		return imagesource;
	}
};

/*Reference ID Generate*/
export const getReferenceID = function() {

if (typeof cookie.load('referenceId') === 'undefined') {
    var randomKey = randomId();
    cookie.save('referenceId', randomKey, {});
    return randomKey;
}
else {

    return cookie.load('referenceId');

}

};

/* address format */
export const addressFormat = function (unitOne, unitTwo, addressOne, addressTwo, postCode) {
	var unit = (unitTwo !== "" && unitOne !== "" ? "#" + unitOne + "-" + unitTwo + "," : (unitOne !== '' ? "#" + unitOne + "," : ""));
	 unit = (addressOne !== "" ? unit + addressOne + "," : unit );
	 unit = (addressTwo !== "" ? unit + addressTwo + "," : unit );
	 //unit = (postCode !== "" ? unit + postCode + "," : unit );
	  unit = (postCode !== "" ? unit+'Singapore ' + postCode  : unit );

	return (unit !== "" ? unit.replace(/,\s*$/, "") : 'N/A' );
};

/* show Alert */
export const showAlert = function(header,message) {
    $(".alert_popup").remove();
    $('body').append('<div class="white-popup mfp-hide popup_sec alert_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+message+'</p><div class="alt_btns"><a href="javascript:;" class="btn btn-sm btn_yellow popup-modal-dismiss">OK</a></div></div></div></div></div></div>');
    
};

export const showAlertCallBack = function(header,message,callBackClass,tobeOpened='') {
    $(".alert_popup").remove();
    $('body').append('<div class="white-popup mfp-hide popup_sec alert_popup"><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+message+'</p><div class="alt_btns"><a href="'+(tobeOpened?tobeOpened:'javascript:;')+'"   class="btn btn-sm btn_yellow '+callBackClass+'">OK</a></div></div></div></div></div></div>');
    
};

/* show Custom Alert */
export const showCustomAlert = function(type,message) {

	var clsIdTxt = (type === 'success') ? "jquery-success-msg" : "jquery-error-msg";
	$(".custom_alertcls").hide();
	$("."+clsIdTxt).html(message);
	$("#"+clsIdTxt).fadeIn();
    setTimeout(function()
	{
		$(".custom_alertcls").hide();
	}, 6000);
    
};

/* show Cart Count */
export const showCartItemCount = function(cartDetail) {

	var itemCount = (Object.keys(cartDetail).length > 0) ? cartDetail.cart_total_items : 0;
	cookie.save("cartTotalItems",itemCount);	
	$(".hcart_round").html(itemCount);
    
};

 /* get subtotal value  */
 export const getsubTotal = function(subTotal,OriginalAmount,promotionApplied,redeemptionApplied=null) {
	
    if(promotionApplied === "Yes"){
         return subTotal;
    }else if(redeemptionApplied === "Yes"){
         return subTotal;
    }else{
         return OriginalAmount;
    }
  
};

/* show Loader */
export const showLoader = function(divRef,type) {
	if(type === 'class') {
		$('.'+divRef).addClass("loader-main-cls").append('<div class="loader-sub-div"></div>');
	} else {
		$('#'+divRef).addClass("loader-main-cls").append('<div class="loader-sub-div"></div>');
	}
};

/* hide Loader */
export const hideLoader = function(divRef,type) {
	if(type === 'class') {
		$('.'+divRef).removeClass("loader-main-cls");
		$('.'+divRef).find(".loader-sub-div").remove();
	} else {
		$('#'+divRef).removeClass("loader-main-cls");
		$('#'+divRef).find(".loader-sub-div").remove();
	}
};

/* get subtotal value  */
export const getDeliveryCharge = function(promotionApplied,deliveryEnabled,OriginalAmount,isFreeDelivery=null) {
  
    if( (promotionApplied === "Yes" && deliveryEnabled === "Yes") || isFreeDelivery === "Yes"){
         return 0;
    }else{
         return OriginalAmount;
    }
  
};

/* sample funtion */
export const showPriceValue = function(price) { 

    price = (price !== '') ? parseFloat(price) : 0.00;
	var priceTxt = '$ '+price.toFixed(2);
	return priceTxt;
	
 };

/* sample funtion */
export const getGstValue = function(gst,subtotal,format) { 

    gst = parseFloat(gst);
    subtotal = parseFloat(subtotal);
	var gstResult = 0;
   
    if(gst > 0) {
		gstResult  = (gst /100 ) * subtotal; 
	} 
	
	 if(format === "format"){
		  return gstResult.toFixed(2);
	 }else{
		  return gstResult;
	 } 

 };
 
  /* GST Reverse Calculation function */
 export const getReverseGST = function(total) { 
    var vatDivisor = 1 + (7/100);
    var gstpercentage = 7/100;
    var productvalue = total/vatDivisor
    var gst = productvalue*gstpercentage;
    return "GST Inclusive (7%): $"+gst.toFixed(2);
  };   
  
 /* time conversion  */
 export const timeToConv12 = function(time24) {
	  var ts = time24;
	  if(ts !== "" && typeof ts !== 'undefined') {
		  var H = +ts.substr(0, 2);
		  var h = (H % 12) || 12;
		  h = (h < 10)?("0"+h):h;  
		  var ampm = H < 12 ? " AM" : " PM";
		  ts = h + ts.substr(2, 3) + ampm;
	  }
	  return ts;
  };  


