import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
/* import css files */
import Home from './Home/Home';
import Category from './Category/Category';
import Thankyou from './Category/Thankyou';
import {Orders, Logout, Myaccount, Mypromotions} from './Myaccount/Index';
import Pages from './Pages/Pages';
import ContactUs from './Pages/ContactUs';
import Account from './Account/Account';
import Resetpassword from './Account/Resetpassword';

import Payment from './Payment/Payment';
import PayThank from './Payment/Thankyou';

const NoMatch = ({ location }) => (
	<div>
		<div className="pgnot_found">
			<h1>4<span>0</span>4</h1>
			<p>The page you requested</p>
			<a href="/" className="btn btn_pink btn_minwid" title="Home">Home</a>
		</div>	
	</div>
  )

class App extends Component {

componentDidMount(){
  
}

render(){
 	return (
    <Router>
			<Switch>
			 	<Route exact path='/' component={Home}/>
			 	<Route path="/category/:catId" component={Category} />
				<Route path="/category" component={Category} />
				<Route path="/checkout" component={Category} />
				<Route path="/thankyou/:orderId" component={Thankyou} />
				<Route path="/page/:page_slug" component={Pages} />
				<Route path="/myaccount" component={Myaccount} />
				<Route path="/myorders" component={Orders} />
				<Route path="/mypromotions" component={Mypromotions} />
				<Route path="/logout" component={Logout} />
				<Route path="/mypromotions" component={Mypromotions} />
				<Route path="/contact-us" component={ContactUs} />
				<Route path="/account/activation/:activationKey" component={Account} />
				<Route path="/account/resetpassword/:resetKey" component={Resetpassword} />
				<Route path="/payment/:orderId/:paymentId" component={Payment} />
				<Route path="/payment/thankyou" component={PayThank} />
				<Route path="/payment" component={Payment} />
				<Route component={NoMatch} />
			</Switch>
    </Router>
    );
	
      }
    }
export default App;
