/* eslint-disable */
import React, { Component } from 'react';
import { withRouter, Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import { stripslashes,getReferenceID,addressFormat, showAlert, showCustomAlert, showCartItemCount, showAlertCallBack} from "../../Helpers/SettingHelper";

var Parser = require('html-react-parser');

import { fbAppId, baseUrl, appId, apiUrl, apiUrlV2, cateringId} from "../../Helpers/Config";

import axios from 'axios';
var qs = require('qs');

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestOrderinfoData,
  requestMenuData,
  requestFbLoginData,
  requestCustomerUpdateData,
  requestLoginData,
  requestRegistrationData,
  requestForgetPasswordData,
  requestSettingData,
  requestCartlistData
} from '../../modules/ui/actions';

import Moment from 'moment';
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import mainLogo from "../../common/images/logoheader.png";
import mouseImg from "../../common/images/mouse.png";
import FacebookLogin from 'react-facebook-login';
var dateFormat = require('dateformat');
import validator from 'validator'
import update from 'immutability-helper'
import { validated } from 'react-custom-validation'
import cookie from 'react-cookies';
import Cartdetails from "./Cartdetails"
import {Signup, Login, Forgotpassword} from "../Myaccount/Index";

import Packageupdate from "../Category/Packageupdate"

import ReactGA from 'react-ga';

class Header extends Component {

  constructor(props) {
    super(props);
    
    if (window.location.hostname !== 'localhost') {

		ReactGA.initialize('UA-147228440-1'); 
		ReactGA.pageview(window.location.pathname);

	}
	
    this.state = {
        menuData:[],
        fpstatus:'initiating',
        regstatus:'initiating',
        cartlistData:[],
        headerBlock: "", serachName: '',
        fields: {
            email: '',
            password: ''
        },
        fieldssignup: {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            rePassword: '',
            mobile: '',
            terms: ''

        },
        fieldsfgtpassword: {
            email: ''
        },
        opencartpopup:'',
        classname:'',

    };

     cookie.save("defaultAvilablityId", cateringId);

}

fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
}

fieldChangeSignup = (field, value) => {

    if (field == 'terms') {
        value = $("#terms").prop("checked");
    }
    this.setState(update(this.state, { fieldssignup: { [field]: { $set: value } } }))
}

fieldforgot = (field, value) => {
    this.setState(update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } }))
}

handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require('qs');
    var postObject = {
        "app_id": appId,
        "type": "web",
        "login_username": formPayload.email,
        "login_password": formPayload.password
    };
    $(".login_submit").append('<b className="gloading_img"></b>');
   
    this.props.requestLoginData(qs.stringify(postObject));
 
}
 

    componentDidMount() {
		
     this.props.requestSettingData();
     this.props.requestOrderinfoData();
     this.props.requestMenuData('Main-menu-u1g09mdhvtev7lrawiqp8ofqs');
     this.props.requestCartlistData({UserId:cookie.load('UserId'),referenceId:cookie.load('referenceId')});

	 if ($(".open-popup-link").length > 0) {
	  $('.open-popup-link').magnificPopup({
		  type: 'inline',
		  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
	  });
     }
     
     if ($(".trigger_login").length > 0) {
        $('.trigger_login').magnificPopup({
            type: 'inline',
            midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });
       }
     
      


		 /* Input lable animation */
		if ($(".input-focus").length > 0) {
			$('.input-focus').focus(function() {
				$(this).parents('.focus-out').addClass('focused');
			});
			$('.input-focus').blur(function() {
				var inputValue = $(this).val();
				if (inputValue == "") {
					$(this).removeClass('filled');
					$(this).parents('.focus-out').removeClass('focused');
				} else {
					$(this).addClass('filled');
				}
			});
	  }

	 /* Main menu navigation for mobile Start*/
    if ($(".trigger_menu").length > 0) {

        $('.trigger_menu').click(function(e) {
            e.stopPropagation();
            $(this).toggleClass('active');
            if ($('.hmenu_list').hasClass('open')) {
                $('.mega_menu').slideUp();
            }
            $('.hmenu_list').toggleClass('open');
        });

        $(document).click(function(e) {
            if (!$(e.target).is('.trigger_menu, .hmenu_list, .hmenu_list * ')) {
                if ($('.hmenu_list').is(":visible")) {
                    $('.hmenu_list').removeClass('open');
                    $('.trigger_menu').removeClass('active');
                    $('.mega_menu').slideUp();
                }
            }
        });
    } 

	  
	if ($(".tab_mobtrigger").length > 0) {
		$(".tab_mobtrigger").click(function() {
			if ($(this).hasClass('active')) {				
				$(this).removeClass('active');				
				$(this).parent().removeClass('active');
			}
			else{
				$(".tab_mobtrigger").removeClass('active')
				$(".tab_mobtrigger").parent().removeClass('active');
				$(this).addClass('active');	
				$(this).parent().addClass('active');	
			}
			
		});
	}
	if ($(".sub_tab_mobtrigger").length > 0) {
        $(".sub_tab_mobtrigger").click(function() {
			if ($(this).hasClass('active')) {				
				$(this).removeClass('active');				
				$(this).parent().removeClass('active');
			}
			else{
				$(".sub_tab_mobtrigger").removeClass('active')
				$(".sub_tab_mobtrigger").parent().removeClass('active');
				$(this).addClass('active');	
				$(this).parent().addClass('active');	
			}
            
        });
    }
    if(cookie.load('opencartpopup') == 'open')
    {
	    this.setState({classname: 'htico_cart opencartpopup'});
	    cookie.save('opencartpopup','')

	}else{
		 this.setState({classname: 'htico_cart'});
	}

}

  createLink(menu){

	if(menu.nav_type==="0")
	{

    return  <Link to={"/page/"+menu.nav_title_slug} title={menu.nav_title}><span>{menu.nav_title}</span></Link>;

    }
    else if(menu.nav_type==="3")
    {  
		/*
		 return  <Link to={menu.nav_pages} title={menu.nav_title}><span>{menu.nav_title}</span></Link>;
		*/
		 return   <Link to={(menu.nav_pages != '#') ?"/"+menu.nav_pages:''} title={menu.nav_title} target={ (menu.nav_link_type == 'blank') ?'_blank':''}><span>{menu.nav_title}</span></Link>;
	}

  }

	listMainNavigation(){
		
		   if (this.state.menuData) {
	 
            return this.state.menuData.map(function (menu, i) {
                  return  (<li key={i+100}>{this.createLink(menu)}</li>);
            },this)
                     
        }

	}
  
  

    handleSignup = () => {
        
        const formPayload = this.state.fieldssignup;
        this.setState({regstatus:'loading'});
        
        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "customer_first_name": formPayload.firstname,
            "customer_last_name": formPayload.lastname,
            "customer_email": formPayload.email,
            "customer_password": formPayload.password,
            "customer_phone": formPayload.mobile,
            "site_url": this.props.SettingData.result_set.client_site_url + "/",
        };
        this.props.requestRegistrationData(qs.stringify(postObject));
 
    }

    forgotpassword = () => {
        
        this.setState({fpstatus:'loading'});
        const formPayload = this.state.fieldsfgtpassword;

        var qs = require('qs');
        var postObject = {
            "app_id": appId,
            "type": "web",
            "email_address": formPayload.email,
            "site_url": this.props.SettingData.result_set.client_site_url + "/",
        };
        this.props.requestForgetPasswordData(qs.stringify(postObject));
      
    }

    showMessage(response){
        
           if (response.status === "ok") {
              showAlert('Success', response.message);
               
            } else {
                if (response.form_error) {
                   showAlert('Error', response.form_error);
                 } else {
                    showAlert('Error', response.message);
                 }
            }
            $.magnificPopup.open({
                items: {
                      src: '.alert_popup'
                       },
                       type: 'inline'
                 });
    }

  responseFacebook = (response) => {

    if (response.name !== "" && response.email !== "" && response.name !== undefined && response.email !== undefined) {
        var vSplitName = response.name.split(" ");
        var firstname = vSplitName[0];
        var lastname = vSplitName[1];
        var birthday = response.birthday;
        var qs = require('qs');
        var photo = response.picture.data.url;
        /* update gender field */
        var postGender = "";
        if (typeof response.gender !== "undefined" && response.gender === "male") {
            postGender = "M";
        } else if (typeof response.gender !== "undefined" && response.gender === "female") {
            postGender = "F";
        }
        /* update DOB */
        var dob = '';
        if (typeof birthday !== "undefined" && birthday !== "") {
            dob = dateFormat(response.birthday, "yyyy-mm-dd");
        }



        var postObject = {
            "app_id": appId,
            "type": "web",
            "login_firstname": response.first_name,
            "login_lastname": response.last_name,
            "login_username": response.email,
            "customer_fb_id": response.id,
            "customer_gender": postGender,
            "customer_photo": photo,
            "customer_dob": dob
        };

        this.props.requestFbLoginData(qs.stringify(postObject));
        
    }
}


doLogin(fbloginData){
    if (fbloginData.status === "ok") {
        $.magnificPopup.close();
        var mobileno = '';
        if (typeof fbloginData.result_set.customer_phone === "undefined" || fbloginData.result_set.customer_phone === "null" || fbloginData.result_set.customer_phone === "") {
            mobileno = "";
        } else {
    
            mobileno = fbloginData.result_set.customer_phone;
        }
    
        cookie.save("UserId", fbloginData.result_set.customer_id);
        cookie.save("UserEmail", fbloginData.result_set.customer_email);
        cookie.save("UserFname", (fbloginData.result_set.customer_first_name !== "" ? fbloginData.result_set.customer_first_name : ''));
        cookie.save("UserLname", (fbloginData.result_set.customer_last_name !== "" ? fbloginData.result_set.customer_last_name : ''));
        cookie.save("UserMobile", mobileno);
    
        cookie.save("UserDefaultAddress", fbloginData.result_set.customer_address_name);
        cookie.save("UserDefaultUnitOne", fbloginData.result_set.customer_address_line1);
        cookie.save("UserDefaultUnitTwo", fbloginData.result_set.customer_address_line2);
        cookie.save("UserDefaultPostalCode", fbloginData.result_set.customer_postal_code);
        
        
        
         const{history} = this.props;
    
    
		var cateringPay = (typeof cookie.load('cateringPay') === 'undefined') ? '' : cookie.load('cateringPay');
	console.log('cateringPay',cateringPay)
		if(cateringPay === 'start') {

			showAlert('Success', 'Logged in Successfully!');
			$.magnificPopup.open({
				items: {
					src: '.alert_popup'
				},
				type: 'inline'
			});
           history.push("/payment");
		
		} else { 
			
			var qs = require('qs');
			var postObject = {
				"app_id": appId,
				"reference_id": getReferenceID(),
				"customer_id": fbloginData.result_set.customer_id,
				"availability_id": cookie.load('defaultAvilablityId')
			};

			this.props.requestCustomerUpdateData(qs.stringify(postObject));
			
		}

    } else {
        
        
        showAlertCallBack('Error', 'Invalid Login Credentials','trigger_login','#login-popup');
        $.magnificPopup.open({
            items: {
                src: '.alert_popup'
            },
            type: 'inline'
        });
    }
} 


    componentWillReceiveProps(nextProps){
        
        if(nextProps.menuData !== this.props.menuData){
            this.setState({menuData: nextProps.menuData.result_set});
        }
        if(nextProps.cartlistData !== this.props.cartlistData){
           
            this.setState({cartlistData: nextProps.cartlistData});

            if ($(".mCustomScrollbar").length > 0) {
                $(".mCustomScrollbar").mCustomScrollbar();
            }
       }

        if(nextProps.fbloginData!==this.props.fbloginData){
          
			this.doLogin(nextProps.fbloginData);
        }
        if(nextProps.LoginData !==this.props.LoginData){
 
            this.doLogin(nextProps.LoginData);
        }
        
        if(nextProps.CustomerUpdateData !==this.props.CustomerUpdateData){
            
           
            if(nextProps.CustomerUpdateData.status==='ok'){
                nextProps.history.push("/checkout");
             // return <Redirect to='/checkout' />;
            }else{
                nextProps.history.push("/checkout");
              //return <Redirect to='/myaccount' />;
            }
          } 
          
          if(this.state.fpstatus==='loading'){
            if(nextProps.ForgetUpdateData !== undefined){
                this.setState({fpstatus:'ok'});
                this.showMessage(nextProps.ForgetUpdateData)       
            }
    
          }
          if(this.state.regstatus==='loading'){
            if(nextProps.RegistrationData !== undefined){
                this.setState({regstatus:'ok'});
                this.showMessage(nextProps.RegistrationData)       
            }
    
          }
          

        if(nextProps.placeorderinfoData !==this.props.placeorderinfoData){
			
			this.setState({orderinfo:Parser(stripslashes(nextProps.placeorderinfoData.result_set[0].staticblocks_description))})

        }
        
    }



	
	satePackChange = (field, value) => {
		if(field === 'editpackage_deta') {
			var cartDataDetail = value; 
			var CrdPkgData = cartDataDetail['cartLst'];
			var CrdMainData = cartDataDetail['cartMain'];
			if(Object.keys(CrdPkgData).length > 0) {
				this.setState({edite_packageid: CrdPkgData.cart_item_product_id,edit_flag: CrdPkgData.cart_item_breaktime_indexflag,cart_packagedata: CrdPkgData,cart_maindata: cartDataDetail['cartMain']}, function () { this.openPackagePopup(); }.bind(this));
			}
			
		}
	}
	
	openPackagePopup() {
		 
		
			$("#PackageEditMdl").modal({backdrop: 'static',keyboard: false});
	}

	yesProcdFun() {
	this.cartDestoryFun();
	
	}
	movecheckout(){
		
		cookie.save('trgLgin','Yes');
		
		const{history} = this.props;
		if (typeof cookie.load('UserId') === 'undefined') {
		if(cookie.load('trgLgin') === 'Yes') {
			cookie.save('fromCkeckOutVld','Yes')
			this.sateValChange('checkout_submit', 'Ok');
			$.magnificPopup.open({
				items: {
					src: '#login-popup'
				},
				type: 'inline'
			});
		}
	 } else {
	   cookie.save('fromCkeckOutVld','Yes')
		history.push("/checkout");
	 }
	}
	
	sateValChange = (field, value) => {

		if(field === 'catering_date') {
			this.setState({catering_date: value});
		}
		else if(field === 'catering_time') {
			this.setState({catering_time: value});
		}
		else if(field === 'breaktime_enable') {
			this.setState({breaktime_enable: value});
		}
		else if(field === 'breaktime_count') {
			this.setState({breaktime_count: value});
		}
		else if(field === 'breaktime_list') {
			this.setState({breaktime_list: value});
		}
		else if(field === 'break_timeHtml') {
			this.setState({break_timeHtml: value});
		}
		else if(field === 'outlet_id') {
			this.setState({catering_outlet_id: value});
		}
		else if(field === 'hall_id') {
			this.setState({catering_hall_id: value});
		}
		else if(field === 'venue_submit') {
			this.setState({trigger_venue_submit: value});
		}
		else if(field === 'catePrimaryId') {
			this.setState({catePrimaryId: value});
		}
		else if(field === 'category_id') {
			this.setState({category_id: value});
		}
		else if(field === 'checkout_submit') {
			this.setState({trigger_checkout_submit: value});
		}
		else if(field === 'category_name') {
			this.setState({category_name: value});	
		}
		else if(field === 'active_tabflag') {
			this.setState({active_tabflag: value});	
		}
		else if(field === 'add_new_item') {
			this.setState({add_new_item: value});	
		}
    }
	
	cartDestoryFun() {
		
	    const{history} = this.props;
		
		var postCrtObject = {};
			postCrtObject = {
				'app_id' : appId,
			}
		if (typeof cookie.load('UserId') === 'undefined') {
			postCrtObject['reference_id'] = getReferenceID();
		} else {
			postCrtObject['customer_id'] = cookie.load('UserId');
		}
		 $(".clear_cart").append('<b class="gloading_img"></b>');
		axios.post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
		.then(res => {
			if (res.data.status === "ok") {
			    //$(".clear_cart gloading_img").remove();
			     showAlert('success','Nice! Your cart is empty!.');
                       $.magnificPopup.open({
					items: {
                      src: '.alert_popup'
                       },
                       type: 'inline'
                 });
                        
				$.magnificPopup.close();
				var cartDetails = Array();
				showCartItemCount(cartDetails);
				
				history.push("/#/");
				
			} else {
				
				$.magnificPopup.close();
				 showAlert('error','Sorry! Products can`t update your cart');
                        $.magnificPopup.open({
                            items: {
                                src: '.alert_popup'
                            },
                            type: 'inline'
                        });
			}
		})
		.catch(function (error) {
			showCustomAlert('error','something went wrong');
			$.magnificPopup.close();
		});
		
	}
	
    
    render() {

        var details = '';
		if(this.props.cartlistData !== undefined && this.props.cartlistData.status!=='error'){
            details = this.props.cartlistData.result_set.cart_details
        }

        return (
      <header>
   <div className="header_in">
      <div className="header_top">
         <div className="htopinfo text-center">
           {this.state.orderinfo}
         </div>
      </div>
      <div className="header_middle">
      <div className="menu_icon trigger_menu">
        <span className="icon-bar icon-bar1"></span>
        <span className="icon-bar icon-bar2"></span>
        <span className="icon-bar icon-bar3"></span>
    </div>
       <Link to={"/"} className="logo" title="Ishiro">
         <img src={mainLogo} alt="Ishiro" />
         </Link>
         <div className="hmenu_sec text-right">
            
            <ul className="hmenu_list">
               	{this.state.menuData && this.listMainNavigation()}
            </ul>
            <ul className="hmenu_actions clearfix">
               <li className="horder_btn">
                  <Link to={"/category"} className="btn btn_pink btn_minwid" title="Order Now">
                  <i className="mouse"><img src={mouseImg} /></i>Order Now
                 
               </Link>
              </li>
              {!cookie.load("UserId") && <li className="htmenu_sign">
                
                <span className="htsign_proficon"></span>
                <div className="htsign_ddmenu">
                    <a href="#login-popup" data-effect="mfp-zoom-in"  className="open-popup-link htico_sign"><span>Login</span></a>
                    <span> / </span>
                    <a href="#signup-popup" data-effect="mfp-zoom-in" className="open-popup-link"> <span>Sign up</span></a>
                </div>
            </li>}
            {cookie.load("UserId") &&  <li className="htmenu_sign">
                                <span className="htsign_proficon"></span>
                                <div className="htsign_ddmenu">
                                    <Link className="htico_sign" to="/myaccount" title="My Account"> My Account</Link> <span>|</span>
                                    <Link className="htico_sign" to="/logout" title="Logout"> Logout</Link>
                                    </div>

                                </li>}
               { <li className={this.state.classname}>
                  <a href="#" title=""><i></i><span className="hcart_round">{details.cart_total_items?details.cart_total_items:0}</span></a>
				  
                  {window.location.pathname != '/checkout' && this.props.active_tabflag !== 4 && details.cart_total_items  && <Packageupdate chekoutState={this.state}  />}
				  
                  {window.location.pathname != '/checkout' && this.props.active_tabflag !== 4 && details.cart_total_items  && <div className="hcart_dropdown">
                            <div className="hcart_tt">
                                <div className="row">
                                    <div className="col-sm-6 text-left">
                                        <h3>Your Order Details</h3>
                                    </div>  
                                    <div className="col-sm-6 text-right">
                                        <a href="javascript:void(0);" className="btn btn-sm btn_yellow clear_cart" onClick={this.yesProcdFun.bind(this)}>Clear Cart</a>
                                    </div>                                  
                                </div>
                            </div>
                            <div className="hcart_scrollarea mCustomScrollbar">          
                           <div className="cart_list_sec">    
                           {/* <div className="delivery_total">
                            <div className="delivery_total_left">
                                <img src={scotterImg} />
                                <h3>Catering Handeling By</h3> <span>{details.outlet_name}</span><span>{addressFormat(details.outlet_unit_number1, details.outlet_unit_number2, details.outlet_address_line1, details.outlet_address_line2, details.outlet_postal_code)}</span> </div>
                            <div className="delivery_total_left delivery_total_right">
                                <img src={mapImg} />
                                <h3>Catering Location</h3>
                                {(details.order_hall_name !== '')?<span>{details.order_hall_name}</span>:''}	
                                <span>
                                    {addressFormat(details.order_customer_unit_no1,details.order_customer_unit_no2,details.order_customer_address_line1,details.order_customer_address_line2,details.order_customer_postal_code)}
                                </span></div>
                        </div>

                        <div className="delivery_total delivery_total_number">
                            <div className="delivery_total_left">
                                <h2>Event Date</h2>
                                <h4 className="checkoutDate">{Moment(details.order_date).format('DD-MM-YYYY')}</h4>
                            </div>
                            <div className="delivery_total_left delivery_total_right">
                                <h2>Event time</h2>
                                <h4 className="checkoutTime">{Moment(details.order_date).format('hh:mm A')}</h4>
                            </div>
                            </div>    */}                

                            <div className="order_details">	
                                  
                                <Cartdetails  satePackChangefun={this.satePackChange}  />
                                
                               <a href="javascript:void(0);" className="btn btn-sm btn_pink" onClick={this.movecheckout.bind(this)}>Checkout</a>
                            </div>
                            </div>
                            </div>
                            </div>}
               </li>}
           { /*<li className="htico_search"> 
                <a className="search_icon" href="javascript:void(0);"><i></i></a>                
            </li>*/} 
            </ul>
            {/*<div className="hsearch_sec">
               <div className="hsearch_bx">
                  <input type="text" className="form-control" />
                  <input type="submit" value="" className="btn btn_orange" />
               </div>
            </div>*/}
         </div>
      </div>
   </div>
 
 {/* login popup */}

 <div id="login-popup" className="white-popup mfp-hide popup_sec login-popup">
 <div className="pop-whole">
     <div className="pop-whole-lhs">
         <div className="pop-whole-lhs-inner">
             <h3>Have an account ?</h3>
             <p>Log in with your details to manage.</p>
         </div>
     </div>
     <div className="inside-popup-rhs">
         <div className="popup-footer">
             <FacebookLogin
                 appId={fbAppId}
                 fields="name,email,picture,first_name,last_name,birthday,gender"
                 callback={this.responseFacebook}
                 scope="public_profile,email,user_birthday"
                 cssclassName="btn btn-black  fa fa-facebook"
                 redirectUri={baseUrl}
                 icon="fa-facebook"
                 textButton="&nbsp; Login with Facebook"
             />

             <div className="or-seperator"><span>Or</span></div>
         </div>
         <div className="popup-header textcenter">
             <h4>Login with<img src={mainLogo} /></h4>
         </div>
         <Login fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleSignin} onInvalid={() => console.log('Form invalid!')} />

     </div>
 </div>
</div>

{/* Signup popup */}
<div id="signup-popup" className="white-popup mfp-hide popup_sec signup-popup">
 <div className="pop-whole">
     <div className="pop-whole-lhs">
         <div className="pop-whole-lhs-inner">
             <h3>New to Ishiro ?</h3>
             <p>Create your ishiro account.</p>
         </div>
     </div>
     <div className="inside-popup-rhs">
         <div className="popup-footer">
             <FacebookLogin
                 appId={fbAppId}
                 fields="name,email,picture,first_name,last_name,birthday,gender"
                 callback={this.responseFacebook}
                 scope="public_profile,email,user_birthday"
                 cssclassName="btn btn-black  fa fa-facebook"
                 redirectUri={baseUrl}
                 icon="fa-facebook"
                 textButton="&nbsp; Login with Facebook"
             />
             <div className="or-seperator"><span>Or</span></div>
         </div>
         <div className="popup-header textcenter">
             <h4>Create account with <img src={mainLogo} /></h4>
         </div>
         <Signup fields={this.state.fieldssignup} onChange={this.fieldChangeSignup} onValid={this.handleSignup} onInvalid={() => console.log('Form invalid!')} />
     </div>
 </div>
</div>

{/* Forgot Password Popup */}

<div id="forgot-password-popup" className="white-popup mfp-hide popup_sec changepw_popup">
 <div className="pouup_in">
     <h3 className="title1 text-center">Forgot Password</h3>
     <Forgotpassword fields={this.state.fieldsfgtpassword} onChange={this.fieldforgot} onValid={this.forgotpassword} onInvalid={() => console.log('Form invalid!')} />
 </div>
</div>



</header>

        );
    }
} 


function mapStateToProps(appState, ownProps) {

   
  
  return {
    placeorderinfoData: appState.ui.placeorderinfoData,
    menuData: appState.ui.MenuData,
    fbloginData: appState.ui.FbLoginData,
    CustomerUpdateData:appState.ui.CustomerUpdateData,
    LoginData:appState.ui.LoginData,
    RegistrationData:appState.ui.RegistrationData,
    ForgetUpdateData:appState.ui.ForgetUpdateData,
    SettingData:appState.ui.SettingData,
    cartlistData: appState.ui.cartlistData,
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestOrderinfoData,
      requestMenuData,
      requestFbLoginData,
      requestLoginData,
      requestRegistrationData,
      requestForgetPasswordData,
      requestCustomerUpdateData,
      requestSettingData,
      requestCartlistData
    }, dispatch)
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
