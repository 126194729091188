/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';

import { timThumpUrl,cateringId } from "../../Helpers/Config";

import { stripslashes, callImage, getReferenceID, showCustomAlert, showAlert, showCartItemCount, showPriceValue } from "../../Helpers/SettingHelper";
var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestCatlistData,
  requestCartlistData,
  requestProdetailData,
} from '../../modules/ui/actions';

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import Datetimelist from "./Datetimelist";
import Packagedetails from "./Packagedetails";
import Checkout from "./Checkout";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();


class Category extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			commonTabCls:'resp-tab-item', 
			categoryActCls:'active', 
			venueDetActCls:'', 
			packageActCls:'', 
			checkoutActCls:'',
			active_tabflag: 1,
			catering_date: '', 
			catering_time: '',  
			breaktime_enable: 'No',
			breaktime_count: 1, 
			breaktime_list: [], 
			break_timeHtml: '',
			cartItems: [], 
			cartDetails: [], 
			cartTotalItmCount: 0, 
			catePrimaryId: '', 
			category_id: '', 
			category_name: '', 
			catering_outlet_id: '', 
			catering_hall_id: '', 
			cart_outlet_id: '', 
			cart_outlet_txt: '', 
			cart_hall_id: '', 
			cart_hall_txt: '', 
			trigger_venue_submit: 'No', 
			trigger_checkout_submit: 'No', 
			add_new_item: 'No',
			catLoading:false,
			cartContentLoading:false,
			proDetailLoading:false,
			catlist:'',
			catviewmenu_flag:false,
			};

    }

	componentDidMount() {

		var catId = this.props.match.params.catId;

		if(typeof catId !== 'undefined') {

			this.props.requestCatlistData({pro_cate_id:catId});
			this.setState({catviewmenu_flag:true});

		} else {
			 this.props.requestCatlistData({});
		}

       this.loadCart();

       var cateringPay = (typeof cookie.load('cateringPay') === 'undefined') ? '' : cookie.load('cateringPay');
		if(cateringPay === 'initial') {
			cookie.save('cateringPay', 'start');
			$.magnificPopup.open({
				items: {
					src: '#login-popup'
				},
				type: 'inline'
			});
		}

	}

	componentWillReceiveProps(nextProps) {

		if(nextProps.match.params.catId != this.props.match.params.catId) {
			if(typeof catId !== 'undefined') {

			this.props.requestCatlistData({pro_cate_id:catId});
			this.setState({catviewmenu_flag:true});

			} else {
					this.setState({catviewmenu_flag:false});
				 this.props.requestCatlistData({});
			}
		}
		

		 if(typeof nextProps.catlistData != 'undefined') {

			 if(nextProps.catlistData.status == 'ok' && (this.state.catLoading == false || nextProps.catlistData!= this.props.catlistData)) {

				this.setState({catLoading:true});
				this.setState({catlist:nextProps.catlistData});

				if(this.state.catviewmenu_flag) {

					this.saveCategoryDetails(nextProps.catlistData.result_set[0]);

					this.setActiveTab(3);
				}

			 }
		}


		 /*Get Cart list*/
		 if(typeof nextProps.cartlistData != 'undefined') {

			 if(nextProps.cartlistData.status == 'ok' && this.state.cartContentLoading == false) {

			 var resultSetArr = (!('result_set' in nextProps.cartlistData)) ? [] : nextProps.cartlistData.result_set;

			 if ((nextProps.cartlistData.status === "ok") && (Object.keys(resultSetArr).length > 0)) {
				var cartDetailsMain = resultSetArr.cart_details;
				var cartItems = resultSetArr.cart_items;
				var totalCartItems = resultSetArr.cart_details.cart_total_items;
				cookie.save("cartTotalItems",totalCartItems);
				
				if(cartDetailsMain.cart_breaktime_enable === 'Yes') {
					var cartBrkLst = (cartDetailsMain.cart_breaktime_list !== '' && cartDetailsMain.cart_breaktime_list !== null)?JSON.parse(cartDetailsMain.cart_breaktime_list):[];
					this.setState({ breaktime_enable: cartDetailsMain.cart_breaktime_enable });
					this.setState({ breaktime_count: cartDetailsMain.cart_breaktime_count });
					this.setState({ breaktime_list: cartBrkLst });
				}
				
			} else {
				var cartDetailsMain = [];
				var cartItems = [];
				var totalCartItems = 0;
			}
			this.setState({ cartTotalItmCount: totalCartItems });
			this.setState({ cartDetails: cartDetailsMain });
			this.setState({ cartItems: cartItems });
			
			this.setState({cartContentLoading:true});
			
			this.setState({cartTotalItmCount: totalCartItems, cartDetails: cartDetailsMain, cartItems: cartItems}, function () { this.getSingleCartProduct(); }.bind(this));
			
		}

		 }

		  /*Get product details*/
		 if(typeof nextProps.prodetailData != 'undefined') {
			 
			 if(nextProps.prodetailData.status == 'success' && this.state.proDetailLoading == false) {
				
				if(Object.keys(nextProps.prodetailData.result_set).length > 0) {
							let proResultset = nextProps.prodetailData.result_set[0];
							cookie.save('catering_categoryPryId', proResultset.pro_cate_primary_id);
							cookie.save('catering_categoryId', proResultset.product_category_id);
							cookie.save('catering_categoryName', proResultset.catgory_name);
							this.setState({catePrimaryId: proResultset.pro_cate_primary_id, category_id: proResultset.product_category_id, category_name: proResultset.catgory_name,proDetailLoading:true});
						}
			 
			 
			 }
		 
		 
		 }
		 
		 
	}

	/*Load cart*/
	loadCart() {

		cookie.save("cartTotalItems",0);
		cookie.save("cateringOutletId",235);

		/*cookie.save("UserId",12789);

		cookie.save("UserFname",'Jach');
		cookie.save("UserLname",'Jach');
		cookie.save("UserEmail",'jachtest1@gmail.com');*/
		/*cookie.save("UserMobile",'');*/

		this.props.requestCartlistData({UserId:cookie.load('UserId'),referenceId:cookie.load('referenceId')});

    }

	getSingleCartProduct() {

		var cartItems = this.state.cartItems;

		if(Object.keys(cartItems).length > 0) {

		  var crtProductId = cartItems[0].cart_item_product_id;
		  if(crtProductId !== '') {

			 this.props.requestProdetailData({cateringId:cateringId,productId:crtProductId}); 

		  }

		  var cartDetailsArr = this.state.cartDetails;
		  var outletId = cartDetailsArr.outlet_id;
		  var hallId = cartDetailsArr.cart_hall_id;
		  var hallChrg = cartDetailsArr.cart_hall_charges;
		  var eventtypeTxt = cartDetailsArr.cart_venue_type;
		  
		  if(eventtypeTxt === 'hall') {
			 cookie.save('catering_eventType', 'hall');
		  } else {
			 cookie.save('catering_eventType', 'venue');
		  }

		}
	}

	setActiveTab = (tabid) => {

			var userId = cookie.load('UserId');
			var catgPryId = cookie.load('catering_categoryPryId');
			var eventType = cookie.load('catering_eventType');
			var OutletId = cookie.load('cateringOutletId');
			var HallId = cookie.load('cateringHallId');
			var totalItems = cookie.load('cartTotalItems');

			var sateCatPrmId = this.state.catePrimaryId;
			sateCatPrmId = (sateCatPrmId === '') ? catgPryId : sateCatPrmId;

			var categoryAct = '';
			var venueDetAct = '';
			var packageAct = '';
			var checkoutAct = '';

			if(tabid === 1) {
				categoryAct = 'active';
			} else if(tabid === 2) {
				if (typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') {
					categoryAct = 'active'; venueDetAct = 'active';
				} else {
					tabid = 1;
					categoryAct = 'active';
				}
			} else if(tabid === 3) {

				/*&& typeof OutletId !== 'undefined' && OutletId !== ''*/

				if ((typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') && ((eventType === 'venue' ) || (eventType === 'hall' && typeof HallId !== 'undefined' && HallId !== ''))) {
					
					categoryAct = 'active'; venueDetAct = 'active'; packageAct = 'active';

				} else if (typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') {
					tabid = 2;
					categoryAct = 'active'; venueDetAct = 'active';
				} else {
					tabid = 1;
					categoryAct = 'active';
				}

			} else if(tabid === 4) {

				/*&& typeof OutletId !== 'undefined' && OutletId !== ''*/
				/* && typeof HallId !== 'undefined' && HallId !== ''*/

				if (
					(typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') 
					&& 
					((eventType === 'venue' ) || (eventType === 'hall')) 
					&& 
					(parseInt(totalItems)>0)
				) {
					
					
					
					if(typeof userId !== 'undefined' && userId !== '') {
						categoryAct = 'active'; venueDetAct = 'active'; packageAct = 'active'; checkoutAct = 'active';
					} else {
						tabid = 1;
						categoryAct = 'active';
						this.setState({trigger_checkout_submit: 'Yes'});
						cookie.save('fromCkeckOutVld', 'Yes');
					}
				} else if (
				(typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') 
				&& 
					(
					(eventType === 'venue')
					|| 
					(eventType === 'hall')
					)
				) {

					/*if((this.state.catering_outlet_id === OutletId) && (this.state.catering_hall_id === HallId)) {
					*/	tabid = 3;
						categoryAct = 'active'; venueDetAct = 'active'; packageAct = 'active';
						if(parseInt(totalItems) === 0) {
							showCustomAlert('error','Sorry! Your cart is empty.');
						}
					/*} else {
						tabid = 2;
						categoryAct = 'active'; venueDetAct = 'active';
						this.setState({trigger_venue_submit: 'Yes'});
					}*/

				} else if (typeof catgPryId !== 'undefined' && catgPryId !== '' && sateCatPrmId !== '') {
					tabid = 2;
					categoryAct = 'active'; venueDetAct = 'active';
				} else {
					tabid = 1;
					categoryAct = 'active';
					
					$('.dvLoadingCls').fadeOut(500);

					if($('.checkoutTopDiv').length) {
						$('.checkoutTopDiv').removeClass('active');
					}

					if($('.tab_mobtrigger_cls').length) {
						$('.tab_mobtrigger_cls').removeClass('active');
					}
					
					
				}

			}

		   this.setState({active_tabflag: tabid});

		   this.setState({categoryActCls: categoryAct});
		   this.setState({venueDetActCls: venueDetAct});
		   this.setState({packageActCls: packageAct});
		   this.setState({checkoutActCls: checkoutAct});
	}

	showsActiveDiv = (valtxt) => {
		var tabflag = this.state.active_tabflag;
		return (parseInt(valtxt) === parseInt(tabflag)) ? 'block' : 'none';
	}

	showsActiveHdr = (valtxt) => {
		var tabflag = this.state.active_tabflag;
		return (parseInt(valtxt) === parseInt(tabflag)) ? 'active' : '';
	}

	saveCategoryDetails(categoryDet) {

		if(Object.keys(categoryDet).length > 0) {

			this.sateValChange('catePrimaryId', categoryDet.pro_cate_primary_id);
			this.sateValChange('category_id', categoryDet.pro_cate_id);
			this.sateValChange('category_name', categoryDet.pro_cate_name);

			cookie.save('catering_categoryPryId', categoryDet.pro_cate_primary_id);
			cookie.save('catering_categoryId', categoryDet.pro_cate_id);
			cookie.save('catering_categoryName', categoryDet.pro_cate_name);

			this.setState({catePrimaryId: categoryDet.pro_cate_primary_id, category_id: categoryDet.pro_cate_id, category_name: categoryDet.pro_cate_name});

			if(this.state.catviewmenu_flag) {
				this.setActiveTab(3);
			} else {

			   this.setActiveTab(2);

			}

		}
	}
	
	sateValChange = (field, value) => {

		if(field === 'catering_date') {
			this.setState({catering_date: value});
		}
		else if(field === 'catering_time') {
			this.setState({catering_time: value});
		}
		else if(field === 'breaktime_enable') {
			this.setState({breaktime_enable: value});
		}
		else if(field === 'breaktime_count') {
			this.setState({breaktime_count: value});
		}
		else if(field === 'breaktime_list') {
			this.setState({breaktime_list: value});
		}
		else if(field === 'break_timeHtml') {
			this.setState({break_timeHtml: value});
		}
		else if(field === 'outlet_id') {
			this.setState({catering_outlet_id: value});
		}
		else if(field === 'hall_id') {
			this.setState({catering_hall_id: value});
		}
		else if(field === 'venue_submit') {
			this.setState({trigger_venue_submit: value});
		}
		else if(field === 'catePrimaryId') {
			this.setState({catePrimaryId: value});
		}
		else if(field === 'category_id') {
			this.setState({category_id: value});
		}
		else if(field === 'checkout_submit') {
			this.setState({trigger_checkout_submit: value});
		}
		else if(field === 'category_name') {
			this.setState({category_name: value});	
		}
		else if(field === 'active_tabflag') {
			this.setState({active_tabflag: value});	
		}
		else if(field === 'add_new_item') {
			this.setState({add_new_item: value});	
		}
    }

	setPropActiveTab(tagvalue) {
		var tabflag = this.state.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.setActiveTab(parseInt(tagvalue));
		} else {
			this.sateValChange('active_tabflag', 0);
		}
	}
	
 render() {

		let commonimgurl_obj;
		let categorylist = [];

		if(this.state.catlist) {

			categorylist = this.state.catlist.result_set;
			commonimgurl_obj = this.state.catlist.common;

		}

    
    return (
      <div className="Home cateringPg">
		<Header {...this.state}  />
		<div className="tmenu_scroll">
			<ul>
				<li className={this.state.commonTabCls+' categorylistLi '+this.state.categoryActCls} onClick={this.setActiveTab.bind(this,1)}><a href="javascript:void(0)">
				CATEGORIES</a></li>

				<li className={this.state.commonTabCls+' venueDetLi '+this.state.venueDetActCls} onClick={this.setActiveTab.bind(this,2)}><a href="javascript:void(0)">
				VENUE DETAILS</a></li>

				<li className={this.state.commonTabCls+' packageLi '+this.state.packageActCls} onClick={this.setActiveTab.bind(this,3)}><a href="javascript:void(0)">
				UPDATE YOUR PACKAGE</a></li>

				<li className={this.state.commonTabCls+' checkoutLi '+this.state.checkoutActCls} onClick={this.setActiveTab.bind(this,4)}><a href="javascript:void(0)">CHECKOUT
				</a></li>
			</ul>
		</div>
		<div className="catering_wrap">

			<div className="title_sec text-center catering_mobremovett">
				<h2>Delivery Menu</h2>
				{/*}<small>text box description will be here. sample text line here text box description will be here. sample text line here</small> {*/}
			</div>

			<div className="tab_sec order_nav">

				<ul className="nav nav-tabs">

				<li className={this.state.commonTabCls+' categorylistLi '+this.state.categoryActCls} onClick={this.setActiveTab.bind(this,1)}><a href="javascript:void(0)">
				<span className="nos">1</span><span>CATEGORIES</span></a></li>

				<li className={this.state.commonTabCls+' venueDetLi '+this.state.venueDetActCls} onClick={this.setActiveTab.bind(this,2)}><a href="javascript:void(0)">
				<span className="nos">2</span><span>VENUE DETAILS</span></a></li>

				<li className={this.state.commonTabCls+' packageLi '+this.state.packageActCls} onClick={this.setActiveTab.bind(this,3)}><a href="javascript:void(0)">
				<span className="nos">3</span><span>UPDATE YOUR PACKAGE</span></a></li>

				<li className={this.state.commonTabCls+' checkoutLi '+this.state.checkoutActCls} onClick={this.setActiveTab.bind(this,4)}><a href="javascript:void(0)"><span className="nos">4</span><span>CHECKOUT</span>
				</a></li>

				</ul>

				<div className="tab-content">

						<div className="tab-pane categorylistTopDiv" style={{display:this.showsActiveDiv(1)}}>
							
						{/* <h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.showsActiveHdr(1)} onClick={this.setPropActiveTab.bind(this,1)} >CATEGORIES<i></i></h4>	 */}
							<div className="tab_mobrow" style={{display:this.showsActiveDiv(1)}}>	
		
								<div className="ctgselect_sec">
									<div className="ctg_gridsec">
<div className="row">

{
(categorylist || []).map(
(category,index) => {
return (
	<div className="col-md-3 col-sm-4 pkg_gcol" key={index}>
	<div className="ctg_item">
		<div className="ctg_img">
			{callImage(commonimgurl_obj.category_image_url, category.pro_cate_image, 370, 323, timThumpUrl)}
			<div className="ctg_info">
				<div className="ctg_inner">
					<div className="ctg_text">
					<h4>{stripslashes(category.pro_cate_name)}</h4>
					<p>{stripslashes(category.pro_cate_short_description)}</p>
					</div>
					<div className="ctg_btnsec text-center">
						
					{(category.pro_cate_menu_file !== '' && category.pro_cate_menu_file !== null) ? <a href={commonimgurl_obj.category_menu_file_url+category.pro_cate_menu_file} target="_blank" className="btn btn_block ">Download Menu</a> : <a href="javascript:void(0)" className="btn btn_block ">Download Menu</a>}
					
					{/*<a href="/ishiro.pdf" className="btn btn_block" target="_blank">Download Menu</a>*/}
						
			<a href="javascript:void(0)" onClick={this.saveCategoryDetails.bind(this, category)} className="btn btn_pink " title="Place Order">Place Order</a>
							</div>  
						</div>
					</div>
				</div>
			</div>
			</div>
	  
	  )
	}
	) 
	}

										</div>
									</div>		
								</div>
							</div>
							

						</div> {/*}tabid-1{*/}
						
						
						
						{/* Tab2 Venue Details */}
						<div className="tab-pane venueTopDiv" style={{display:this.showsActiveDiv(2)}}>
							<Datetimelist categoryState={this.state} sateValChangefun={this.sateValChange} setActiveTab={this.setActiveTab} showsActiveDiv={this.showsActiveDiv} showsActiveHdr={this.showsActiveHdr}/>
						</div>
						
						
						
						{/* Tab3 Package Details */}
						<div className="tab-pane packageTopDiv upkg_tabsec" style={{display:this.showsActiveDiv(3)}}>
							<Packagedetails categoryState={this.state} cartTotalItmCount={this.state.cartTotalItmCount} sateValChangefun={this.sateValChange} setActiveTab={this.setActiveTab} showsActiveDiv={this.showsActiveDiv} showsActiveHdr={this.showsActiveHdr}/>
						</div>
						
						{/* Tab4 Checkout Details */}
						<div className="tab-pane checkoutTopDiv" style={{display:this.showsActiveDiv(4)}}>
							<Checkout categoryState={this.state} sateValChangefun={this.sateValChange} setActiveTab={this.setActiveTab} showsActiveDiv={this.showsActiveDiv} showsActiveHdr={this.showsActiveHdr}/>
						</div>

				</div>
					
				

		   </div>
			
		</div>

			
		<Footer />  
	
	</div>

    );
  }
}
 
function mapStateToProps(appState, ownProps) {

  return {
    catlistData: appState.ui.catlistData,
    cartlistData: appState.ui.cartlistData,
    prodetailData: appState.ui.prodetailData
  }

}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestCatlistData,
      requestCartlistData,
      requestProdetailData
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Category);
