import { handleAction, combineActions } from 'redux-actions';

import {
	
  RECEIVE_STATICBLK_DATA,
  receiveStaicblkData,

  RECEIVE_BANNER_DATA,
  receiveBannerData,

  RECEIVE_PROMO_DATA,
  receivePromoData,

  RECEIVE_HEADERORDERINFO_DATA,
  receiveOrderinfoData,

  RECEIVE_FOOTERCONTACTINFO_DATA,
  receiveContactinfoData,

  RECEIVE_FOOTER_DATA,
  receiveFooterData,

  RECEIVE_FOLLOWUS_DATA,
  receiveFollowusData,
  
  RECEIVE_CATLIST_DATA,
  receiveCatlistData,

  RECEIVE_CATOUTLET_DATA,
  receiveCatoutletData,

  RECEIVE_CATHALL_DATA,
  receiveCathallData,
  
  RECEIVE_CATDATE_DATA,
  receiveCatdateData,

  RECEIVE_CATTIME_DATA,
  receiveCattimeData,

  RECEIVE_PROLIST_DATA,
  receiveProlistData,

  RECEIVE_PRODETAIL_DATA,
  receiveProdetailData,
  
  RECEIVE_CARTLIST_DATA,
  receiveCartlistData,
  
  RECEIVE_PAGES_DATA,
  receivePageData,

  RECEIVE_MENU_DATA,
  receiveMenuData,
  
  RECEIVE_FBLOGIN_DATA,
  receiveFbLoginData,
  
  RECEIVE_CUSTOMERUPDATE_DATA,
  receiveCustomerUpdateData,
  
  RECEIVE_FORGETPASSWORD_DATA,
  receiveForgetPasswordData,
  
  RECEIVE_REGISTRATION_DATA,
  receiveRegistrationData,
  
  RECEIVE_LOGIN_DATA,
  receiveLoginData,
  
  RECEIVE_CUSTOMERDETAIL_DATA,
  receiveCustomerDetailData,
  
  RECEIVE_UPDATECUSTOMERPROFILE_DATA,
  receiveUpdateCustomerProfileData,
  
  RECEIVE_EMAILEXIST_DATA,
  receiveEmailExistData,
  
  RECEIVE_PHONEEXIST_DATA,
  receivePhoneExistData,
  
  RECEIVE_ADDRESS_DATA,
  receiveAddressData,
  
  RECEIVE_CHANGEPASSWORD_DATA,
  receiveChangePasswordData,
  
  RECEIVE_SECONDARYADDREMOVE_DATA,
  receiveSecondaryAddRemoveData,
  
  RECEIVE_ALLUSERSECADDR_DATA,
  receiveAllUserSecAddrData,
  
  RECEIVE_PORDERHISTORY_DATA,
  receivePorderHistoryData,
  
  RECEIVE_CORDERHISTORY_DATA,
  receiveCorderHistoryData,
  
  RECEIVE_ORDERHISTORY_DATA,
  receiveOrderHistoryData,
  
  RECEIVE_APPLYPROMOTION_DATA,
  receiveApplyPromotionData,
  
  RECEIVE_RESETPASSWORD_DATA,
  receiveResetPasswordData,
  
  RECEIVE_PROMOTIONLIST_DATA,
  receivePromotionListData,
  
  RECEIVE_CONTACTUS_DATA,
  receiveContactUsData,
  
  RECEIVE_CONTACTCONTENT_DATA,
  receiveContactcontentData,
  
  RECEIVE_SETTING_DATA,
  receiveSettingData,
  
  RECEIVE_PRINTORDERRECEIPT_DATA,
  receivePrintOrderReceiptData,
  
  RECEIVE_RECEIPTPROMOTION_DATA,
  receiveReceiptPromotionData,
  
  RECEIVE_DOWNLOADQUOTATION_DATA,
  receiveDownloadQuotationData,
  
  RECEIVE_LOGINDOWNLOADQUOTATION_DATA,
  receiveLoginDownloadQuotationData,
  
  
} from './actions';

var defaultState = {};

export const ui = handleAction(combineActions(
  receiveStaicblkData,
  receiveBannerData,
  receivePromoData,
  receiveOrderinfoData,
  receiveContactinfoData,
  receiveFooterData,
  receiveFollowusData,
  receiveCatlistData,
  receiveCatoutletData,
  receiveCathallData,
  receiveCatdateData,
  receiveCattimeData,
  receiveProlistData,
  receiveProdetailData,
  receiveCartlistData,
  receivePageData,
  receiveMenuData,
  receiveCustomerUpdateData,
  receiveForgetPasswordData,
  receiveRegistrationData,
  receiveLoginData,
  receiveFbLoginData,
  receiveCustomerDetailData,
  receiveUpdateCustomerProfileData,
  receiveEmailExistData,
  receivePhoneExistData,
  receiveAddressData,
  receiveChangePasswordData,
  receiveSecondaryAddRemoveData,
  receiveAllUserSecAddrData,
  receivePorderHistoryData,
  receiveCorderHistoryData,
  receiveOrderHistoryData,
  receiveApplyPromotionData,
  receiveResetPasswordData,
  receivePromotionListData,
  receiveContactUsData,
  receiveContactcontentData,
  receiveSettingData,
  receivePrintOrderReceiptData,
  receiveReceiptPromotionData,
  receiveDownloadQuotationData,
  receiveLoginDownloadQuotationData,


), {
    next(state, action) {
		
		 var newState;
		
      switch (action.type) {
		  
		  
		    case RECEIVE_STATICBLK_DATA:
			   newState = {
				...state
			  };

			  newState.staicblkData = action.payload;

			  return newState;
		  
	
          case RECEIVE_BANNER_DATA:
			   newState = {
				...state
			  };

			  newState.bannerData = action.payload;

			  return newState;

		 case RECEIVE_PROMO_DATA:
			   newState = {
				...state
			  };

			  newState.promoData = action.payload;

			  return newState;

		 case RECEIVE_HEADERORDERINFO_DATA:
			   newState = {
				...state
			  };

			  newState.placeorderinfoData = action.payload;

			  return newState;

		 case RECEIVE_FOOTERCONTACTINFO_DATA:
			   newState = {
				...state
			  };

			  newState.footercontactinfoData = action.payload;

			  return newState;

		case RECEIVE_FOOTER_DATA:
			   newState = {
				...state
			  };

			  newState.footerData = action.payload;

			  return newState;

		case RECEIVE_FOLLOWUS_DATA:
			   newState = {
				...state
			  };

			  newState.followusData = action.payload;

			  return newState;
			  
		case RECEIVE_CATLIST_DATA:
			   newState = {
				...state
			  };

			  newState.catlistData = action.payload;

			  return newState; 
			  
		case RECEIVE_CATOUTLET_DATA:
			   newState = {
				...state
			  };

			  newState.catoutletData = action.payload;

			  return newState;
			  
		case RECEIVE_CATHALL_DATA:
			   newState = {
				...state
			  };

			  newState.cathallData = action.payload;

			  return newState;

		case RECEIVE_CATDATE_DATA:
			   newState = {
				...state
			  };

			  newState.catdateData = action.payload;

			  return newState;

		case RECEIVE_CATTIME_DATA:
			   newState = {
				...state
			  };

			  newState.cattimeData = action.payload;

			  return newState;

		case RECEIVE_PROLIST_DATA:
			   newState = {
				...state
			  };

			  newState.prolistData = action.payload;

			  return newState;

		case RECEIVE_PRODETAIL_DATA:
			   newState = {
				...state
			  };

			  newState.prodetailData = action.payload;

			  return newState;

		case RECEIVE_CARTLIST_DATA:
			   newState = {
				...state
			  };

			  newState.cartlistData = action.payload;

			  return newState;
			  
	    case RECEIVE_PAGES_DATA:
			   newState = {
				...state
			  };

			  newState.PageData = action.payload;
			  return newState;
			  
			  
	    case RECEIVE_MENU_DATA:
			   newState = {
				...state
			  };

			  newState.MenuData = action.payload;
			  return newState;
	   
	   case RECEIVE_FBLOGIN_DATA:
		   newState = {
			...state
		  };

			  newState.FbLoginData = action.payload;
			  return newState;
			  
		 case RECEIVE_CUSTOMERUPDATE_DATA:
		   newState = {
			...state
		  };

			  newState.CustomerUpdateData = action.payload;
			  return newState;
			  
		 case RECEIVE_FORGETPASSWORD_DATA:
		   newState = {
			...state
		  };

			  newState.ForgetUpdateData = action.payload;
			  return newState;
			  
		 case RECEIVE_REGISTRATION_DATA:
		   newState = {
			...state
		  };

			  newState.RegistrationData = action.payload;
			  return newState;
			  
		 case RECEIVE_LOGIN_DATA:
		   newState = {
			...state
		  };

			  newState.LoginData = action.payload;
			  return newState;
			  
		 case RECEIVE_CUSTOMERDETAIL_DATA:
		   newState = {
			...state
		  };

			  newState.CustomerDetailData = action.payload;
			  return newState;
			  
		 case RECEIVE_UPDATECUSTOMERPROFILE_DATA:
		   newState = {
			...state
		  };

			  newState.UpdateCustomerProfileData = action.payload;
			  return newState;
			  
		  case RECEIVE_EMAILEXIST_DATA:
		   newState = {
			...state
		  };

			  newState.EmailExistData = action.payload;
			  return newState;
			  
	      case RECEIVE_PHONEEXIST_DATA:
		   newState = {
			...state
		  };

			  newState.PhoneExistData = action.payload;
			  return newState;
			  
	      case RECEIVE_ADDRESS_DATA:
		   newState = {
			...state
		  };

			  newState.AddressData = action.payload;
			  return newState;
		
		 case RECEIVE_CHANGEPASSWORD_DATA:
		   newState = {
			...state
		  };

			  newState.ChangePasswordData = action.payload;
			  return newState;
		
		case RECEIVE_SECONDARYADDREMOVE_DATA:
		   newState = {
			...state
		  };

			  newState.SecondaryAddRemoveData = action.payload;
			  return newState;	  
			  
	   case RECEIVE_ALLUSERSECADDR_DATA:
		   newState = {
			...state
		  };

			  newState.AllUserSecAddrData = action.payload;
			  return newState;	
			  
	   case RECEIVE_PORDERHISTORY_DATA:
		   newState = {
			...state
		  };

			  newState.PorderHistoryData = action.payload;
			  return newState;	
			  
	  case RECEIVE_CORDERHISTORY_DATA:
		   newState = {
			...state
		  };

			  newState.CorderHistoryData = action.payload;
			  return newState;	
			  
	  case RECEIVE_ORDERHISTORY_DATA:
		   newState = {
			...state
		  };

			  newState.OrderHistoryData = action.payload;
			  return newState;
	  
	  case RECEIVE_APPLYPROMOTION_DATA:
		   newState = {
			...state
		  };

			  newState.ApplyPromotionData = action.payload;
			  return newState;
			  
	  case RECEIVE_RESETPASSWORD_DATA:
		   newState = {
			...state
		  };

			  newState.ResetPasswordData = action.payload;
			  return newState;	  
			  
	  case RECEIVE_PROMOTIONLIST_DATA:
		   newState = {
			...state
		  };

			  newState.PromotionListData = action.payload;
			  return newState;	
	  
	  case RECEIVE_CONTACTUS_DATA:
		   newState = {
			...state
		  };

			  newState.ContactUsData = action.payload;
			  return newState;	  
			  
		case RECEIVE_CONTACTCONTENT_DATA:
		newState = {
		...state
		};

		newState.ContactcontentData = action.payload;

		return newState;
		
		case RECEIVE_SETTING_DATA:
		   newState = {
			...state
		  };

			  newState.SettingData = action.payload;
			  return newState;
			  
	   case RECEIVE_PRINTORDERRECEIPT_DATA:
		   newState = {
			...state
		  };

			  newState.PrintOrderReceiptData = action.payload;
			  return newState;
	  
	  case RECEIVE_RECEIPTPROMOTION_DATA:
		   newState = {
			...state
		  };

			  newState.ReceiptPromotionData = action.payload;
			  return newState;
			  
     case RECEIVE_DOWNLOADQUOTATION_DATA:
		   newState = {
			...state
		  };

			  newState.DownloadQuotationData = action.payload;
			  return newState;

          
       case RECEIVE_LOGINDOWNLOADQUOTATION_DATA:
		   newState = {
			...state
		  };

			  newState.LoginDownloadQuotationData = action.payload;
			  return newState;

        default:
          return state;
          
      }
    },
    throw(state, action) {
		
		var newState;
		
      switch (action.type) {

          case RECEIVE_BANNER_DATA:
           newState = {
            ...state
          };

          newState.bannerDataError = action.payload;

          return newState;
          
          case RECEIVE_HEADERORDERINFO_DATA:
           newState = {
            ...state
          };

          newState.placeorderinfoDataDataError = action.payload;

          return newState;

        default:
          return state;
      }
    }
  }, defaultState
)
