import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getPromotionListData(params){
  return await axios.get(apiUrl + 'promotion_api_v2/promotionlistWhitoutuniqcode/?app_id='+appId+params)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

export async function getReceiptPromotionData(params){
  return axios.get(apiUrl + 'promotion_api_v2/promotion_details/?app_id=' + appId +params)

  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}

