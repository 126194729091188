/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";

import { stripslashes, hideLoader, showAlert} from "../../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl } from "../../Helpers/Config";


var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import axios from 'axios';


import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  requestContactUsData,
  requestContactcontentData,
} from '../../modules/ui/actions';

var Parser = require('html-react-parser');


class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
			
			fields: {
				name: '',
				mobile: '',
				subject: '',
				email: '',
				type: '',
				message: '',
			}, pageTitle: '', pageDesc: '', status: '', windowHeight: 0
			};

    }

    fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleFormSubmit = () => {
		const formPayload = this.state.fields;

		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"customer_first_name": formPayload.name,
			"customer_emailaddress": formPayload.email,
			"customer_phonenumber": formPayload.mobile,
			"subject": formPayload.type,
			"customer_message": formPayload.message
		};


	
		/*axios.post(apiUrl + "contactus/contact_us", qs.stringify(postObject)).then(response => {
			if (response.data.status === "ok") {

				showAlert('Success', response.data.message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

				setTimeout(function () {

					window.location.reload();

				}, 3000);


			} else {
				showAlert('Success', response.data.message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

			}

		})
			.catch(function (error) {
				console.log(error);
			}); requestContactUsDataSaga */

        this.props.requestContactUsData(qs.stringify(postObject));

	}

    componentDidMount() {
		
		this.props.requestContactcontentData();

		const pageSlug = 'contact-us';

		axios.get(apiUrl + 'cms/page?status=A&app_id=' + appId + '&page_slug=' + pageSlug)
			.then(res => {
				if (res.data.status === "ok") {
					this.setState({ pageTitle: res.data.result_set[0].cmspage_title, status: 'ok' });
					this.setState({ pageDesc: res.data.result_set[0].cmspage_description });
					$('#dvLoading').fadeOut(3000);
				}
				else if (res.data.status === "error") {
					this.setState({ status: 'error' });
				}
			});
		$('html, body').animate({ scrollTop: 0 }, 800);
	}
    
    componentWillReceiveProps(nextProps) {
 
		
		if(nextProps.contactusData !== this.props.contactusData)
		{	
			if(nextProps.contactusData.status === 'ok')
			{
				showAlert('Success', nextProps.contactusData.message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

				setTimeout(function () {

					window.location.reload();

				}, 3000);
			}
			else {
				showAlert('Success', nextProps.contactusData.message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

			}
		}

    $('html, body').animate({ scrollTop: 0 }, 800); 

	}


	render() {

		

			return (<div> 
				 {/* Header start */}
				<Header categoryState={this.state} setActiveTab={this.setActiveTab} />
				{/* Header End */}
				<div className="container catering">
				<div className="breadcrumb">
					<div className="container">
						<div className="breadcrumb_inner">
							<div className="breadcrumb_left">
								<ul>
									<li><p><a className='' href="/">Home</a>
										<i className="fa fa-chevron-right"></i></p></li>
									<li><p><a href="javascript:void(0)">{stripslashes(this.state.pageTitle)}</a></p></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				</div>
				<div className="cms-page contactus_pg">
					<div className="container">
						<h3>{stripslashes(this.state.pageTitle)}</h3>
						<div className="contactus_wrapper">
							<div className="row">
								<div className="col-md-4 contact_left">
									{Parser(stripslashes(this.state.pageDesc))}
								</div>
								<Form fields={this.state.fields} contactContent={this.props.contactcontentData} onChange={this.fieldChange} onValid={this.handleFormSubmit} onInvalid={() => console.log('Form invalid!')} />
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<div id="dvLoading1234"></div>

			</div>
			);	

	}
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
	mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

const isEmpty = (value) =>
	value === '' ? 'This field is required.' : null

const isEmail = (email) =>
	validator.isEmail(email) ? null : 'This is not a valid email.'

function validationConfig(props) {
	const { name, mobile, email, message } = props.fields

	return {
		fields: ['name', 'mobile', 'email', 'message'],

		validations: {
			name: [[isEmpty, name]],
			mobile: [[isEmpty, mobile], [isMobile, mobile]],
			email: [[isEmpty, email], [isEmail, email]],
			message: [[isEmpty, message]]
		}
	}
}



class Form extends React.Component {

	state = {
		pageTitle: '',
		pageDesc: ''
	}



	render() {
		
		
		const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
		let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;
		
		let contactcontent ='';
		
		if(this.props.contactContent != undefined)
		{
			contactcontent = Parser(this.props.contactContent.result_set[0].staticblocks_description);
		}

		if ($validation.name.error.reason !== undefined) {
			document.getElementsByClassName('name').className = 'error';
			errMsgFirstName = ($validation.name.show && <span className="error">{$validation.name.error.reason}</span>)
		}

		if ($validation.mobile.error.reason !== undefined) {
			errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
		}

		if ($validation.email.error.reason !== undefined) {

			errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
		}

		if ($validation.message.error.reason !== undefined) {
			errMsgMessage = ($validation.message.show && <span className="error">{$validation.message.error.reason}</span>)
		}
	

		return (

			<div className="col-md-8 contact_right">
				<div className="contact_form">
					<h3>DROP US A LINE</h3>
					<p className="help-block">
					{contactcontent}
					</p>
						<form className="form_sec">
						<div className="form-group">
							<div className="row">
								<div className="col-md-6">
									<div className="focus-out">
										<label>Name*</label>
										<input type="input" className="form-control input-focus" value={fields.name} maxLength="20"   {...$field('name', (e) => onChange('name', e.target.value)) } />
										{errMsgFirstName}

									</div>
								</div>
								<div className="col-md-6">
									<div className="focus-out">
										<label>Contact Number*</label>
										<input type="text" className="form-control input-focus" value={fields.mobile} maxLength="11"   {...$field('mobile', (e) => onChange('mobile', e.target.value)) } />
										{errMsgMobile}
									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<div className="focus-out">
								<label>Email*</label>
								<input type="input" className="form-control input-focus" value={fields.email} maxLength="25"   {...$field('email', (e) => onChange('email', e.target.value)) } />
								{errMsgEmail}
							</div>
						</div>
						{/*<div className="form-group">
							<div className="re_select">
								<select className="form-control select-gender" {...$field('type', (e) => onChange('type', e.target.value)) }>
									<option value=""> Please Select* </option>
									<option value="General Enquiry" id="general-enquiry"> General Enquiry </option>
									<option value="Order Enquiry" id="order-enquiry"> Order Enquiry </option>
									<option value="Others" id="others"> Others </option>
								</select>

								{errMsgType}

							</div>
						</div>*/}
						<div className="form-group">
							<div className="focus-out">
								<label>Message*</label>

								<textarea className="form-control input-focus" id="feedback"   {...$field('message', (e) => onChange('message', e.target.value)) }></textarea>
								{errMsgMessage}

							</div>
						</div>
						<div className="btn_sec">
							<button type="button" className="btn btn_black btn-lg btn-block" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit<div className="ripple-container"></div></button><br />

						</div>
					</form>
				</div>
			</div>


		)
	}
}
Form = validated(validationConfig)(Form)

function mapStateToProps(appState, ownProps) {

  return {
    contactusData: appState.ui.ContactUsData,
    contactcontentData: appState.ui.ContactcontentData,
   
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestContactUsData,
      requestContactcontentData,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);


