import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getPorderHistoryData(customer_id){
  return await axios.get(apiUrl + 'reports/order_history?order_status=P&app_id='+appId+'&customer_id='+customer_id)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
