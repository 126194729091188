/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';

import moment from 'moment';

import axios from 'axios';
var qs = require('qs');
var Parser = require('html-react-parser');

import { appId, apiUrl, apiUrlV2, cateringId, CountryTxt, productNoImg, baseUrl, timThumpUrl } from "../../Helpers/Config";
import {getReferenceID, stripslashes, showPriceValue, callImage, timeToConv12, showLoader, hideLoader, showCartItemCount, showCustomAlert, showAlert} from "../../Helpers/SettingHelper";


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestStaicblkData,
  requestDownloadQuotationData,
  requestCartlistData,
} from '../../modules/ui/actions';



import CantDlyImg from "../../common/images/cant_Deliver.png";
import ClockImg from "../../common/images/time.png";

class Packagedetails extends Component {

    constructor(props) {
        super(props);

        this.state = { productsdetail: [], productfeilsdetail: [], product_addons: [], product_setup: [], product_modifiers: [], product_modifiers_price: [],product_modifiers_price_new: 0,breaktime_count: 0, breaktime_enable: "", breaktime_list: [], pro_subcate_name: "", product_list: [], selected_pakageIds: [], proListDropDown: '', product_calculated_amount: [], selected_category_name: '',product_disclaimer:'', cartTotalItmCount: '',settings: [], DownloadLoading:false,
		user_name:'', user_phone: '', user_email: '',LoginDownloadLoading:false,checkcartempty:false,
		display_current_category_id:''
        };
		
		if(typeof cookie.load('breaktimeEnable') !== 'undefined') {
			this.state['breaktime_enable'] = cookie.load('breaktimeEnable');
		}
		if(typeof cookie.load('breaktimeList') !== 'undefined') {
			this.state['breaktime_list'] = cookie.load('breaktimeList');
		}
		if(typeof cookie.load('catering_categoryName') !== 'undefined') {
			this.state['selected_category_name'] = cookie.load('catering_categoryName');
		}
		
		if(typeof cookie.load('break_timeHtml') !== 'undefined') {
			this.state['break_timeHtml'] = cookie.load('break_timeHtml');
		}
    }

	componentDidMount()
	{
		 this.props.requestStaicblkData({slug:'product-disclaimer'});

	}

	componentWillReceiveProps(nextProps) {
		
		 /*Get date list*/
		 if(typeof nextProps.staicblkData != 'undefined' && nextProps.staicblkData.status == 'ok') {

			this.setState({'product_disclaimer': Parser(stripslashes(nextProps.staicblkData.result_set[0].staticblocks_description))});
				
		}

		if(this.state.category_id !== nextProps.categoryState.category_id) {
			this.setState({'category_id': nextProps.categoryState.category_id, 'selected_category_name': nextProps.categoryState.category_name}, function () { this.resetAllpackData(); }.bind(this));
		}
		
		
		
		
		if(Object.keys(nextProps).length > 0) {
			var categoryStateArr = nextProps.categoryState;
			for (var key in categoryStateArr) {
				this.state[key] = categoryStateArr[key];
			}
		}
		
		this.getProductList();
		
		 if(nextProps.downloadQuotationData !==this.props.downloadQuotationData){
			 			 
			     //$('#dvLoading').fadeOut(1000);
			     
			     $(".signup_submit gloading_img").remove();


				 if (nextProps.downloadQuotationData.status === "ok" && this.state.DownloadLoading == false) {
				
				 showAlert('success','Your Quotation request has been sent to your registered email please check!.');
                        $.magnificPopup.open({
                            items: {
                                src: '.alert_popup'
                            },
                            type: 'inline'
                        });
                      this.setState({DownloadLoading:true});
						
					} else {
				showAlert('error','Sorry! Your Quotation request was failed.');
					}
			 }
	}

	getProductList() {

		$('.dvLoaderCls').show();

		/*var categoryId = (typeof cookie.load('catering_categoryId') !== 'undefined') ? cookie.load('catering_categoryId') : '';*/

		/*var categoryId = (this.state.category_id)?this.state.category_id:((typeof cookie.load('catering_categoryId') !== 'undefined') ? cookie.load('catering_categoryId') : '');*/

		var categoryId = this.state.category_id;

		var eventType = (typeof cookie.load('catering_eventType') !== 'undefined') ? cookie.load('catering_eventType') : '';
		var outletId = (typeof cookie.load('cateringOutletId') !== 'undefined') ? cookie.load('cateringOutletId') : '';
		var HallId = (typeof cookie.load('cateringHallId') !== 'undefined') ? cookie.load('cateringHallId') : '';

		/* && (outletId !== '' || HallId !== '')*/
		if((categoryId !== '' && eventType !== '')) {
			axios.get(apiUrlV2 + "catering/cateringprodcutlist?app_id=" + appId + "&category_id=" + categoryId + "&catering_type=" + eventType + "&catering_outlet_id=" + outletId + "&catering_hall_id=" + HallId + "&").then(res => {
				$('.dvLoaderCls').fadeOut(500);
				if (res.data.status === "success") {
					var productslist = res.data.result_set;
					var image_source = res.data.image_source;
					
					var display_current_category_id = (productslist[0].pro_subcate_category_id !== undefined)?productslist[0].pro_subcate_category_id:'';

				} else {
					var productslist = [];
					var image_source = '';
					var display_current_category_id = '';
				}
				this.setState({product_list: productslist,display_current_category_id:display_current_category_id}, function () { this.productListBox(); }.bind(this));
				this.setState({product_image_source: image_source});
			});
		} else {
			    $('.dvLoaderCls').fadeOut(500);
				var productslist = [];var image_source = '';
				this.setState({product_list: productslist}, function () { this.productListBox(); }.bind(this));
				this.setState({product_image_source: image_source});
		}
		
	}
	
	
	getProductDetailById(indFlg,productId) {
		var  kyIndx = 'Index_'+indFlg;
		var sateProductsdetail = this.state.productsdetail;
		var sateProductModifr = this.state.product_modifiers;
		var sateProductModifrPrice = this.state.product_modifiers_price;
		var sateProductAddons = this.state.product_addons;
		var sateProductSetup = this.state.product_setup;
		var sateProductFeilsDet = this.state.productfeilsdetail;
		$('.dvLoaderCls').show();
		
		axios.get(apiUrlV2 + "catering/cateringproducts_detail?app_id=" + appId + "&product_id=" + productId + "&availability=" + cateringId + "&status=A&").then(res => {
			    var proDet = Array();
				if (res.data.status === "success") {
					proDet['resultset'] = res.data.result_set;
					proDet['commonurl'] = res.data.common;
				}
				sateProductsdetail[kyIndx] = proDet;
				sateProductModifr[kyIndx] = Array();
				sateProductModifrPrice[kyIndx] = Array();
				sateProductAddons[kyIndx] = Array();
				sateProductSetup[kyIndx] = Array();
				sateProductFeilsDet[kyIndx] = Array();
				this.setState({productsdetail: sateProductsdetail,productfeilsdetail: sateProductFeilsDet,product_modifiers: sateProductModifr,product_modifiers_price: sateProductModifrPrice,product_addons: sateProductAddons,product_setup: sateProductSetup}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
				$('.dvLoaderCls').fadeOut(500);
			});	
	}

	addtoCartPackage(indFlg, selectedPgkId) {

		if(typeof cookie.load('cateringTime') === 'undefined' || cookie.load('cateringTime') === '') {

				$.magnificPopup.open({
					items: {
						src: '#datetime_valid_sec'
					},
					type: 'inline'
				});
		}
		

		var indKey = 'Index_'+indFlg;
		var errorFlg = 'No';
		
		
		/*Validation with modifiers*/
		if($("#proDetMain-"+indFlg).find('.modifiers_main_list').length) {

		/* if(indFlg !== 'noIndex') {
			var brktimeStr = $('#tab-pks-'+indFlg).attr('data-brktimestr');
			var brktimeEnd = $('#tab-pks-'+indFlg).find('.breaktime_endcls').val();
			
			var regex = new RegExp(':', 'g'), brkStr = brktimeStr+':00', brkEnd = brktimeEnd+':00';
			if(parseInt(brkStr.replace(regex, ''), 10) >= parseInt(brkEnd.replace(regex, ''), 10)){
			  errorFlg = 'Yes';
			  $('#tab-pks-'+indFlg).find('.breaktime_endcls').css('border-color','red');
			  window.scrollTo(0, 300);
			  return false;
			}
		}*/

		$("#proDetMain-"+indFlg).find('.modifiers_main_list').each(function(){
			var checked_count = 0;
			var modDivId = $(this).attr('id');
			var modMin   = $(this).attr('data-mdfmin');
			var modMax   = $(this).attr('data-mdfmax');
			$(this).find('.modifiers_valuecheck').each(function(){
				if($(this).is(':checked')){
				  checked_count +=1;
				}
			});

			if(parseInt(modMin) > parseInt(checked_count)) {
				errorFlg = 'Yes';
				var errorTxt = "Please select minimum "+modMin+" item(s)."
				$(this).find('.pkgerror_txt').html(errorTxt);
				$(this).addClass('pkgerror_out');
				$(this).find('.pkgerror_txt').show();
				$('html, body').animate({
					scrollTop: $("#"+modDivId).offset().top
				}, 1000);
			    return false;
			}

			if(parseInt(modMax) < parseInt(checked_count)) {
				errorFlg = 'Yes';
				var errorTxt = "Sorry!. maximum of selected item is "+modMax+" only."
				$(this).find('.pkgerror_txt').html(errorTxt);
				$(this).addClass('pkgerror_out');
				$(this).find('.pkgerror_txt').show();
				$('html, body').animate({
					scrollTop: $("#"+modDivId).offset().top
				}, 1000);
			    return false;
			}
		});

		var proMin = $("#proDetMain-"+indFlg).attr('data-packageminlimit');
		var proMax = $("#proDetMain-"+indFlg).attr('data-packagemaxlimit');
		var total_count = 0;

		$("#proDetMain-"+indFlg).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  total_count +=1;
			}
		});

		if(parseInt(proMax) < parseInt(total_count)) {
			errorFlg = 'Yes';
			var errorTxt = "Sorry!. maximum of selected item is "+proMax+" only."
			$("#proDetMain-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMain-"+indFlg).find('.mdf_common_error').show();
			window.scrollTo(0, 500);
			return false;
		}

		if(parseInt(proMin) > parseInt(total_count)) {
			errorFlg = 'Yes';
			var errorTxt = "Please select minimum "+proMax+" item(s)."
			$("#proDetMain-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMain-"+indFlg).find('.mdf_common_error').show();
			window.scrollTo(0, 500);
			return false;
		}

	}	
		
		var proFieldArr = Array(); var product_id = '';
		var stateProFldDet = this.state.productfeilsdetail;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   product_id = (!('product_id' in proFieldArr)) ? '' : proFieldArr['product_id'];
		   if(product_id === '') {
			   errorFlg = 'Yes';
			   $('#tab-pks-'+indFlg).find('.alert_danger').show();
			   $('#tab-pks-'+indFlg).find('.alert_danger').delay(1500).fadeOut();
			   return false;
		   }
	    }

	    
		var postModifiers = [];
		var productModifier= this.state.product_modifiers;
		if(Object.keys(productModifier).length > 0) {
			var selecteMdfMain = (!(indKey in productModifier)) ? Array() : productModifier[indKey];
			if(Object.keys(selecteMdfMain).length > 0) {
				for(var key in selecteMdfMain) {
					
					if(Object.keys(selecteMdfMain[key]).length > 0) {
					
						var Modifiers = selecteMdfMain[key].modifiers;

						var mdfArr = (Modifiers !== null && Modifiers !== '' && typeof Modifiers !== "undefined") ?Modifiers.split("~~") : [];
						
						var ModifiersValArr = [];
						var ModifiersValues = selecteMdfMain[key].modifiervalues;
						for(var key1 in ModifiersValues) {
							var ExpVarbl = ModifiersValues[key1];
							var mytemparr = ExpVarbl.split("~~");
							ModifiersValArr.push( { modifier_value_name:mytemparr[1], modifier_value_id:mytemparr[0], modifier_value_price:mytemparr[3], modifier_value_qty:mytemparr[2] } )
						}
						
						postModifiers.push( { modifier_name:mdfArr[1], modifier_id:mdfArr[0], modifiers_values:ModifiersValArr } )
					
					}
				}
			}
		}
		
		var postAddons = [];
		var productAddons = this.state.product_addons;
		if(Object.keys(productAddons).length > 0) {
			var selecteAdfMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAdfMain).length > 0) {
				for(var key in selecteAdfMain) {
					var Addons = selecteAdfMain[key];
					var AddonsValArr = [];
					var addon_setup_id = '';
					var addon_setup_title = '';
					var addon_setup_selection = '';
					for(var key1 in Addons) {
						var ExpVarbl = Addons[key1];
						var mytemparr = ExpVarbl.split("~~");
						
						addon_setup_id = mytemparr[0];
						addon_setup_title = mytemparr[1];
						addon_setup_selection = mytemparr[2];
						
						if(parseInt(mytemparr[7]) > 0) {
							AddonsValArr.push( { addon_setup_val_id:mytemparr[3], addon_setup_val_title:mytemparr[4], addon_setup_val_desc:mytemparr[5], addon_setup_val_price:mytemparr[6], addon_setup_val_qty:mytemparr[7] } )
						}
					}
					
					if(Object.keys(AddonsValArr).length > 0) {
						postAddons.push( { addon_setup_id:addon_setup_id, addon_setup_title:addon_setup_title, addon_setup_selection:addon_setup_selection, addons_setup_values:AddonsValArr } )
					}
				}
			}
		}
		
		
		var postSetup = [];
		var productSetup = this.state.product_setup;
		if(Object.keys(productSetup).length > 0) {
			var selecteStMain = (!(indKey in productSetup)) ? Array() : productSetup[indKey];
			if(Object.keys(selecteStMain).length > 0) {
				
				var deliveryList = selecteStMain.delivery;
				if(Object.keys(deliveryList).length > 0) {
					for(var key in deliveryList) {
						
						var ExpVarbl = deliveryList[key];
						var mytemparr = ExpVarbl.split("~~");

						postSetup.push( { setupid:mytemparr[0], setupname:mytemparr[1], setupdescription:mytemparr[2], setupprice:mytemparr[3], setupqty:mytemparr[4], setuptype:mytemparr[5] } )
					}
				}
				
				var buffetList = selecteStMain.buffet;
				if(Object.keys(buffetList).length > 0) {
					for(var key in buffetList) {
						
						var ExpVarbl = buffetList[key];
						var mytemparr = ExpVarbl.split("~~");

						postSetup.push( { setupid:mytemparr[0], setupname:mytemparr[1], setupdescription:mytemparr[2], setupprice:mytemparr[3], setupqty:mytemparr[4], setuptype:mytemparr[5] } )
					}
				}

				var selfcollectionList = selecteStMain.selfcollection;
				if(Object.keys(selfcollectionList).length > 0) {
					for(var key in selfcollectionList) {
						
						var ExpVarbl = selfcollectionList[key];
						var mytemparr = ExpVarbl.split("~~");

						postSetup.push( { setupid:mytemparr[0], setupname:mytemparr[1], setupdescription:mytemparr[2], setupprice:mytemparr[3], setupqty:mytemparr[4], setuptype:mytemparr[5] } )
					}
				}
				
			}
		}

	 
		if((errorFlg === 'No') && (Object.keys(proFieldArr).length > 0)) {

			showLoader('add-tocart-cls','class');
			
			var brktimeSts = 'No';
			var brktimeCnt = '0';
			var brktimeFlg = indFlg;
			var brktimeStr = '';
			var brktimeEnd = '';
			var brktimeLst = '';
			
			if(indFlg !== 'noIndex') {
				brktimeSts = 'Yes';
				brktimeCnt = this.state.breaktime_count;
				brktimeStr = $('#tab-pks-'+indFlg).attr('data-brktimestr');
				brktimeEnd = $('#tab-pks-'+indFlg).find('.breaktime_endcls').val();
				brktimeLst = JSON.stringify(this.state.breaktime_list);
			}
			var outletId = this.state.catering_outlet_id;
			
			var postHall = [];
			if(cookie.load('catering_eventType') === "hall") {
				postHall.push( { catering_venue_type:'catering_hall', catering_hall_id:cookie.load('cateringHallId'), hall_charges:cookie.load('cateringHallCharge') } )
			}
			
			var productSpecialNotes = (!('productSpecialNotes' in proFieldArr)) ? '' : proFieldArr['productSpecialNotes'];
			
			var postObject = {};
				postObject = {
					'app_id' 				: appId,
					'availability_id' 		: cateringId,
					'product_id' 			: proFieldArr['product_id'],
					'product_min_qty' 		: proFieldArr['minpaxcount'],
					'product_qty' 			: proFieldArr['selectpaxcount'],
					'product_name' 			: proFieldArr['product_name'],
					'product_sku' 			: proFieldArr['product_sku'],
					'product_slug' 			: proFieldArr['product_slug'],
					'product_unit_price' 	: proFieldArr['productPrice'],
					'product_total_price' 	: proFieldArr['productSubTotal'],
					'product_image' 		: proFieldArr['productImage'],
					'product_special_notes' : productSpecialNotes,
					'modifiers' 			: JSON.stringify(postModifiers),
					'addons_setup' 			: JSON.stringify(postAddons),
					'cateringhall' 			: JSON.stringify(postHall),
					'setup' 				: JSON.stringify(postSetup),
					'outlet_id' 			: outletId,
					'breaktime_enable' 		: brktimeSts,
					'breaktime_count' 		: brktimeCnt,
					'breaktime_list' 		: brktimeLst,
					'breaktime_indexflag' 	: brktimeFlg,
					'breaktime_started' 	: brktimeStr,
					'breaktime_ended' 		: brktimeEnd,
				}

			if (typeof cookie.load('UserId') === 'undefined') {
				postObject['reference_id'] = getReferenceID();
			} else {
				postObject['customer_id'] = cookie.load('UserId');
			}

			postObject['modifiers_validation'] = 'Yes';

			/*without modifiers*/
			if(!$("#proDetMain-"+indFlg).find('.modifiers_main_list').length) {
				postObject['modifiers_validation'] = 'No';
			}

			axios.post(apiUrlV2 + "cateringcart/insert", qs.stringify(postObject))
            .then(res => {
				if (res.data.status === "ok") {
					
					var cartDetails = (!('cart_details' in res.data.contents)) ? Array() : res.data.contents.cart_details;
					showCartItemCount(cartDetails);
					
					$.magnificPopup.open({
                        items: {
                            src: '#checkout-cont-popup'
                        },
                        type: 'inline'
                    });
					this.setState({'checkcartempty' : true})
					this.resetPackage(indFlg);
					this.props.sateValChangefun('add_new_item', 'Yes');
					
					/*showCustomAlert('success','Great choice! Item added to your cart.');
					$('#tab-pks-'+indFlg).find('.alert_success').show();
					$('#tab-pks-'+indFlg).find('.alert_success').delay(1800).fadeOut();
					*/
					/*this.props.setActiveTab(4);*/
				
					
				} else {
					$('#tab-pks-'+indFlg).find('.alert_danger').show();
					$('#tab-pks-'+indFlg).find('.alert_danger').delay(1500).fadeOut();
				}
				hideLoader('add-tocart-cls','class');
			})
            .catch(function (error) {
 
				$('#tab-pks-'+indFlg).find('.alert_danger').show();
		        $('#tab-pks-'+indFlg).find('.alert_danger').delay(1500).fadeOut();
            });
			
		} else {
			$('#tab-pks-'+indFlg).find('.alert_danger').show();
		    $('#tab-pks-'+indFlg).find('.alert_danger').delay(1500).fadeOut();
		    return false;
		}
	}


	
	getTopBarDetails()
	{
		var eventType = cookie.load('catering_eventType');
		var showLocationName = '';
		var showLocationAddr = '';
		if(eventType === "hall") {
			showLocationName = cookie.load('cateringHallName');
			showLocationAddr = cookie.load('cateringHalladdress');
		} else {
			showLocationName = cookie.load('cateringOutletName');
			showLocationAddr = cookie.load('cateringOutletAddress');
		}
		
		const breakTmCount = cookie.load('breaktimeCount');
		return (<div className="number-break clearfix">
					
					{/*}<a className="back_bx" href="javascript:void(0)" onClick={this.goBackFun.bind(this,1,2)}>Back</a>{*/}
					
					<a className="back_bx" href="javascript:void(0)" onClick={this.goBackcatFun.bind(this,1,2)}>Back</a>
					
					
						{((this.state.breaktime_enable === "Yes") && (Object.keys(this.state.breaktime_list).length > 0))?<div className="break-time"><h3 className="title4">Number of Break Time</h3><div className="qty_bx catqty_bx"> 
							<span className="qty_minus" onClick={this.goBackFun.bind(this,2,2)}>-</span>
								<input type="text" value={breakTmCount} readOnly /> 
							<span className="qty_plus" onClick={this.goBackFun.bind(this,2,2)}>+</span> 
						</div></div>:<div className="break-time"><h3 className="title4" style={{marginBottom: '8px'}}>Break Time</h3><div className=""> <p style={{marginBottom: '3px'}}> You don't have any break time.</p>
						<span className="change-location display_block"><a href="javascript:void(0)" onClick={this.goBackFun.bind(this,4,2)}>Add Break Time</a></span>

						</div></div>}
					{/*}
					<div className="making-catering" style={{width: '43%'}}>
						<p>You are making a catering at <span className="making-place display_block">{showLocationName}</span> {showLocationAddr} <span className="change-location display_block"><a href="javascript:void(0)" onClick={this.goBackFun.bind(this,4,2)}>Change Location</a></span></p>
					</div>
					{*/}
					
					<div className="event-time">
						<h3 className="title4">Event date &amp; Time</h3>
						<h3 style={{color: '#72624f',marginBottom: 0,fontSize: '20px',fontFamily: 'Berthold Akzidenz-Grotesk'}}>{ moment(cookie.load('cateringDate')).format('DD-MM-YYYY')} {timeToConv12(cookie.load('cateringTime'))}</h3>
						<span className="change-location display_block"><a href="javascript:void(0)" onClick={this.goBackFun.bind(this,4,2)}>Change Event date &amp; Time</a></span>
					</div>
					
					<div className="white-popup mfp-hide popup_sec" id="gotoback_popup_id" style={{maxWidth: 500}}><div className="custom_alert"><div className="custom_alertin"><div className="alert_height"><div className="alert_header" style={{textAlign: 'center'}}>Wait a second !</div><div className="alert_body"><img src={CantDlyImg} /><p>By switching you are about to clear your cart.</p><p>Do you wish to proceed ?</p><div className="alt_btns"><a href="javascript:void(0);" className="btn btn-sm btn_yellow popup-modal-dismiss" style={{background:'#e0dddd'}}>Cancel</a><a href="javascript:void(0);" className="btn btn-sm btn_yellow" onClick={this.yesProcd.bind(this)}>Yes Proceed</a></div></div></div></div></div></div>
					
				  </div>);
	}

	goBackFun(stpflg,stpTxt) {

		var totalItems = cookie.load('cartTotalItems');
		if(parseInt(totalItems)>0) {
			$.magnificPopup.open({
				items: {
					src: '#gotoback_popup_id'
				},
				type: 'inline'
			});
		} else {
			this.props.setActiveTab(stpTxt);
		}
	}

	goBackcatFun(stpflg,stpTxt) {
		this.props.setActiveTab(1);
	}

    yesProcd() {
		this.cartDestory();
		this.props.setActiveTab(2);
	}
	
	cartDestory() {
		
		var postCrtObject = {};
			postCrtObject = {
				'app_id' : appId,
			}
		if (typeof cookie.load('UserId') === 'undefined') {
			postCrtObject['reference_id'] = getReferenceID();
		} else {
			postCrtObject['customer_id'] = cookie.load('UserId');
		}
		
		axios.post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
		.then(res => {
			if (res.data.status === "ok") {
				showCustomAlert('success','Your cart is empty');
				$.magnificPopup.close();
				var cartDetails = Array();
				showCartItemCount(cartDetails);
				browserHistory.push("/#/");
				
			} else {
				$.magnificPopup.close();
				showCustomAlert('error','Sorry! Products can`t update your cart');
			}
		})
		.catch(function (error) {

			showCustomAlert('error','something went wrong');
			$.magnificPopup.close();
		});
		
	}
    
    
    
    getBreakTime_BarHtml() {
		var breakTimeEnable = this.state.breaktime_enable;
		var breakTimeList = this.state.breaktime_list;
		var breakTimeActive = 0;
		if((breakTimeEnable === "Yes") && (Object.keys(breakTimeList).length > 0)) {
			
			const BarHtml = breakTimeList.map((brtimeLst, index) =>
								<li className={(index===breakTimeActive)?"brk_time_li active":"brk_time_li"} id={"Brk-"+index} key={index} onClick={this.handleTabBtn.bind(this,index)}>
									<a href="javascript:void(0)" > Break { index+1 }  -  {timeToConv12(brtimeLst)}</a>
								</li>); 
								
				
             return BarHtml;				
			
		} else {
			var BarHtl = "";
			return BarHtl;
		}
		
	}
    
    handleTabBtn(indexTxt) {
		$('.brk_time_li').removeClass('active');
		$('#Brk-'+indexTxt).addClass('active');
		$('.tab-pane-sub').removeClass('active');
		$('#tab-pks-'+indexTxt).addClass('active');
		$('.tab-pane-sub').hide();
		$('#tab-pks-'+indexTxt).show();
	}
	
	
	/*Get product list*/
	getProductListHtml() {
		
		var breakTimeEnable = this.state.breaktime_enable;
		var breakTimeList = this.state.breaktime_list;
		var breakTimeActive = 0;
		if((breakTimeEnable === "Yes") && (Object.keys(breakTimeList).length > 0)) {
			
			const BdyMainHtml = breakTimeList.map((brtimeLst, index) =>
								<div id={"tab-pks-"+index} data-brktimestr={brtimeLst} className={(index===breakTimeActive)?"tab-pane-sub active":"tab-pane-sub"} key={index} style={{display:(index===breakTimeActive)?"block":"none"}}>
									<div className="tab_mobrow1 pkselect_tabin">
									
										<div className="pkselected_filtersec">
												{ this.innarBarPkgDiv(index) }     
										</div>
										{ this.getProductListAndDetails(index) }	
										
									</div>
								</div>); 
            return BdyMainHtml;
		
		} else {
			return (<div id="tab-pks-no" className="tab-pane-sub">
			
							<div className="tab_mobrow pkselect_tabin">
									
								<div className="pkselected_filtersec">
								{ this.innarBarPkgDiv('noIndex') }         
								</div>
								
								{ this.getProductListAndDetails('noIndex') }
								
							</div>
					</div>);
		}
		
	}
	
	innarBarPkgDiv(indFlg) {
		var kyIndx = 'Index-'+indFlg;
		var selectedPakageIds = this.state.selected_pakageIds;
		var selectedPgkId = (!(kyIndx in selectedPakageIds)) ? '' : selectedPakageIds[kyIndx]; 
		
		var brkVal = '';
		if(indFlg !== 'noIndex') {
			var breakTimeList = this.state.breaktime_list;
		    brkVal = (!(indFlg in breakTimeList)) ? '' : breakTimeList[indFlg]; 
		}
		
		 return (<div className="row">
		 
					{(selectedPgkId !== '') ? <div className="col-sm-6 pkselected_package">
						<label><i className="fa fa-th-large" style={{cursor: 'pointer'}}  onClick={this.goBackcatFun.bind(this,1,2)}></i> {this.state.selected_category_name}</label>
						<div className="form-group">
							<div className="custom_select">
								<select className="form-control" name="pkg-listbx" onChange={this.changePackage.bind(this,indFlg)} value={selectedPgkId} >
								{this.state.proListDropDown}
								</select>
							</div>
						</div>
					</div> : <div className="col-sm-6 pkselected_package">
									<div className="title4 text-uppercase"> {this.state.selected_category_name} </div>
									<div className="text-uppercase"> {(indFlg !== 'noIndex') ? 'Select Your Packages' : 'Select Your Packages for each break time'}</div>
					</div>}
					
					{(indFlg !== 'noIndex') ? <div className="col-sm-6 pkselected_break text-right">
						<label>Selected Break Time</label>
						<div className="form-group">
							<div className="input_area input_lefticons custom_select">
								<span className="ileft_ico"><img src={ClockImg} /></span>
								<select name="end_breaktime_inp" className="form-control datetimepicker filled breaktime_endcls" value={brkVal} onChange={this.handleBrkTime.bind(this,indFlg)} id={"end-breaktime-"+indFlg}>
									{this.state.break_timeHtml}
								</select>
							</div>
						</div>
					</div> : <div className="col-sm-6 pkselected_break text-right"></div>}
					
				</div>);

	}
	
	handleBrkTime(indFlg,event) {
		var brktimeStr = $('#tab-pks-'+indFlg).attr('data-brktimestr');
		var brktimeEnd = event.target.value;
		
		var breakTimeList = this.state.breaktime_list;
		if(breakTimeList.indexOf(brktimeEnd) === -1) {
			breakTimeList[indFlg] = brktimeEnd;
			this.setState({breaktime_list: breakTimeList});
			this.props.sateValChangefun('breaktime_list', breakTimeList);
			cookie.save('breaktimeList', breakTimeList);
		} else {
			$('#tab-pks-'+indFlg).find('.breaktime_endcls').css('border-color','red');
			setTimeout(function() {
				$('#tab-pks-'+indFlg).find('.breaktime_endcls').removeAttr("style"); 
			}, 800)
		}

	}
	
	changePackage(indFlg, event) {
		var pkgId = event.target.value;
		var selectedPakageIds = this.state.selected_pakageIds;
		var kyIndx = 'Index-'+indFlg;
		selectedPakageIds[kyIndx] = pkgId;
		this.setState({selected_pakageIds: selectedPakageIds});
		
		this.getProductDetailById(indFlg,pkgId);
	}
	
	productListBox() {
		var productList = this.state.product_list;
		var proListDropDown = '';
		if(Object.keys(productList).length > 0) {
			var proListHtml = '';var productsArr = [];var incr = 0;
			for (var key in productList) {
				var productsTemp = productList[key].products;
				for (var keyinr in productsTemp) {
					productsArr[incr] = productsTemp[keyinr];
					incr++;
				}
			}
			
			productsArr.map((loadData) => 
				proListHtml+="<option value='"+loadData.product_id+"' >"+((loadData.product_alias!='')?stripslashes(loadData.product_alias):stripslashes(loadData.product_name))+" &nbsp;("+showPriceValue(loadData.product_price)+" Per Pax)</option>"
			 );
			proListDropDown = Parser(proListHtml);
		}
		
		this.setState({proListDropDown : proListDropDown});
	}
	/*get common settings details*/
	
	getCommonSetting(pstCode) {
		var pstCode = (pstCode === '') ? this.state.catering_postal_code : '';
		var postCdVal = (pstCode != '') ? "&postal_code=" + pstCode : '';
		axios.get(apiUrl + 'settings/get_common_settings?app_id=' + appId + postCdVal)
		.then(res => {
			if (res.data.status === "ok") {
				if (typeof res.data.result_set !== "undefined") {
					this.setState({ settings: res.data.result_set });
				}
			}
		});
	}
	
	/* download quotation */
	 downloadCartItem() {
		//	this.props.requestCartlistData({UserId:cookie.load('UserId'),referenceId:cookie.load('referenceId')});
		
		if (typeof cookie.load('UserId') === 'undefined') {
			$.magnificPopup.open({
				items: {
					src: '#get_quotationdata'
				},
				type: 'inline'
			});
		} else {
			$('#dvLoading').fadeIn();

		var customerId = cookie.load('UserId');
		var customerEmail = cookie.load('UserEmail');
		//var promotionAmount = this.state.promotion_amount;;
		var comSettings = this.state.settings;
		var gstPar = 0;
		if(Object.keys(comSettings).length > 0) {
			gstPar = (comSettings.client_tax_surcharge !== '') ? parseFloat(comSettings.client_tax_surcharge) : 0;
		}
		var outletIdVl = cookie.load('cateringOutletId');
		
		var urlParam = "&customer_id="+customerId+"&request_email="+customerEmail+"&client_gst_charge="+gstPar+"&outlet_id="+outletIdVl;
		
		this.props.requestDownloadQuotationData(urlParam);
		}
	}
	getProductListAndDetails(indFlg) {
		
		var kyIndx = 'Index-'+indFlg;
		var selectedPakageIds = this.state.selected_pakageIds;
		var selectedPgkId = (!(kyIndx in selectedPakageIds)) ? '' : selectedPakageIds[kyIndx]; 
		
		if(selectedPgkId != '') {
			
			return (<div>
						{ this.getProductDetailHtml(indFlg,selectedPgkId) }
						
						<div className="chk-payment-btn text-center">
						{ /* <a href="#" className="btn btn_download" title="Download Quotation">Download Quotation</a> */ }
							<div className="chk-payment-btn-row add-tocart-cls">	
								<div className="row">
								
							
							{(this.state.checkcartempty == false)?<div className="col-xs-6">
										<a href="javascript:void(0)" className="btn btn_black btn-block" title="Back" onClick={this.resetAllpackData.bind(this)}>Back</a>
									</div>:<div className="col-xs-6">
							<a href="javascript:void(0);" onClick={this.downloadCartItem.bind(this)} className="btn btn_black btn-block">Download Quotation</a>
						   </div>}

									<div className="col-xs-6">
										<a href="javascript:void(0)" onClick={this.addtoCartPackage.bind(this, indFlg, selectedPgkId)} className="btn btn_pink btn-block" title="Continue">Continue</a>
									</div>
								</div>
								<div className="cart-success-msg alert alert_success" >Great choice! Item added to your cart.</div>
								<div className="cart-error-msg alert alert_danger" >Sorry!. Product Detaile was not valid.</div>
							</div>
						</div>
						
					</div>);
			
		} else {
			return (<div className="pkg_gridsec">
					  <div className="row">
						{ this.productListOnlyHtml(indFlg) }
					  </div>
					</div>);
		}
	}

    getProductDetailHtml(indFlg,selectedPgkId) {
		
		   var proFieldArr = Array();
		   var proDetailArr = Array();
		   var commonurlArr = Array();
		   var stateProDet = this.state.productsdetail;
		   var stateProFldDet = this.state.productfeilsdetail;
		   var indKey = 'Index_'+indFlg;
		   
		   if(Object.keys(stateProDet).length > 0) {
			   var temArr = (!(indKey in stateProDet)) ? Array() : stateProDet[indKey];
			   if(Object.keys(temArr).length > 0) {
				   proDetailArr = (Object.keys(temArr['resultset']).length > 0) ? temArr['resultset'][0]:Array();
				   commonurlArr = temArr['commonurl'];
			   }
		   }
		   
		   if(Object.keys(stateProFldDet).length > 0) {
			   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   }
		   
		
		if(Object.keys(proDetailArr).length > 0) {
			
			var selectpaxcount = (!('selectpaxcount' in proFieldArr)) ? proDetailArr.product_min_pax : proFieldArr['selectpaxcount'];
			var productSubTotal = (!('productSubTotal' in proFieldArr)) ? 0.00 : proFieldArr['productSubTotal'];
			var productAddonsAmount = (!('productAddonsAmount' in proFieldArr)) ? 0.00 : proFieldArr['productAddonsAmount'];
			var productSetupAmount = (!('productSetupAmount' in proFieldArr)) ? 0.00 : proFieldArr['productSetupAmount'];
			var productGrandTotal = (!('productGrandTotal' in proFieldArr)) ? 0.00 : proFieldArr['productGrandTotal'];
			var productSpecialNotes = (!('productSpecialNotes' in proFieldArr)) ? '' : proFieldArr['productSpecialNotes'];

			return (<div id={"proDetMain-"+indFlg} data-packageminlimit={proDetailArr.product_min_select} data-packagemaxlimit={proDetailArr.product_max_select}>
			
					<div className="pkg_listsec bx_out">
						<div className="bx_in">
							<div className="pkglist_item pkglist_itemtable ">
								<div className="pkglist_img">
									{callImage(commonurlArr.image_source, proDetailArr.product_thumbnail, 422, 172, timThumpUrl)}
								</div>											
								<div className="pkglist_info">
									<div className="pkglist_infott">
										<h3>{(proDetailArr.product_alias!='')?stripslashes(proDetailArr.product_alias):stripslashes(proDetailArr.product_name)}</h3>
									</div>
									<p>{(proDetailArr.product_long_description != "")?stripslashes(proDetailArr.product_long_description):stripslashes(proDetailArr.product_short_description)}</p>
								</div>
								<div className="pkglist_pricebx text-center">
									<span className="display_block">Price Per Pax</span>
									<b className="display_block">{showPriceValue(proDetailArr.product_price)}</b>
									<div className="qty_bx catqty_bx"> 
										<span className="qty_minus" onClick={this.updateProductPax.bind(this,indFlg,'minus',selectpaxcount,proDetailArr.product_min_pax,proDetailArr.product_max_pax)}>-</span>
										<input type="text" name="min_pax_count" value={selectpaxcount} readOnly /> 
										<span className="qty_plus" onClick={this.updateProductPax.bind(this,indFlg,'plus',selectpaxcount,proDetailArr.product_min_pax,proDetailArr.product_max_pax)}>+</span> 
									</div>
									{Number(proDetailArr.product_max_pax) > 0 && <div className="pkglist_info_new"><p>Min pax:{proDetailArr.product_min_pax} Max pax:{proDetailArr.product_max_pax}</p></div>}
								</div>											
							</div>
						</div>
					</div>

					{(Object.keys(proDetailArr.modifiers).length > 0)?<div className="bx_out package_checkbx">
							<div className="bx_in">
							    <div className="mdf_common_error" style={{color:'red',textAlign:'center',fontWeight:'bold',display:'none'}}></div>
								<div className="row package_checkrow">{this.productModifiers(indFlg,proDetailArr.modifiers)}</div>
							</div>
						</div> : ""}
						
					{(Object.keys(proDetailArr.addons_setup).length > 0)?<div className="bx_out package_addonbx">
							<div className="bx_in">
								<h3 className="package_formtt"><span>Addons</span><small>(Choose  your addons )</small></h3>
								<ul className="package_formchecklist pkgaddon_checklist clearfix">{this.productAddonsSetup(indFlg,proDetailArr.addons_setup)}</ul>
							</div>
						</div> : ""}
					
					{((Object.keys(proDetailArr.delivery).length > 0) || (Object.keys(proDetailArr.buffet).length > 0) || (Object.keys(proDetailArr.self_collection).length > 0))?<div className="bx_out package_setupbx">
							<div className="bx_in">
								<h3 className="package_formtt"><span>Setup</span><small>(Choose  your buffect setup)</small></h3>
					<div className="pkgsetup_list" id={"setup_"+indFlg} >{this.productDeliverySetupHtml(indFlg,proDetailArr.delivery)} {this.productBuffetSetupHtml(indFlg,proDetailArr.buffet)} {this.productSelfcollectionSetupHtml(indFlg,proDetailArr.self_collection)}</div>
							</div>
						</div> : ""}
					 
					 <div className="bx_out package_descbx">
						<div className="bx_in">
							<h3 className="package_formtt package_formtt1"><span>Add Special Instruction</span></h3>
							<div className="form-group">
								<textarea className="form-control" placeholder="Type your Message here" value={productSpecialNotes} onChange={this.handleSpecialInstruction.bind(this,indFlg)} ></textarea>
							</div>
							
							{this.state.product_disclaimer}
							
							
							
						</div>
					 </div>
					 
					 <div className="bx_out package_descbx" style={{padding:'0px'}}>
						<div className="bx_in ctr-price-item">
						    <div className="row pkg_total_box">
									<div className="col-sm-7"></div>
									<div className="col-sm-5">
										<div className="pkg_total_row">
											<div className="col-xs-5"><b>Subtotal</b></div>
											<div className="col-xs-7">: <span>{showPriceValue(productSubTotal)}</span></div>
										</div>
										{(parseFloat(productAddonsAmount)>0)?<div className="pkg_total_row">
											<div className="col-xs-5"><b>Add-ons</b></div>
											<div className="col-xs-7">: <span>{showPriceValue(productAddonsAmount)}</span></div>
										</div>:''}
										{(parseFloat(productSetupAmount)>0)?<div className="pkg_total_row">
											<div className="col-xs-5"><b>Setup</b></div>
											<div className="col-xs-7">: <span>{showPriceValue(productSetupAmount)}</span></div>
										</div>:''}
										<div className="pkg_total_row grand_total">
											<div className="col-xs-5"><b>Total</b></div>
											<div className="col-xs-7">: <span>{showPriceValue(productGrandTotal)}</span></div>
										</div>
								  </div>
							 </div>	  
						</div>
					 </div>
					 
				</div>);
		} else {
			return (<div><div className="pkg_listsec bx_out"> Product Details is empty.</div></div>);
		}
	}

    productListOnlyHtml(indFlg) {
		var productList = this.state.product_list;
		var imageSource = this.state.product_image_source;
		if(Object.keys(productList).length > 0) {
			var productsArr = [];
			var incr = 0;
			for (var key in productList) {
				/*var proSubcateName = productList[key].pro_subcate_name;*/
				var productsTemp = productList[key].products;
				for (var keyinr in productsTemp) {
					productsArr[incr] = productsTemp[keyinr];
					incr++;
				}
			}
			const proListHtml = productsArr.map((prodts, index) =>
												<div className="col-md-3 col-sm-4 pkg_gcol" key={index}>
													<div className="pkg_gitem"> {/*} highlighted {*/}
														<div className="pkg_gimg">
														   {callImage(imageSource, prodts.product_thumbnail, 436, 330, timThumpUrl)}
														   <div className="pkg_ginfo">

																{/*}<span className="buy_two_get1">Buy 2 Get 1 Free</span>{*/}
																<div className="pkg_gtext">
																	<h4>{(prodts.product_alias!='')?stripslashes(prodts.product_alias):stripslashes(prodts.product_name)}</h4>
																	<h5>MIN Pax : {prodts.product_min_pax}
																{Number(prodts.product_max_pax) > 0 && <><br/>MAX Pax : {prodts.product_max_pax}</>
																	}
																	</h5>

																	<p>{stripslashes(prodts.product_short_description)}</p>
																</div>
																<div className="price">
																	<p><span>{showPriceValue(prodts.product_price)}</span><sup> (per pax)</sup></p>
																</div>
																
																{ this.getProducttag(prodts.pro_tags) }

															</div>
														</div>
														<div className="pkg_gbtsec text-center">
															<a href="javascript:void(0)" className="btn btn_pink " onClick={this.choosePackage.bind(this, indFlg, prodts.product_id)} title={(this.state.display_current_category_id === '637522BD-119F-4745-9C7B-6F045BE07F60' || this.state.display_current_category_id === 'B8FAB2E1-71A4-4979-A442-B08A4410F530' || this.state.display_current_category_id === 'ECAA3462-E055-4390-A74A-CA279DF4233C')?'Choose Bowl':'Choose Package'}>{(this.state.display_current_category_id === '637522BD-119F-4745-9C7B-6F045BE07F60' || this.state.display_current_category_id === 'B8FAB2E1-71A4-4979-A442-B08A4410F530' || this.state.display_current_category_id === 'ECAA3462-E055-4390-A74A-CA279DF4233C')?'Choose Bowl':'Choose Package'}</a>
														</div>
													</div>
											     </div>);
			return proListHtml;	
			
		}
	}
	
	choosePackage(indFlg, pkgId) {
		var selectedPakageIds = this.state.selected_pakageIds;
		var kyIndx = 'Index-'+indFlg;
		selectedPakageIds[kyIndx] = pkgId;
		this.setState({selected_pakageIds: selectedPakageIds});
		
		this.getProductDetailById(indFlg,pkgId);
	}
	
	
	
	calculatedOverAllAmount(indFlg) {
		
		   var proFieldArr = Array();
		   var proDetailArr = Array();
		   var AddonsArr = Array();
		   var SetupArr = Array();
		   var proMdfPrice = 0;
		   var stateProDet = this.state.productsdetail;
		   var stateProMdfPrice = this.state.product_modifiers_price;
		   let proMdfPriceNew = this.state.product_modifiers_price_new;
		   var stateProFldDet = this.state.productfeilsdetail;
		   var productAddons = this.state.product_addons;
		   var productSetup = this.state.product_setup;
		   var indKey = 'Index_'+indFlg;
		   var imageSource = '';
		   var productImage = '';
		   
		   if(Object.keys(stateProDet).length > 0) {
			   var temArr = (!(indKey in stateProDet)) ? Array() : stateProDet[indKey];
			   if(Object.keys(temArr).length > 0) {
				   proDetailArr = (Object.keys(temArr['resultset']).length > 0) ? temArr['resultset'][0]:Array();
				   imageSource = (Object.keys(temArr['commonurl']).length > 0) ? temArr['commonurl'].image_source:'';
				   ;
			   }
		   }
		   
		   if(Object.keys(stateProFldDet).length > 0) {
			   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
		   }
		   
		   if(Object.keys(productAddons).length > 0) {
			   AddonsArr = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
		   }
		   
		   if(Object.keys(productSetup).length > 0) {
			   SetupArr = (!(indKey in productSetup)) ? Array() : productSetup[indKey];
		   }
		   
		   if(Object.keys(stateProMdfPrice).length > 0) {
			   proMdfPrice = (!(indKey in stateProMdfPrice)) ? 0 : stateProMdfPrice[indKey];
		   }

		   var minpaxcount = 0;
		   var selectpaxcount = 0;
		   var productPrice = 0;
		   var productSubTotal = 0;
		   var productAddonsAmount = 0;
		   var productSetupAmount = 0;
		   var productGrandTotal = 0;

		   if(Object.keys(proDetailArr).length > 0) {

				productImage = (proDetailArr.product_thumbnail ==="" ) ? productNoImg : imageSource+"/"+proDetailArr.product_thumbnail;

				minpaxcount = proDetailArr.product_min_pax;

				selectpaxcount = (!('selectpaxcount' in proFieldArr)) ? proDetailArr.product_min_pax : proFieldArr['selectpaxcount'];

				/*productPrice = (parseFloat(proDetailArr.product_price) + parseFloat(proMdfPrice));*/

				productPrice = parseFloat(proDetailArr.product_price);

				productPrice+=proMdfPriceNew;
				
				
				productSubTotal = (parseFloat(selectpaxcount) * parseFloat(productPrice));


				for (var key in AddonsArr) {
					var AddonsValue = AddonsArr[key];
					for(var key2 in AddonsValue) {
						var ExpVarbl = AddonsValue[key2];
						var mytemparr = ExpVarbl.split("~~");
						if(parseInt(mytemparr[7]) > 0) {
						  productAddonsAmount += (parseInt(mytemparr[7]) * parseFloat(mytemparr[6]));
						}
					}
				}
				
				for (var key in SetupArr) {
					var SetupValue = SetupArr[key];
					if(Object.keys(SetupValue).length > 0) {
						for(var key2 in SetupValue) {
							var ExpVarbl = SetupValue[key2];
							var mytemparr = ExpVarbl.split("~~");
							if(parseInt(mytemparr[4]) > 0) {
							  productSetupAmount += (parseInt(mytemparr[4]) * parseFloat(mytemparr[3]));
							}
						}
					}
				}
		   
		   
				productGrandTotal = (parseFloat(productSubTotal) + parseFloat(productAddonsAmount) + parseFloat(productSetupAmount));
		   
				proFieldArr['product_id'] = proDetailArr.product_id;
				proFieldArr['product_name'] = proDetailArr.product_name;
				proFieldArr['product_sku'] = proDetailArr.product_sku;
				proFieldArr['product_slug'] = proDetailArr.product_slug;
				proFieldArr['productImage'] = productImage;
				proFieldArr['minpaxcount'] = minpaxcount;
				proFieldArr['selectpaxcount'] = selectpaxcount;
				proFieldArr['productPrice'] = productPrice;
				proFieldArr['productSubTotal'] = productSubTotal;
				proFieldArr['productAddonsAmount'] = productAddonsAmount;
				proFieldArr['productSetupAmount'] = productSetupAmount;
				proFieldArr['productGrandTotal'] = productGrandTotal;
				
				stateProFldDet[indKey] = proFieldArr;
				this.setState({productfeilsdetail: stateProFldDet});
				
		   }
		
	}

	productModifiers(indFlg,proModifiers) {
		
		const Modifiershtml = proModifiers.map((Modifiers, index1) =>
								 <div className="col-md-6 col-sm-6 modifiers_main_list" id={"mdf-"+indFlg+"-"+index1} data-mdfmin={Modifiers.pro_modifier_min_select} data-mdfmax={Modifiers.pro_modifier_max_select} data-mdfmain={Modifiers.pro_modifier_id+'~~'+Modifiers.pro_modifier_name} key={index1}>
										<div className="package_checkitem">
											<h3 className="package_formtt">
												<span>{(Modifiers.pro_modifier_alias_name!='')?stripslashes(Modifiers.pro_modifier_alias_name):stripslashes(Modifiers.pro_modifier_name)}
												</span>
													{(parseInt(Modifiers.pro_modifier_min_select) > 0)?<small>(Choice of {Modifiers.pro_modifier_min_select+" - "+Modifiers.pro_modifier_max_select} of the followings)</small>:""}
											</h3>														
											<ul className="package_formchecklist">
											{this.productModifierValues(indFlg,index1,Modifiers.modifiers_values)}
											</ul>
											<div className="pkgerror_txt" style={{display:'none'}}>Please select 1 more item!</div>
										</div>
								  </div>); 
			return Modifiershtml;	
		
	}
	
	productModifierValues(indFlg,index1,modifiersValues) {
		if(Object.keys(modifiersValues).length > 0) {
			const Modifiershtml = modifiersValues.map((ModfrValue, index2) =>
								  <li key={index2} className="modfr_valuecls" id={"mdfvalue-"+indFlg+"-"+index1+"-"+index2} data-mdfsub={ModfrValue.pro_modifier_value_id+'~~'+ModfrValue.pro_modifier_value_name+'~~1~~'+ModfrValue.pro_modifier_value_price}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="modifiers_valuecheck" value={ModfrValue.pro_modifier_value_id} onChange={this.checkModfrValue.bind(this,indFlg,index1,index2)} checked={this.CheckModfrValues(indFlg,index1,index2)} data-mdfvalueprice={ModfrValue.pro_modifier_value_price}/>
										<span>
										    {stripslashes(ModfrValue.pro_modifier_value_name)}{(parseFloat(ModfrValue.pro_modifier_value_price)>0)?' ($'+ModfrValue.pro_modifier_value_price+')':''}
											<b> {stripslashes(ModfrValue.pro_modifier_value_short_description)} </b>
										</span>
									</div>
								  </li>); 
		    return Modifiershtml;						  
		} else{
			return (<div className="m_norecord"> </div>);
		}
	}
	
	handlerTestFun() {
		
	}

	productAddonsSetup(indFlg,addonsSetup) {
		    
			const addonshtml = addonsSetup.map((proAddons, index3) =>
								  <li key={index3} id={"addonsLi-"+indFlg+"-"+index3} onChange={this.openAddonsPopup.bind(this,indFlg,index3,proAddons)} className={this.CheckAddonsValues(indFlg,index3,'class')}>
									<div className="custom_checkbox">
										<input type="checkbox" className="addonCheck" onChange={this.handlerTestFun.bind(this)} checked={this.CheckAddonsValues(indFlg,index3,'chkbox')}/>
										<span> {proAddons.cat_addon_title} </span>
										<div className="pkgerror_txt" style={{display:'none'}}>Please select 1 more item!</div>
									</div>
									<div id={"addonsDet-"+indFlg+"-"+index3} className="white-popup mfp-hide popup_sec addon_popup">
									    
										<div className="pouup_in">
											<div className="addon_poptop">			
												<div className="ttsplitter ttsplitter_lg text-center">
													<div className="ttsplitter_in">
														<span className="text-uppercase">
														{proAddons.cat_addon_title}
														</span>
													</div>
												</div>      
												
												<div className="addon_listsec">
												{(proAddons.cat_addon_selection === 'single')?this.singleAddonPopupList(indFlg,index3,proAddons):this.multipleAddonPopupList(indFlg,index3,proAddons)}
												</div>
												
											</div>
											<div className="addon_popbot">
												<div className="row">
													<div className="col-md-6 col-sm-6">
														<a href="javascript:void(0)" className="btn btn_grey btn-sm common-mfd-closefun" title="Go Back">Go Back</a>							
													</div>
													<div className="col-md-6 col-sm-6 text-right">
														<a href="javascript:void(0)" className="btn btn_pink btn-sm common-mfd-closefun" title="Continue">Continue</a>							
													</div>
												</div>
											</div>
										</div>
										
									</div>
								  </li>); 
			return addonshtml;						  
		
	}
	
	CheckAddonsValues(indFlg,index3,type) {
		var productAddons = this.state.product_addons;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		var classTxt = '';
		var chkBox = false;
		if(Object.keys(selecteAddonSub).length > 0) {
			classTxt = 'active';
			chkBox = true;
		}
		
		return (type === 'class') ? classTxt : chkBox;
	}
	
	CheckModfrValues(indFlg,index1,index2) {
		var productModifier = this.state.product_modifiers;
		var selecteMdfMain = Array();
		var selecteMdfSub = Array();
		var mdfrValueArr = Array();
		if(Object.keys(productModifier).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteMdfMain = (!(indKey in productModifier)) ? Array() : productModifier[indKey];
			if(Object.keys(selecteMdfMain).length > 0) {
				var subKey = 'modifr_'+index1;
				selecteMdfSub = (!(subKey in selecteMdfMain)) ? Array() : selecteMdfMain[subKey];
				
				mdfrValueArr = (Object.keys(selecteMdfSub).length > 0) ? selecteMdfSub['modifiervalues'] : Array();
			}
		}
		
		var mdfrValue = '';
		var mdValKy = 'mdfval_'+index2;
		if(Object.keys(mdfrValueArr).length > 0) {
			mdfrValue = (!(mdValKy in mdfrValueArr)) ? '' : mdfrValueArr[mdValKy];
		}
		
		var chkBox = false;
		if(mdfrValue !== '') {
			chkBox = true;
		}
		
		return chkBox;
	}
	
	openAddonsPopup(indFlg,index3,DelryValue) {
		var productAddons = this.state.product_addons;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		var liIdtxt = 'addonsLi-'+indFlg+'-'+index3;
		$('#'+liIdtxt).removeClass('active');
		if(Object.keys(selecteAddonSub).length > 0) {
			$('#'+liIdtxt).addClass('active');
			$('#'+liIdtxt).find('.addonCheck').prop('checked', true);
		} else {
			$('#'+liIdtxt).find('.addonCheck').prop('checked', false);
			
			if(DelryValue.cat_addon_selection === 'single'){
				$('#addonsDet-'+indFlg+'-'+index3).find('.addon_list_single').prop('checked', false);
			} else if(DelryValue.cat_addon_selection === 'multiple'){
				$('#addonsDet-'+indFlg+'-'+index3).find('.addon_qty_input').val('0');
				$('#addonsDet-'+indFlg+'-'+index3).find('.plue_muns_div').hide();
				$('#addonsDet-'+indFlg+'-'+index3).find('.qty_count_sp').hide();
				$('#addonsDet-'+indFlg+'-'+index3).find('.add_tocart_div').show();
			}
		}
		
		var idText = '#addonsDet-'+indFlg+'-'+index3;
		$.magnificPopup.open({
			items: {
				src: idText
			},
			type: 'inline'
		});
	}
	
	multipleAddonPopupList(indFlg,index3,proAddons) {
		var proAddonsValues = proAddons.addons_setup_values;
		
		if(Object.keys(proAddonsValues).length > 0) {
			const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) =>
										<div className="addon_listrw addon_list_main" id={"addon_main-"+indFlg+"-"+index3+"-"+index6} key={index6}>
											<div className="addon_linfo">
												<h4>{AddonsValue.cat_addonvalues_title}</h4>
													{(AddonsValue.cat_addonvalues_description !== '')?<p>{AddonsValue.cat_addonvalues_description}</p>:''}
											</div>
											<div className="addon_lright text-right add_tocart_div">
											    <span className="cross_multi qty_count_sp" style={{display:'none'}}>x1</span>
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
												<a href="javascript:void(0)" onClick={this.addonSubAddtoCart.bind(this,indFlg,index3,index6)} className="btn btn_pink btn-sm" title="Add to cart">Add to cart</a>							
											</div>
										    <div data-addonbind={proAddons.product_catering_addons_setup_id+"~~"+proAddons.cat_addon_title+"~~"+proAddons.cat_addon_selection+"~~"+AddonsValue.cat_addonvalues_id+"~~"+AddonsValue.cat_addonvalues_title+"~~"+AddonsValue.cat_addonvalues_description+"~~"+AddonsValue.cat_addonvalues_price} className="addon_lright text-right plue_muns_div" style={{display:'none'}}>
												<span className="cross_multi qty_count_sp" style={{display:'none'}}>x1</span>	
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
												<div className="qty_bx catqty_bx"> 
													<span className="qty_minus" onClick={this.addonPlusMinusCart.bind(this,indFlg,index3,index6,'minus')}>--</span>
													<input type="text" className="addon_qty_input" value={this.getQtyValue(indFlg,index3,index6)} readOnly /> 
													<span className="qty_plus" onClick={this.addonPlusMinusCart.bind(this,indFlg,index3,index6,'plus')}>+</span> 
												</div>
												<a href="javascript:void(0)" onClick={this.addonDoneCart.bind(this,indFlg,index3,index6)} className="btn btn_grey btn-sm" title="Add to cart">Done</a>							
											</div>
										</div>); 
			 return AddonsValuehtml;
		}
	}
	
	singleAddonPopupList(indFlg,index3,proAddons) {
		var proAddonsValues = proAddons.addons_setup_values;
		
		if(Object.keys(proAddonsValues).length > 0) {
			const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) =>
										<div className="addon_listrw addon_list_main" id={"addon_main-"+indFlg+"-"+index3+"-"+index6} key={index6}>
											<div className="addon_linfo">
											   <div className="custom_checkbox">
												<input type="checkbox" className="addon_list_single" onClick={this.addonSingleCart.bind(this,indFlg,index3,index6)} />
												<span>
												<h4>{AddonsValue.cat_addonvalues_title}</h4>
													{(AddonsValue.cat_addonvalues_description !== '')?<p>{AddonsValue.cat_addonvalues_description}</p>:''}
												</span>
												</div>		
											</div>
											<div data-addonbind={proAddons.product_catering_addons_setup_id+"~~"+proAddons.cat_addon_title+"~~"+proAddons.cat_addon_selection+"~~"+AddonsValue.cat_addonvalues_id+"~~"+AddonsValue.cat_addonvalues_title+"~~"+AddonsValue.cat_addonvalues_description+"~~"+AddonsValue.cat_addonvalues_price} className="addon_lright text-right add_tocart_div">
											    <span className="cross_multi qty_count_sp" style={{display:'none'}}>x1</span>
												<span className="addon_price">{showPriceValue(AddonsValue.cat_addonvalues_price)}</span>
											</div>
										</div>); 
			 return AddonsValuehtml;
		}
	}
	
	getQtyValue(indFlg,index3,index6) {
		var productAddons = this.state.product_addons;
		var selecteAddonsMain = Array();
		var selecteAddonSub = Array();
		var qtyValue = 0;
		if(Object.keys(productAddons).length > 0) {
			var indKey = 'Index_'+indFlg;
			selecteAddonsMain = (!(indKey in productAddons)) ? Array() : productAddons[indKey];
			if(Object.keys(selecteAddonsMain).length > 0) {
				var subKey = 'addon_'+index3;
				selecteAddonSub = (!(subKey in selecteAddonsMain)) ? Array() : selecteAddonsMain[subKey];
			}
		}
		
		if(Object.keys(selecteAddonSub).length > 0) {
			var ExpVarbl = selecteAddonSub[index6];
			var mytemparr = ExpVarbl.split("~~");
			qtyValue = mytemparr[7];
		}
		
		return qtyValue;
	}
	
	
	addonSubAddtoCart(indFlg,index3,index6) {
		var mainDivId = "addon_main-"+indFlg+"-"+index3+"-"+index6;
		$('#'+mainDivId).find('.add_tocart_div').hide();
		$('#'+mainDivId).find('.plue_muns_div').show();
	}
	
	addonDoneCart(indFlg,index3,index6) {
		
		var mainDivId = "addon_main-"+indFlg+"-"+index3+"-"+index6;
		var indKey = 'Index_'+indFlg;
		var subKey = 'addon_'+index3;
		
		var tempArr = Array();var allValEmpty = 'yes';
		$('#'+mainDivId).closest('.addon_popup').find('.addon_qty_input').each(function(newInt){
			var alltmFl = $(this).closest('.plue_muns_div').attr('data-addonbind');
			var qtyInt = $(this).val();
			var temarFl = alltmFl+"~~"+qtyInt;
			tempArr[newInt] = temarFl;
			if(parseInt(qtyInt) > 0) {
				allValEmpty = 'No';
			}
		});
		
		var productAddons = this.state.product_addons;
		if(allValEmpty == 'No') {
			productAddons[indKey][subKey] = tempArr;
		} else {
			productAddons[indKey][subKey] = Array();
		}
		
		this.setState({product_addons: productAddons}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
		
		var qty_input = $('#'+mainDivId).find('.addon_qty_input').val();
		
		$('#'+mainDivId).find('.plue_muns_div').hide();
		if(parseInt(qty_input) > 0) {
			$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp').show();
		} else {
			$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp').hide();
		}
		$('#'+mainDivId).find('.add_tocart_div').show();
		
	}
	
	addonSingleCart(indFlg,index3,index6) {
		
		var mainDivId = "addon_main-"+indFlg+"-"+index3+"-"+index6;
		var indKey = 'Index_'+indFlg;
		var subKey = 'addon_'+index3;
		
		var tempArr = Array();var allValEmpty = 'yes';
		var incvl = 0;
		$('#'+mainDivId).closest('.addon_popup').find('.addon_list_single').each(function(newInt){
			if($(this).is(':checked')){
				var alltmFl = $(this).closest('.addon_list_main').find('.add_tocart_div').attr('data-addonbind');
				var temarFl = alltmFl+"~~1";
				tempArr[incvl] = temarFl;
				allValEmpty = 'No';
				incvl++;
			}
		});
		
		var productAddons = this.state.product_addons;
		if(allValEmpty == 'No') {
			productAddons[indKey][subKey] = tempArr;
		} else {
			productAddons[indKey][subKey] = Array();
		}
		
		this.setState({product_addons: productAddons}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
		
	}
	
	addonPlusMinusCart(indFlg,index3,index6,type) {
		var mainDivId = "addon_main-"+indFlg+"-"+index3+"-"+index6;
		var qty_input = $('#'+mainDivId).find('.addon_qty_input').val();
		if(type === 'minus') {
			qty_input = parseInt(qty_input);
			if(qty_input > 0) {
				qty_input = qty_input - 1;
				$('#'+mainDivId).find('.addon_qty_input').val(qty_input);
			}
			
		} else {
			qty_input = parseInt(qty_input) + 1;
			$('#'+mainDivId).find('.addon_qty_input').val(qty_input);
		}
		var count_sp_text = 'X'+qty_input;
		$('#'+mainDivId).find('.add_tocart_div').find('.qty_count_sp').html(count_sp_text);
	}
	
	productDeliverySetupHtml(indFlg,DeliverySetup) {
		if(Object.keys(DeliverySetup).length > 0) {
			return (<div className="pkgsetup_listrw delivery_main_div">
						<div className="package_controltt">
							<div className="custom_radio">
								<input type="radio" defaultChecked className="setup_cls delivery_input" onClick={this.handleClickSetup.bind(this,indFlg,'delivery')} name="setup_input" value="delivery"/>
								<span>Delivery</span>
							</div>
						</div>
						<ul className="package_formchecklist delivery_listing_div">
							{this.productDeliverySetup(indFlg,DeliverySetup)}
						</ul>
					</div>);					  
		}
	}

	productSelfcollectionSetupHtml(indFlg,SelfcollectionSetup) {
		if(Object.keys(SelfcollectionSetup).length > 0) {
			return (<div className="pkgsetup_listrw selfcollection_main_div">
						<div className="package_controltt">
							<div className="custom_radio">
								<input type="radio" className="setup_cls selfcollection_input" onClick={this.handleClickSetup.bind(this,indFlg,'selfcollection')} name="setup_input" value="selfcollection"/>
								<span>Self Collection</span>
							</div>
						</div>
						<ul className="package_formchecklist selfcollection_listing_div">
							{this.productSelfCollectionSetup(indFlg,SelfcollectionSetup)}
						</ul>
					</div>);					  
		}
	}
	
	productDeliverySetup(indFlg,DeliverySetup) {
			const Deliveryhtml = DeliverySetup.map((DelryValue, index4) =>
								  <li key={index4} className="setup_li" data-setupdet={DelryValue.catering_delivery_setup_id+"~~"+DelryValue.catering_delivery_title+"~~"+DelryValue.catering_delivery_description+"~~"+DelryValue.catering_delivery_price+"~~1~~Delivery"}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="setup_listing_value" value={DelryValue.catering_delivery_price} onChange={this.handleUpdateSetup.bind(this,indFlg,index4,'delivery')} checked={this.CheckDeliveryBuffSelf(indFlg,index4,DelryValue.catering_delivery_setup_id,'delivery')} />
										<span>
										    {DelryValue.catering_delivery_title+" ( "+showPriceValue(DelryValue.catering_delivery_price)+" ) : "+DelryValue.catering_delivery_description}
										</span>
									</div>
								  </li>); 
		    return Deliveryhtml;						  
	}

	productSelfCollectionSetup(indFlg,SelfcollectionSetup) {
			const Selfcollectionhtml = SelfcollectionSetup.map((DelryValue, index4) =>
								  <li key={index4} className="setup_li" data-setupdet={DelryValue.catering_self_collection_setup_id+"~~"+DelryValue.catering_self_collection_title+"~~"+DelryValue.catering_self_collection_description+"~~"+DelryValue.catering_self_collection_price+"~~1~~self_collection"}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="setup_listing_value" value={DelryValue.catering_self_collection_price} onChange={this.handleUpdateSetup.bind(this,indFlg,index4,'selfcollection')} checked={this.CheckDeliveryBuffSelf(indFlg,index4,DelryValue.catering_self_collection_setup_id,'selfcollection')} />
										<span>
										    {DelryValue.catering_self_collection_title+" ( "+showPriceValue(DelryValue.catering_self_collection_price)+" ) : "+DelryValue.catering_self_collection_description}
										</span>
									</div>
								  </li>); 
		    return Selfcollectionhtml;						  
	}
	
	CheckDeliveryBuffSelf(indFlg,index4,idtxt,type) {
		
		var sateProductSetup = this.state.product_setup;
		var selecteDBMain = Array();
		var selecteDBfSub = Array();
		var indKey = 'Index_'+indFlg;
		var subKey = type;
		var chkBox = false;
		
		if(Object.keys(sateProductSetup).length > 0) {
			selecteDBMain = (!(indKey in sateProductSetup)) ? Array() : sateProductSetup[indKey];
			if(Object.keys(selecteDBMain).length > 0) {
				selecteDBfSub = (!(subKey in selecteDBMain)) ? Array() : selecteDBMain[subKey];
				for(var key in selecteDBfSub) {
					var ExpVarbl = selecteDBfSub[key];
					var mytemparr = ExpVarbl.split("~~");
					if(mytemparr[0] == idtxt) {
					  chkBox = true;
					}
				}
			}
		}
		
		return chkBox;
	}
	
	productBuffetSetupHtml(indFlg,BuffetSetup) {
		if(Object.keys(BuffetSetup).length > 0) {
			return (<div className="pkgsetup_listrw buffet_main_div">
						<div className="package_controltt">
							<div className="custom_radio">
								<input type="radio" className="setup_cls buffet_input" onClick={this.handleClickSetup.bind(this,indFlg,'buffet')} name="setup_input" value="delivery"/>
								<span>Buffets Setup</span>
							</div>
						</div>
						<ul className="package_formchecklist buffet_listing_div" style={{display:'none'}}>
							{this.productBuffetSetup(indFlg,BuffetSetup)}
						</ul>
					</div>);					  
		}
	}
	
	productBuffetSetup(indFlg,BuffetSetup) {
			const Buffethtml = BuffetSetup.map((BuffetValue, index5) =>
								  <li key={index5} className="setup_li" data-setupdet={BuffetValue.catering_buffet_setup_id+"~~"+BuffetValue.catering_buffet_title+"~~"+BuffetValue.catering_buffet_description+"~~"+BuffetValue.catering_buffet_price+"~~1~~Buffet"}>
								    <div className="custom_checkbox">
										<input type="checkbox" className="setup_listing_value" value={BuffetValue.catering_buffet_price} checked={this.CheckDeliveryBuffSelf(indFlg,index5,BuffetValue.catering_buffet_setup_id,'buffet')} onChange={this.handleUpdateSetup.bind(this,indFlg,index5,'buffet')} />
										<span>
										    {BuffetValue.catering_buffet_title+" ( "+showPriceValue(BuffetValue.catering_buffet_price)+" ) : "+BuffetValue.catering_buffet_description}
										</span>
									</div>
								  </li>); 
		    return Buffethtml;						  
	}
	
	handleClickSetup(indFlg,type) {
		
		var indKey = 'Index_'+indFlg;
		var subKey1 = 'delivery';
		var subKey2 = 'buffet';
		var subKey3 = 'selfcollection';
		var deliveryArr = Array();
		var buffetArr = Array();
		var selfcollectionArr = Array();
		var sateProductSetup = this.state.product_setup;
		
		if(type == 'delivery') {
			$('#setup_'+indFlg).find('.buffet_listing_div').hide();
			$('#setup_'+indFlg).find('.selfcollection_listing_div').hide();
			$('#setup_'+indFlg).find('.delivery_listing_div').show();
			sateProductSetup[indKey][subKey2] = buffetArr;
			
			$('#setup_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				$(this).prop('checked', false);
			});
			
		} else if(type == 'selfcollection') {
			$('#setup_'+indFlg).find('.buffet_listing_div').hide();
			$('#setup_'+indFlg).find('.delivery_listing_div').hide();
			$('#setup_'+indFlg).find('.selfcollection_listing_div').show();
			sateProductSetup[indKey][subKey3] = selfcollectionArr;
			
			$('#setup_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				$(this).prop('checked', false);
			});
			
		} else {
			$('#setup_'+indFlg).find('.delivery_listing_div').hide();
			$('#setup_'+indFlg).find('.buffet_listing_div').show();
			sateProductSetup[indKey][subKey1] = deliveryArr;
			
			$('#setup_'+indFlg).find('.delivery_listing_div').find('.setup_listing_value').each(function(dlvy){
				$(this).prop('checked', false);
			});
		}
		
		this.setState({product_setup: sateProductSetup}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
	}
	
	handleUpdateSetup(indFlg,indexInr,type) {
		
		var deliveryArr = Array();
		var buffetArr = Array();
		var selfcollectionArr = Array();
		if(type === 'delivery') {
			var incvl = 0;
			$('#setup_'+indFlg).find('.delivery_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					deliveryArr[incvl] = $(this).closest('.setup_li').attr('data-setupdet');
					incvl++;
				}
			});
		} else if(type === 'buffet') {
			var incvl = 0;
			$('#setup_'+indFlg).find('.buffet_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					buffetArr[incvl] = $(this).closest('.setup_li').attr('data-setupdet');
					incvl++;
				}
			});
		} else if(type === 'selfcollection') {
			var incvl = 0;
			$('#setup_'+indFlg).find('.selfcollection_listing_div').find('.setup_listing_value').each(function(dlvy){
				if($(this).is(':checked')){
					selfcollectionArr[incvl] = $(this).closest('.setup_li').attr('data-setupdet');
					incvl++;
				}
			});
		}
		
		var indKey = 'Index_'+indFlg;
		var subKey1 = 'delivery';
		var subKey2 = 'buffet';
		var subKey3 = 'selfcollection';
		var sateProductSetup = this.state.product_setup;
			sateProductSetup[indKey][subKey1] = deliveryArr;
			sateProductSetup[indKey][subKey2] = buffetArr;
			sateProductSetup[indKey][subKey3] = selfcollectionArr;
		
		this.setState({product_setup: sateProductSetup}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
	}
	
	updateProductPax(indFlg,type,selectpaxcount,minpaxcount,maxpaxcount) {
		minpaxcount = (parseInt(minpaxcount) > 0) ? parseInt(minpaxcount) : 1;
		maxpaxcount = (parseInt(maxpaxcount) > 0) ? parseInt(maxpaxcount) : 0;
		selectpaxcount = parseInt(selectpaxcount);

		if(type === 'minus') {
			if(minpaxcount < selectpaxcount && selectpaxcount > 1) {
				selectpaxcount = selectpaxcount - 1;
			}
		} else {

			if(selectpaxcount < maxpaxcount && maxpaxcount > 1) {
				selectpaxcount = selectpaxcount + 1;
			} else if(maxpaxcount < 1) {
				selectpaxcount = selectpaxcount + 1;
			} else {}
		}
		
		var indKey = 'Index_'+indFlg;
		var proFieldArr = Array();
		var stateProFldDet = this.state.productfeilsdetail;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
	    }
		proFieldArr['selectpaxcount'] = selectpaxcount;
		stateProFldDet[indKey] = proFieldArr;
		this.setState({productfeilsdetail: stateProFldDet}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));
	}
	
	checkModfrValue(indFlg,index1,index2) {
		var proMin = $("#proDetMain-"+indFlg).attr('data-packageminlimit');
		var proMax = $("#proDetMain-"+indFlg).attr('data-packagemaxlimit');
		var modMin = $("#mdf-"+indFlg+"-"+index1).attr('data-mdfmin');
		var modMax = $("#mdf-"+indFlg+"-"+index1).attr('data-mdfmax');
		var checked_count = 0;
		var total_count = 0;
		
		$("#mdf-"+indFlg+"-"+index1).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  checked_count +=1;
			}
		});
		
		$("#proDetMain-"+indFlg).find('.modifiers_valuecheck').each(function(){
			if($(this).is(':checked')){
			  total_count +=1;
			}
		});
		
		$("#mdf-"+indFlg+"-"+index1).removeClass('pkgerror_out');
		$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').hide();
		$("#proDetMain-"+indFlg).find('.mdf_common_error').hide();
		/*if(parseInt(modMin) > parseInt(checked_count)) {
			var errorTxt = "Please select minimum "+modMin+" item."
			$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').html(errorTxt);
			$("#mdf-"+indFlg+"-"+index1).addClass('pkgerror_out');
			$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').show();
			$("#mdfvalue-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', true);
		} else*/ if(parseInt(modMax) < parseInt(checked_count)) {
			var errorTxt = "Sorry!. maximum of selected item is "+modMax+" only."
			$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').html(errorTxt);
			$("#mdf-"+indFlg+"-"+index1).addClass('pkgerror_out');
			$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').show();
			$("#mdfvalue-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', false);
		} else if(parseInt(proMax) < parseInt(total_count)) {
			var errorTxt = "Sorry!. maximum of selected item is "+proMax+" only."
			$("#proDetMain-"+indFlg).find('.mdf_common_error').html(errorTxt);
			$("#proDetMain-"+indFlg).find('.mdf_common_error').show();
			$("#mdfvalue-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', false);
		}
		
		
		
		var indKey = 'Index_'+indFlg;
		var subKey = 'modifr_'+index1;
		
		var tempArr = Array();var allValEmpty = 'yes';
		var modfrMn = $("#mdf-"+indFlg+"-"+index1).attr('data-mdfmain');
		$("#mdf-"+indFlg+"-"+index1).find('.modifiers_valuecheck').each(function(mdint){
			if($(this).is(':checked')){
			  tempArr['mdfval_'+mdint] = $(this).closest('.modfr_valuecls').attr('data-mdfsub');
			  allValEmpty = 'No';
			}
		});
		
		
		var productModfr= this.state.product_modifiers;
		if(allValEmpty == 'No') {
			var MdfArr = Array();
			MdfArr['modifiers'] = modfrMn;
			MdfArr['modifiervalues'] = tempArr;
			productModfr[indKey][subKey] = MdfArr;
		} else {
			productModfr[indKey][subKey] = Array();
		}

		let proMdfPrice =0;
		$("#proDetMain-"+indFlg).find('.modifiers_valuecheck').each(function() {
			if($(this).is(':checked')) {

				if($(this).data('mdfvalueprice') != '') {
					proMdfPrice+=parseFloat($(this).data('mdfvalueprice'));
				}
			}
		});

		this.setState({product_modifiers: productModfr,product_modifiers_price_new:proMdfPrice}, function () { this.calculatedOverAllAmount(indFlg); }.bind(this));

		this.hideModfrValueError(indFlg,index1);
	}
	
	hideModfrValueError(indFlg,index1)
	{
		setTimeout(function()
		{
			$("#mdf-"+indFlg+"-"+index1).removeClass('pkgerror_out');
			$("#mdf-"+indFlg+"-"+index1).find('.pkgerror_txt').hide();
			$("#proDetMain-"+indFlg).find('.mdf_common_error').hide();
		}, 6000);
	}
	
	
	handleSpecialInstruction(indFlg, event) {
		
		var stateProFldDet = this.state.productfeilsdetail;
		var proFieldArr = Array();
		var indKey = 'Index_'+indFlg;
		if(Object.keys(stateProFldDet).length > 0) {
		   proFieldArr = (!(indKey in stateProFldDet)) ? Array() : stateProFldDet[indKey];
	    }
		var remarks = event.target.value;
		proFieldArr['productSpecialNotes'] = remarks;
		stateProFldDet[indKey] = proFieldArr;
		this.setState({productfeilsdetail: stateProFldDet});
	}
	
	resetPackage(indFlg) {
		var selectedPakageIds = this.state.selected_pakageIds;
		var kyIndx = 'Index-'+indFlg;
		selectedPakageIds[kyIndx] = '';
		this.setState({selected_pakageIds: selectedPakageIds});
	}
	
	resetAllpackData() {
		var selectedPakageIds = this.state.selected_pakageIds;
		if(Object.keys(selectedPakageIds).length > 0) {
			for (var key in selectedPakageIds) {
				selectedPakageIds[key] = '';
			}
			this.setState({selected_pakageIds: selectedPakageIds});
		}
	}

	getProducttag = (tags) =>  {

		if(tags !== null) {

			tags = tags.split(',');

			return (
			<div className="food_type">
			{ tags.map((tagvalue, index) => (
				 <span key={index}>{tagvalue}</span>
				 
				 ))}
			</div>
			);	

		}
		
		

	}
	
	/* for un login */
	sendquotationdata() {
		
				
		var userName = this.state.user_name;
		var userEmail = this.state.user_email;
		var userPhone = this.state.user_phone;
		
        let reference_id ='';    
        if (typeof cookie.load('UserId') === 'undefined') {
			 reference_id = getReferenceID();
		} 
		
		var comSettings = this.state.settings;
		var client_delivery_surcharge = 0;
		var gstPar = 0;
		if(Object.keys(comSettings).length > 0) {
			client_delivery_surcharge = (comSettings.client_delivery_surcharge !== '') ? parseFloat(comSettings.client_delivery_surcharge) : 0;
			gstPar = (comSettings.client_tax_surcharge !== '') ? parseFloat(comSettings.client_tax_surcharge) : 0;
		}
	
		
		
        var urlParam = "&reference_id="+reference_id+"&request_email="+userEmail+"&request_name="+userName+"&request_phone="+userPhone+"&client_delivery_surcharge="+client_delivery_surcharge+"&client_gst_charge="+gstPar;
        
        $(".signup_submit").append('<b class="gloading_img"></b>');

		this.props.requestDownloadQuotationData(urlParam);
		

         
	}
	
	handleAddrChange(event) 
	{
		if(event.target.name === 'user_name') {
			this.setState({user_name: event.target.value});
		} else if(event.target.name === 'user_phone') {
			this.setState({user_phone: event.target.value});
		} else if(event.target.name === 'user_email') {
			this.setState({user_email: event.target.value});
		} 
	}
	
	
	movetabsec() {

		$.magnificPopup.close();
		this.props.setActiveTab(2);
	}
	checkoutlogin(){
		
		if (typeof cookie.load('UserId') === 'undefined') {
			cookie.save('trgLgin','Yes');
		const{history} = this.props;
		if(cookie.load('trgLgin') === 'Yes') {
			cookie.save('fromCkeckOutVld','Yes')
			this.sateValChange('checkout_submit', 'Ok');
			$.magnificPopup.open({
				items: {
					src: '#login-popup'
				},
				type: 'inline'
			});
		}
	 }else{
		 		$.magnificPopup.close();

		 this.props.setActiveTab(4);
	 }
	}
	
	sateValChange = (field, value) => {

		if(field === 'catering_date') {
			this.setState({catering_date: value});
		}
		else if(field === 'catering_time') {
			this.setState({catering_time: value});
		}
		else if(field === 'breaktime_enable') {
			this.setState({breaktime_enable: value});
		}
		else if(field === 'breaktime_count') {
			this.setState({breaktime_count: value});
		}
		else if(field === 'breaktime_list') {
			this.setState({breaktime_list: value});
		}
		else if(field === 'break_timeHtml') {
			this.setState({break_timeHtml: value});
		}
		else if(field === 'outlet_id') {
			this.setState({catering_outlet_id: value});
		}
		else if(field === 'hall_id') {
			this.setState({catering_hall_id: value});
		}
		else if(field === 'venue_submit') {
			this.setState({trigger_venue_submit: value});
		}
		else if(field === 'catePrimaryId') {
			this.setState({catePrimaryId: value});
		}
		else if(field === 'category_id') {
			this.setState({category_id: value});
		}
		else if(field === 'checkout_submit') {
			this.setState({trigger_checkout_submit: value});
		}
		else if(field === 'category_name') {
			this.setState({category_name: value});	
		}
		else if(field === 'active_tabflag') {
			this.setState({active_tabflag: value});	
		}
		else if(field === 'add_new_item') {
			this.setState({add_new_item: value});	
		}
    }
	
	setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}
	
	render() {

        return (<>
				  {/* <h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(3)} onClick={this.setPropActiveTab.bind(this,3)} >UPDATE YOUR PACKAGE<i></i></h4> */}
				   <div className="tab_mobrow" style={{display:this.props.showsActiveDiv(3)}}>

						{ this.getTopBarDetails() }

						<div className="pkselect_tabsec">

							<ul className="nav nav-tabs">

								{ this.getBreakTime_BarHtml() }

							</ul>

							<div className="tab-content breaktime_tab">
								{ this.getProductListHtml() }
							</div>

						</div>	
					
					<div id="dvLoading" className="dvLoaderCls"></div>
				  </div>
				{ /*start*/ }
				  <div id="get_quotationdata" className="white-popup mfp-hide popup_sec quotpopup">
    <div className="popup-body">
        <div className="gen-quote">
            <h3>Generate Quotation</h3>
        </div>
        <div className="quote-desc">
          {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti blanditiis nesciunt, voluptates hic iure harum nisi at ullam delectus excepturi, iste obcaecati saepe consequatur perferendis necessitatibus earum neque maiores, nihil.</p>*/}
        </div>
        <div className="form-body">
            <div className="form-group">
                <div className="focus-out">
                    <label>name</label>
                    <input type="input" name="user_name" value={this.state.user_name} className="form-control input-focus" value={this.state.user_name} onChange={this.handleAddrChange.bind(this)} />
                </div>

            </div>

            <div className="form-group">
                <div className="focus-out">
                    <label>Phone Number</label>

                    <input type="input" name="user_phone" className="form-control input-focus" value={this.state.user_phone} onChange={this.handleAddrChange.bind(this)}/>


                </div>
            </div>
            <div className="form-group">
                <div className="focus-out">
                    <label>Email Address</label>
                    <input type="input" name="user_email" className="form-control input-focus"  value={this.state.user_email} onChange={this.handleAddrChange.bind(this)}/>
                </div>
            </div>

            <div className="form-group">
                <div className="login_pop_sub">
                    <button type="button" className="btn btn_black btn_minwid signup_submit" onClick={this.sendquotationdata.bind(this)}>Submit</button>
                </div>
            </div>

        </div>
    </div>
</div>

<div className="white-popup mfp-hide popup_sec" id="datetime_valid_sec" style={{maxWidth: 500}}><div className="custom_alert"><div className="custom_alertin"><div className="alert_height"><div className="alert_header">Error</div><div className="alert_body"><p>Please choose date and time?</p><div className="alt_btns"><a href="javascript:;" className="btn btn-sm btn_yellow" onClick={this.movetabsec.bind(this)}>OK</a></div></div></div></div></div></div>

<div className="white-popup mfp-hide popup_sec" id="checkout-cont-popup" style={{maxWidth: 500}}><div className="custom_alert"><div className="custom_alertin"><div className="alert_height"><div className="alert_header">Success</div><div className="alert_body"><p>Item added to your cart.</p><p>Do you want to proceed to checkout?</p><div className="alt_btns"><a href="javascript:;" className="btn btn-sm btn_yellow popup-modal-dismiss">No</a><a href="javascript:;" className="btn btn-sm btn_yellow" onClick={this.checkoutlogin.bind(this)}>Yes</a></div></div></div></div></div></div>


				{ /* end */}
				</>);
     }
}

function mapStateToProps(appState, ownProps) {

  return {
    staicblkData: appState.ui.staicblkData,
    downloadQuotationData: appState.ui.DownloadQuotationData,
    cartlistData: appState.ui.cartlistData,

    
   
  }
  
   
}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestStaicblkData,
      requestDownloadQuotationData,
      requestCartlistData,
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Packagedetails);
