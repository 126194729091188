import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getSecondaryAddRemoveData(postData){
  return await axios.post(apiUrl + "customer/secondary_address_remove",postData)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
