/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';

import { stripslashes } from "../../Helpers/SettingHelper";
var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestPromoData
} from '../../modules/ui/actions';


class Promoinfo extends Component {


    constructor(props) {
        super(props);

        this.state = { };
    }

    componentDidMount() {

		this.props.requestPromoData();
    }

    render() {
		
		
		let promoinfo = '';

		if(this.props.promoData) {

			if(this.props.promoData.status == 'ok') {

				promoinfo = Parser(stripslashes(this.props.promoData.result_set[0].staticblocks_description));
			}
		}
		

        return (
		<div>
		  {promoinfo}
		</div>

        );
    }
}

function mapStateToProps(appState, ownProps) {

  return {
    promoData: appState.ui.promoData
  }

}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
       requestPromoData
    }, dispatch)
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Promoinfo);

