/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';

import '@ptkdev/webcomponent-instagram-widget';

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Banner from "./Banner";
import Featuredproduct from "./Featuredproduct";

export default  class Home extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {};
    }
    
	componentDidMount()
	{
		
		
		var cateringPay = (typeof cookie.load('cateringPay') === 'undefined') ? '' : cookie.load('cateringPay');
		if(cateringPay === 'initial') {
			cookie.save('cateringPay', 'start');
			$.magnificPopup.open({
				items: {
					src: '#login-popup'
				},
				type: 'inline'
			});
		}
		
		
		setTimeout(function () {

			if (cookie.load('loginPopup') == 1 && !cookie.load("UserId")) {
				$.magnificPopup.open({
				  items: {
					src: '#login-popup'
				  },
				  type: 'inline'
				});
			}

			cookie.save('loginPopup', 0);

			/*if ($(".open-popup-link").length > 0) {
				$('.open-popup-link').magnificPopup({
				  type: 'inline',
				  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
				});
			}*/

		}, 3000);

	}

	componentWillReceiveProps() {}

 render() {

    return (
      <div className="Home">

		<Header />
		<Banner />
		<Featuredproduct />
		<>

		 <instagram-widget username="@ishirosg" grid="4x3" force-square="yes" items-limit="12" image-width="100%" image-height="50%" border-corners="5" border-spacing="2px"></instagram-widget>
		 <div style={{textAlign:'center'}}>
			<a className="btn btn_pink view_more" href="https://www.instagram.com/ishirosg" title="View More" target="_blank" >View More</a></div>
		</>
		<Footer />

	</div>

    );
  }
}
 
