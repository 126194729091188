/* eslint-disable */
import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Slider from "react-slick";

import { timThumpUrl } from "../../Helpers/Config";
import { stripslashes, callImage} from "../../Helpers/SettingHelper";


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestCatlistData,
  requestStaicblkData
} from '../../modules/ui/actions';

var Parser = require('html-react-parser');

class Featuredproduct extends Component {


    constructor(props) {
        super(props);

        this.state = { express_catering_content:''};
    }

    componentDidMount() {
			 this.props.requestCatlistData({highlight_option:'Yes'});
			  this.props.requestStaicblkData({slug:'express-caterng-menu-content'});
    }
 
    componentWillReceiveProps(nextProps) {
		
		
		 /*Get date list*/
		 if(typeof nextProps.staicblkData != 'undefined' && nextProps.staicblkData.status == 'ok') {

			this.setState({'express_catering_content': Parser(stripslashes(nextProps.staicblkData.result_set[0].staticblocks_description))});
				
		}
    
	}

    render() {

		let commonimgurl_obj;
		let categorylist = [];

		if(this.props.catlistData) {

			categorylist = this.props.catlistData.result_set;
			commonimgurl_obj = this.props.catlistData.common;

		}
		
		
    
    
   
		if( categorylist !== undefined && categorylist.length) {
			
			var slider_fcnt =  ((categorylist.length) > 3)?3:2; 

			var settings = {
			  infinite: true,
			  slidesToShow: slider_fcnt,
			  slidesToScroll: 1,
			  responsive: [{
				breakpoint: 1191,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 3
				  
				}
			  },
			  {
				breakpoint: 850,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 2,
				  infinite: true
				}
			  },
			  {
				breakpoint: 680,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1
				}
			  }
			  ]

			};

        return (
				<div>
				<div className="clearfix"></div>
				<div className="hme-product">
				   <div className="container">
					  <div className="hme-product-inner">
						 <div className="title_sec text-center">

							{this.state.express_catering_content}
							
						 </div>
						 <div className="hme-prt-row">
						<Slider {...settings}>

							{
								  (categorylist || []).map(
								  (category,index) => {
									  return (

									<div className="hme-prt-column" key={index}>
									   <div className="hme-prt-inner">
										  <div className="hme-prt-hei">{callImage(commonimgurl_obj.category_image_url, category.pro_cate_image, 350, 390, timThumpUrl)}</div>
										  <div className="hme-prt-slidecontent">
											 <div className="hme-prt-slidecontent-inner">
												<h3>{stripslashes(category.pro_cate_name)}</h3>
												
												<p>{stripslashes(category.pro_cate_short_description)}</p>
												
												<Link to={"/category/"+category.pro_cate_id} className="btn btn_pink btn_minwid">View Menu</Link>
											 </div>
										  </div>
									   </div>
									</div>

									  )
									}
									) 
								 }

							</Slider>
							 </div>
						 </div>
					 
				   </div>
				</div>
			</div>
        );
        
	} else {
		 return '';
	}
        
    }
}

function mapStateToProps(appState, ownProps) {

  return {
    catlistData: appState.ui.catlistData,
    staicblkData: appState.ui.staicblkData
  }

}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestCatlistData,
      requestStaicblkData
    }, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Featuredproduct);

