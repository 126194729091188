import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getAddressData(postData){
  return await axios.get(apiUrl + '/settings/get_address?app_id='+appId)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
