import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getRegistrationData(postData){
  return await axios.post(apiUrl + "customer/registration",postData)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
