import { takeEvery, call, put } from  'redux-saga/effects';


import {getBannerData} from './api/banner';

import {getPageData} from './api/pages';

import {getMenuNavigationData} from './api/menu';

import {getFbLoginData} from './api/fblogin';

import {updateCustomerUpdateData} from './api/customerupdate';

import {getForgetPasswordData} from './api/forgetpassword';

import {getRegistrationData} from './api/registration';

import {getLoginData} from './api/login';

import {getCustomerDetailData} from './api/customerdetail';

import {getUpdateCustomerProfileData} from './api/updatecustomerprofile';

import {getEmailExistData} from './api/emailexist';

import {getPhoneExistData} from './api/phoneexist';

import {getAddressData} from './api/address';

import {getChangePasswordData} from './api/changepassword';

import {getSecondaryAddRemoveData} from './api/secondaryaddremove';

import {getAllUserSecAddrData} from './api/allsecondaryadd';

import {getPorderHistoryData} from './api/porderhistory';

import {getCorderHistoryData} from './api/corderhistory';

import {getOrderHistoryData, getOrderPdfGenerateData} from './api/orderhistory';

import {getApplyPromotionData} from './api/applypromotion';

import {getResetPasswordData} from './api/resetpassword';

import {getPromotionListData, getReceiptPromotionData} from './api/promotionlist';

import {updateContactUsData} from './api/contactus';

import {getSettingData} from './api/setting';

import {getstaticblkData,getplaceorderinfoData, getPromoData, getfootercontactinfoData, getfootercontentData, getfollowusData, getcontactcontentData} from './api/staticblocks';

import {getcatlistData, getcatoutletData, getcathallData, getcatdateData, getcattimeData, getprolistData, getprodetailData, getcartlistData, getCateringQuotationData, getloginQuotationData} from './api/catering';


import {
	
  REQUEST_STATICBLK_DATA,
  receiveStaicblkData,

  REQUEST_BANNER_DATA,
  receiveBannerData,
  
   REQUEST_PROMO_DATA,
  receivePromoData,

  REQUEST_HEADERORDERINFO_DATA,
  receiveOrderinfoData,

  REQUEST_FOOTERCONTACTINFO_DATA,
  receiveContactinfoData,

  REQUEST_FOOTER_DATA,
  receiveFooterData,

  REQUEST_FOLLOWUS_DATA,
  receiveFollowusData,

  REQUEST_CATLIST_DATA,
  receiveCatlistData,

  REQUEST_CATOUTLET_DATA,
  receiveCatoutletData,

  REQUEST_CATHALL_DATA,
  receiveCathallData,

  REQUEST_CATDATE_DATA,
  receiveCatdateData,

  REQUEST_CATTIME_DATA,
  receiveCattimeData,
  
  
  REQUEST_PROLIST_DATA,
  receiveProlistData,
  
  REQUEST_PRODETAIL_DATA,
  receiveProdetailData,
  
  REQUEST_CARTLIST_DATA,
  receiveCartlistData,
  
  REQUEST_PAGES_DATA,
  receivePageData,
  
  REQUEST_MENU_DATA,
  receiveMenuData,

   REQUEST_FBLOGIN_DATA,
  receiveFbLoginData,
  
  REQUEST_CUSTOMERUPDATE_DATA,
  receiveCustomerUpdateData,
  
  REQUEST_FORGETPASSWORD_DATA,
  receiveForgetPasswordData,
  
  REQUEST_REGISTRATION_DATA,
  receiveRegistrationData,
  
  REQUEST_LOGIN_DATA,
  receiveLoginData,
  
  REQUEST_CUSTOMERDETAIL_DATA,
  receiveCustomerDetailData,
  
  REQUEST_UPDATECUSTOMERPROFILE_DATA,
  receiveUpdateCustomerProfileData,
  
  REQUEST_EMAILEXIST_DATA,
  receiveEmailExistData,
  
  REQUEST_PHONEEXIST_DATA,
  receivePhoneExistData,
  
  REQUEST_ADDRESS_DATA,
  receiveAddressData,
  
  REQUEST_CHANGEPASSWORD_DATA,
  receiveChangePasswordData,
  
  REQUEST_SECONDARYADDREMOVE_DATA,
  receiveSecondaryAddRemoveData,
  
  REQUEST_ALLUSERSECADDR_DATA,
  receiveAllUserSecAddrData,
  
  REQUEST_PORDERHISTORY_DATA,
  receivePorderHistoryData,
  
  REQUEST_CORDERHISTORY_DATA,
  receiveCorderHistoryData,
  
  REQUEST_ORDERHISTORY_DATA,
  receiveOrderHistoryData,
  
  REQUEST_APPLYPROMOTION_DATA,
  receiveApplyPromotionData,
  
  REQUEST_RESETPASSWORD_DATA,
  receiveResetPasswordData,
  
  REQUEST_PROMOTIONLIST_DATA,
  receivePromotionListData,
  
  REQUEST_CONTACTUS_DATA,
  receiveContactUsData,
  
  REQUEST_CONTACTCONTENT_DATA,
  receiveContactcontentData,
  
  REQUEST_SETTING_DATA,
  receiveSettingData,
  
  REQUEST_PRINTORDERRECEIPT_DATA,
  receivePrintOrderReceiptData,
  
  REQUEST_RECEIPTPROMOTION_DATA,
  receiveReceiptPromotionData,
  
  REQUEST_DOWNLOADQUOTATION_DATA,
  receiveDownloadQuotationData,
  
  REQUEST_LOGINDOWNLOADQUOTATION_DATA,
  receiveLoginDownloadQuotationData,
  
} from './actions';


/*Static blocks*/
function* callRequestStaicblkData(action){

  var results = yield call (getstaticblkData, action.payload);

  yield put(receiveStaicblkData(results));

}

export function* requestStaicblkDataSaga(){
  yield takeEvery(REQUEST_STATICBLK_DATA, callRequestStaicblkData);
}

/*Banner*/
function* callRequestBannerData(action){

  var results = yield call (getBannerData, action.payload);

  yield put(receiveBannerData(results));

}

export function* requestBannerDataSaga(){
  yield takeEvery(REQUEST_BANNER_DATA, callRequestBannerData);
}

/*Home promotion Banner*/
function* callRequestPromoData(action){

  var results = yield call (getPromoData, action.payload);
  yield put(receivePromoData(results));

}

export function* requestPromoDataSaga(){
  yield takeEvery(REQUEST_PROMO_DATA, callRequestPromoData);
}

/*Header Place order Info*/
function* callRequestOrderinfoData(action){

  var results = yield call (getplaceorderinfoData, action.payload);

  yield put(receiveOrderinfoData(results));

}

export function* requestOrderinfoDataSaga(){
  yield takeEvery(REQUEST_HEADERORDERINFO_DATA, callRequestOrderinfoData);
}

/*Footer contact Info*/
function* callRequestContactinfoData(action){

  var results = yield call (getfootercontactinfoData, action.payload);

  yield put(receiveContactinfoData(results));

}

export function* requestContactinfoDataSaga(){
  yield takeEvery(REQUEST_FOOTERCONTACTINFO_DATA, callRequestContactinfoData);
}

/*Footer content Info*/
function* callRequestFooterData(action){

  var results = yield call (getfootercontentData, action.payload);

  yield put(receiveFooterData(results));

}

export function* requestFooterDataSaga(){
  yield takeEvery(REQUEST_FOOTER_DATA, callRequestFooterData);
}

/*Footer Follow us info*/
function* callRequestFollowusData(action){

  var results = yield call (getfollowusData, action.payload);

  yield put(receiveFollowusData(results));

}

export function* requestFollowusDataSaga(){
  yield takeEvery(REQUEST_FOLLOWUS_DATA, callRequestFollowusData);
}


/*Get category list*/
function* callRequestCatlistData(action){

  var results = yield call (getcatlistData, action.payload);

  yield put(receiveCatlistData(results));

}

export function* requestCatlistDataSaga(){
  yield takeEvery(REQUEST_CATLIST_DATA, callRequestCatlistData);
}

/*Get outlet list*/
function* callRequestCatoutletData(action){

  var results = yield call (getcatoutletData, action.payload);
  yield put(receiveCatoutletData(results));

}

export function* requestCatoutletDataSaga(){
  yield takeEvery(REQUEST_CATOUTLET_DATA, callRequestCatoutletData);
}

/*Get hall list*/
function* callRequestCathallData(action){

  var results = yield call (getcathallData, action.payload);
  yield put(receiveCathallData(results));

}

export function* requestCathallDataSaga(){
  yield takeEvery(REQUEST_CATHALL_DATA, callRequestCathallData);
}


/*Get Date list*/
function* callRequestCatdateData(action){

  var results = yield call (getcatdateData, action.payload);
  yield put(receiveCatdateData(results));

}

export function* requestCatdateDataSaga(){
  yield takeEvery(REQUEST_CATDATE_DATA, callRequestCatdateData);
}

/*Get Time list*/
function* callRequestCattimeData(action){

  var results = yield call (getcattimeData, action.payload);
  yield put(receiveCattimeData(results));

}

export function* requestCattimeDataSaga(){
  yield takeEvery(REQUEST_CATTIME_DATA, callRequestCattimeData);
}

/*Get Product list*/
function* callRequestProlistData(action){

  var results = yield call (getprolistData, action.payload);
  yield put(receiveProlistData(results));

}

export function* requestProlistDataSaga(){
  yield takeEvery(REQUEST_PROLIST_DATA, callRequestProlistData);
}

/*Get Product detail*/
function* callRequestProdetailData(action){

  var results = yield call (getprodetailData, action.payload);
  yield put(receiveProdetailData(results));

}

export function* requestProdetailDataSaga(){
  yield takeEvery(REQUEST_PRODETAIL_DATA, callRequestProdetailData);
}

/*Get Cart List*/
function* callRequestCartlistData(action){

  var results = yield call (getcartlistData, action.payload);
  yield put(receiveCartlistData(results));

}

export function* requestCartlistDataSaga(){
  yield takeEvery(REQUEST_CARTLIST_DATA, callRequestCartlistData);
}

/*Get Individual page detail*/
function* callRequestPageData(action){
  var results = yield call (getPageData, action.payload);
  yield put(receivePageData(results));

}

export function* requestPageDataSaga(){
  yield takeEvery(REQUEST_PAGES_DATA, callRequestPageData);
}


/*Get Menu*/
function* callRequestMenuData(action){
  var results = yield call (getMenuNavigationData, action.payload);
  yield put(receiveMenuData(results));

}

export function* requestMenuDataSaga(){
  yield takeEvery(REQUEST_MENU_DATA, callRequestMenuData);
}



/*Facebook Login*/
function* callRequestFbLoginData(action){
  var results = yield call (getFbLoginData, action.payload);
  yield put(receiveFbLoginData(results));

}

export function* requestFbLoginDataSaga(){
  yield takeEvery(REQUEST_FBLOGIN_DATA, callRequestFbLoginData);
}


/*Customer Update*/
function* callRequestCustomerUpdateData(action){
  var results = yield call (updateCustomerUpdateData, action.payload);
  yield put(receiveCustomerUpdateData(results));

}

export function* requestCustomerUpdateDataSaga(){
  yield takeEvery(REQUEST_CUSTOMERUPDATE_DATA, callRequestCustomerUpdateData);
}


/*Request Forget Password */

function* callRequestForgetPasswordData(action){
  var results = yield call (getForgetPasswordData, action.payload);
  yield put(receiveForgetPasswordData(results));

}

export function* requestForgetPasswordDataSaga(){
  yield takeEvery(REQUEST_FORGETPASSWORD_DATA, callRequestForgetPasswordData);
}


/*customer Registration*/

function* callRequestRegistrationData(action){
  var results = yield call (getRegistrationData, action.payload);
  yield put(receiveRegistrationData(results));

}

export function* requestRegistrationDataSaga(){
  yield takeEvery(REQUEST_REGISTRATION_DATA, callRequestRegistrationData);
}

/*Login*/

function* callRequestLoginData(action){
  var results = yield call (getLoginData, action.payload);
  yield put(receiveLoginData(results));

}

export function* requestLoginDataSaga(){
  yield takeEvery(REQUEST_LOGIN_DATA, callRequestLoginData);
}


/*Customer Detail*/

function* callRequestCustomerDetailData(action){
  var results = yield call (getCustomerDetailData, action.payload);
  yield put(receiveCustomerDetailData(results));

}

export function* requestCustomerDetailDataSaga(){
  yield takeEvery(REQUEST_CUSTOMERDETAIL_DATA, callRequestCustomerDetailData);
}

/*Customer Profile Update*/

function* callRequestUpdateCustomerProfileData(action){
  var results = yield call (getUpdateCustomerProfileData, action.payload);
  yield put(receiveUpdateCustomerProfileData(results));

}

export function* requestUpdateCustomerProfileDataSaga(){
  yield takeEvery(REQUEST_UPDATECUSTOMERPROFILE_DATA, callRequestUpdateCustomerProfileData);

}


/*Users Email ID Exists*/

function* callRequestEmailExistData(action){
  var results = yield call (getEmailExistData, action.payload);
  yield put(receiveEmailExistData(results));

}

export function* requestEmailExistDataSaga(){
  yield takeEvery(REQUEST_EMAILEXIST_DATA, callRequestEmailExistData);

}


/*Users Email ID Exists*/

function* callRequestPhoneExistData(action){
  var results = yield call (getPhoneExistData, action.payload);
  yield put(receivePhoneExistData(results));

}

export function* requestPhoneExistDataSaga(){
  yield takeEvery(REQUEST_PHONEEXIST_DATA, callRequestPhoneExistData);
  
}


/*Get settings address*/

function* callRequestAddressData(action){
  var results = yield call (getAddressData, action.payload);
  yield put(receiveAddressData(results));

}

export function* requestAddressDataSaga(){
  yield takeEvery(REQUEST_ADDRESS_DATA, callRequestAddressData);
  
}



/* user change Password*/

function* callRequestChangePasswordData(action){
  var results = yield call (getChangePasswordData, action.payload);
  yield put(receiveChangePasswordData(results));

}

export function* requestChangePasswordDataSaga(){
  yield takeEvery(REQUEST_CHANGEPASSWORD_DATA, callRequestChangePasswordData);
  
}


/* Secondary address remove*/

function* callRequestSecondaryAddRemoveData(action){
  var results = yield call (getSecondaryAddRemoveData, action.payload);
  yield put(receiveSecondaryAddRemoveData(results));

}

export function* requestSecondaryAddRemoveDataSaga(){
  yield takeEvery(REQUEST_SECONDARYADDREMOVE_DATA, callRequestSecondaryAddRemoveData);
  
}


/* get all user secondary address*/

function* callRequestAllUserSecAddrData(action){
  var results = yield call (getAllUserSecAddrData, action.payload);
  yield put(receiveAllUserSecAddrData(results));

}

export function* requestAllUserSecAddrDataSaga(){
  yield takeEvery(REQUEST_ALLUSERSECADDR_DATA, callRequestAllUserSecAddrData);
  
}


/*p-order history*/

function* callRequestPorderHistoryData(action){
  var results = yield call (getPorderHistoryData, action.payload);
  yield put(receivePorderHistoryData(results));

}

export function* requestPorderHistoryDataSaga(){
  yield takeEvery(REQUEST_PORDERHISTORY_DATA, callRequestPorderHistoryData);
  
}


/*c-order history*/

function* callRequestCorderHistoryData(action){
  var results = yield call (getCorderHistoryData, action.payload);
  yield put(receiveCorderHistoryData(results));

}

export function* requestCorderHistoryDataSaga(){
  yield takeEvery(REQUEST_CORDERHISTORY_DATA, callRequestCorderHistoryData);
}

/*order history*/

function* callRequestOrderHistoryData(action){
  var results = yield call (getOrderHistoryData, action.payload);
  yield put(receiveOrderHistoryData(results));

}

export function* requestOrderHistoryDataSaga(){
  yield takeEvery(REQUEST_ORDERHISTORY_DATA, callRequestOrderHistoryData);
}


/*Apply promotion*/

function* callRequestApplyPromotionData(action){
  var results = yield call (getApplyPromotionData, action.payload);
  yield put(receiveApplyPromotionData(results));

}

export function* requestApplyPromotionDataSaga(){
  yield takeEvery(REQUEST_APPLYPROMOTION_DATA, callRequestApplyPromotionData);
}

/*Reset Password*/

function* callRequestResetPasswordData(action){
  var results = yield call (getResetPasswordData, action.payload);
  yield put(receiveResetPasswordData(results));

}

export function* requestResetPasswordDataSaga(){
  yield takeEvery(REQUEST_RESETPASSWORD_DATA, callRequestResetPasswordData);
}


/*Promotion list*/

function* callRequestPromotionListData(action){
  var results = yield call (getPromotionListData, action.payload);
  yield put(receivePromotionListData(results));

}

export function* requestPromotionListDataSaga(){
  yield takeEvery(REQUEST_PROMOTIONLIST_DATA, callRequestPromotionListData);
}


/*Contact US*/

function* callRequestContactUsData(action){
  var results = yield call (updateContactUsData, action.payload);
  yield put(receiveContactUsData(results));

}

export function* requestContactUsDataSaga(){
  yield takeEvery(REQUEST_CONTACTUS_DATA, callRequestContactUsData);
}


/*contact content*/
function* callRequestContactcontentData(action){

  var results = yield call (getcontactcontentData, action.payload);
  yield put(receiveContactcontentData(results));

}

export function* requestContactcontentDataSaga(){
  yield takeEvery(REQUEST_CONTACTCONTENT_DATA, callRequestContactcontentData);
}

/*settings*/

function* callRequestSettingData(action){
  var results = yield call (getSettingData, action.payload);
  yield put(receiveSettingData(results));

}

export function* requestSettingDataSaga(){
  yield takeEvery(REQUEST_SETTING_DATA, callRequestSettingData);
}

/*print order receipt*/

function* callRequestPrintOrderReceiptData(action){
  var results = yield call (getOrderPdfGenerateData, action.payload);
  yield put(receivePrintOrderReceiptData(results));

}

export function* requestPrintOrderReceiptDataSaga(){
  yield takeEvery(REQUEST_PRINTORDERRECEIPT_DATA, callRequestPrintOrderReceiptData);
}


/*Receipt Promotion*/

function* callRequestReceiptPromotionData(action){
  var results = yield call (getReceiptPromotionData, action.payload);
  yield put(receiveReceiptPromotionData(results));

}

export function* requestReceiptPromotionDataSaga(){
  yield takeEvery(REQUEST_RECEIPTPROMOTION_DATA, callRequestReceiptPromotionData);
}


/*download quotation*/

function* callRequestDownloadQuotationData(action){
  var results = yield call (getCateringQuotationData, action.payload);
  yield put(receiveDownloadQuotationData(results));

}

export function* requestDownloadQuotationDataSaga(){
  yield takeEvery(REQUEST_DOWNLOADQUOTATION_DATA, callRequestDownloadQuotationData);
}


/*login download quotation*/

function* callRequestLoginDownloadQuotationData(action){
  var results = yield call (getloginQuotationData, action.payload);
  yield put(receiveLoginDownloadQuotationData(results));

}

export function* requestLoginDownloadQuotationDataSaga(){
  yield takeEvery(REQUEST_LOGINDOWNLOADQUOTATION_DATA, callRequestLoginDownloadQuotationData);
}


