import axios from 'axios';
import { appId, apiUrl } from "../../../Helpers/Config";

export async function getPageData(slug){
  return await axios.get(apiUrl+'cms/page?status=A&app_id='+appId+'&page_slug='+slug)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
