/* eslint-disable */

import React, { Component } from 'react';
import validator from 'validator'
import update from 'immutability-helper'
import { validated } from 'react-custom-validation'
import Changepassword from "./Changepassword"
import { Link } from 'react-router-dom';
import { stripslashes, addressFormat, showAlert, callImage } from "../../Helpers/SettingHelper";
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../../Helpers/Config";
import axios from 'axios';
import cookie from 'react-cookies';
import { requestAllUserSecAddrData, requestChangePasswordData } from '../../modules/ui/actions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import $ from "jquery";
   

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';


/*import DatePicker from 'react-datepicker';*/
import MaskedInput from 'react-input-mask';
import moment from 'moment';
/*import 'react-datepicker/dist/react-datepicker.css';*/
import Select from 'react-select';


import paymentImage from "../../common/images/payment.png";
import giftImage from "../../common/images/gift.png";
import logoImage from "../../common/images/logo.png";



var dateFormat = require('dateformat');
var qs = require('qs');

const isEmpty = (value) =>
    value === '' ? 'This field is required.' : null 

const isEmail = (email) =>
    validator.isEmail(email) ? null : 'This is not a valid email.'

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'This is not a valid mobile number.'

const dobpattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
 
function validationConfig(props) {
    const { firstname, lastname, email, mobile, birthdate } = props.fields

    return {
        fields: ['firstname', 'lastname', 'email', 'mobile', 'birthdate'],

        validations: {
            firstname: [
                [isEmpty, firstname]
            ],
            lastname: [
                [isEmpty, lastname]
            ],
            mobile: [[isEmpty, mobile], [isMobile, mobile]],
            email: [[isEmpty, email], [isEmail, email]],
            birthdate: [[isEmpty, birthdate]]
        }
    }
}



class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'Loading',
            secAddrData: [],
            birthdate: '',
            fieldschpassword: {
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''

            }
        };
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.changepassword = this.changepassword.bind(this);
    } 


   

     componentDidMount() {

        var params = "&status=A&refrence=" + cookie.load('UserId');
        this.props.requestAllUserSecAddrData(params);

        


        /* axios.get(apiUrl + "customer/get_all_user_secondary_address?app_id=" + appId + "&status=A&refrence=" + cookie.load('UserId')).then(res => {
            if (res.data.status === "ok") {
                this.setState({ secAddrData: res.data.result_set });
            } else {
                this.setState({ secAddrData: '', secAddrData: [] });
            }
        }); */

    }



    componentWillReceiveProps(nextProps) {
    
        if(nextProps.ChangePasswordData !== this.props.ChangePasswordData){
            const {ChangePasswordData} = nextProps;
            $(".old_password").val('');
                $(".new_password").val('');
                $(".confirm_password").val('');

                if (ChangePasswordData.status === "ok") {
                    
                    showAlert('Success', 'Password changed successfully!');
                    
                } else {
                    if (ChangePasswordData.form_error) {
                        showAlert('Success', ChangePasswordData.form_error);
                        
                    } else {
                        showAlert('Success', ChangePasswordData.message);
                        
                    }
                }
                
                window.$.magnificPopup.open({
                    items: {
                        src: '.alert_popup'
                    },
                    type: 'inline'
                });


        }



    }


    handleChangeDate(datevalue) {
       /* var dateval = new Date(datevalue);
        dateval = moment(dateval).format("DD/MM/YYYY");
        cookie.save('birthdate', dateval);*/
        console.log(datevalue);
        //cookie.save('birthdate', datevalue);
        
       /* this.setState({
            birthdate: datevalue
        });
        this.handleChange("birthdate",datevalue)*/
    }

 

    fieldChange = (field, value) => {
        this.setState(update(this.state, { fieldschpassword: { [field]: { $set: value } } }))
    }

    

    /* To delete the secondary address */
    deleteSecAddr(addrId) {
        var postObject = {
            "app_id": appId,
            "type": "web",
            "secondary_address_id": addrId,
            "refrence": cookie.load('UserId')
        };

        axios.post(apiUrl + "customer/secondary_address_remove", qs.stringify(postObject))
            .then(response => {
                if (response.data.status === "ok") {
                    showAlert('Success', 'Address deleted successfully!');
                    $.magnificPopup.open({
                        items: {
                            src: '.alert_popup'
                        },
                        type: 'inline'
                    });

                } else {
                }

            })
            .catch(function (error) {
                
            });
    }


    /* Get Secondary Address Details */
    secondaryAddresslist() {
        if (this.state.secAddrData.length > 0) {
            return this.state.secAddrData.map((addr, index) =>
                <li key={index}>
                    <a href="javascript:;" >
                        {(addressFormat(addr.unit_code, addr.unit_code2, addr.address, addr.country, addr.postal_code))}
                    </a>
                    <span onClick={() => { this.deleteSecAddr(addr.secondary_address_id) }}></span>
                </li>
            );
        } else {
            return <li>No address found</li>
        }
    }



     loadPostalAddress(postal_code,address1) {
        var postal = $(".customer_postal_code").val();
        alert(postal);
        if(postal.length > 5){
            axios.get(apiUrl + '/settings/get_address?app_id=' + appId + "&zip_code=" + postal)
            .then(res => {
                if (res.data.status === "ok") {
                    var address_name = res.data.result_set.zip_buno + " " + res.data.result_set.zip_sname;
                    $("#customer_address_line1").val(address_name);
                    this.setState({postal :postal });
                    this.setState({address1 :address_name });
                   
                } else {
                    document.getElementById("spn-postalcode-error").innerHTML = '<span class="error">This is not a valid postal code.</span>';
                    $(".customer_postal_code").val('');
                    $("#customer_address_line1").val('');
                }
            });
        }else{
             $(".customer_postal_code").val(postal);
        }
        
	}






    /* Change Password */
    changepassword() {
        var old_password = $(".old_password").val();
        var new_password = $(".new_password").val();
        var confirm_password = $(".confirm_password").val();
        var postObject = {
            "app_id": appId,
            "type": "web",
            "oldpassword": old_password,
            "password": new_password,
            "confirmpassword": confirm_password,
            "refrence": cookie.load('UserId')
        };
        this.props.requestChangePasswordData(qs.stringify(postObject));
 
    }

 

    handleChange = (item, value) => {
        
        this.setState({ ...this.state, [item]: value });
        var des = document.getElementsByClassName('birthdate')

    };


    render() {

     
        var displaySts = (this.state.defaultDispaly === "") ? "none" : this.state.defaultDispaly;

        const { fields, activityPoints, onChange, onValid, onInvalid, $field, $validation } = this.props;


        let PreviousOrderAddressTitle, errMsgFirstname, errMsgLastname, errMsgEmail, errMsgMobile, errMsgPostal, errMsgbirthdate;

        if ($validation.firstname.error.reason !== undefined) {
            errMsgFirstname = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
        }

        if ($validation.lastname.error.reason !== undefined) {
            errMsgLastname = ($validation.lastname.show && <span className="error">{$validation.lastname.error.reason}</span>)
        }

        if ($validation.email.error.reason !== undefined) {
            errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
        }

        if ($validation.mobile.error.reason !== undefined) {
            errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
        }

        if ($validation.birthdate.error.reason !== undefined) {
            errMsgbirthdate = ($validation.birthdate.show && <span className="error">{$validation.birthdate.error.reason}</span>)
        }
        
        var genderlabel = "Male";
        var gender = "M"; 
        if(fields.gender== "M"){
            
            genderlabel = "Male";
            gender = "M"; 
        
            
        }else if(fields.gender=='F'){
            
            genderlabel = "Female";
            gender = "F"; 
        

        }else if(fields.gender=='O'){
            
             genderlabel = "Unspecified";
             gender = "O"; 
            
            
        }
       

        var joined_date = moment(fields.joinedOn).format('DD/MM/YYYY');
        var birthdate = new Date();
        if(this.state.birthdate !==''){
            birthdate = this.state.birthdate;
        }else if(fields.birthdate !==''){
            birthdate = moment(fields.birthdate);
            console.log(birthdate)
            birthdate = birthdate._i;
            console.log(birthdate)
        }

       if(this.props.CustomerDetailData!== undefined){

        return (
            
                <div className="container myacc-tab-1">
                    
                        <div className="tab-content">
                            <div className="tab-pane account-tab-section fade in active">
                                <h4 className="tab_mobtrigger active">Account Information<i></i></h4>
                                <div className="tab_mobrow main_tabin">
                                    <div className="box_in">
                                        <div className="account_sec">
                                            <div className="accmenu_sec">
                                                <div className="accprofile text-center">
                                                    <div className="accprofile_img">
                                                        <img src={fields.photo !== null && fields.photo !== '' && fields.photo !== 'null' ? fields.photo : callImage("", "", 635, 635, timThumpUrl, 'No')} alt="" />
                                                    </div>
                                                    <div className="accprofile_info">
                                                        <p className="text-uppercase">Date Joined</p>
                                                        <p>{joined_date}</p>
                                                    </div>
                                                </div>
                                                <div className="accpoints_sec">
                                                    <div className="accpoints_bx">
                                                        <div className="accpoint_tt text-center">
                                                            <span><img src={logoImage} alt="Ishiro" /></span>
                                                        </div>
                                                        {/*}
                                                        <div className="accpoints_bxin">
                                                            <div className="accearn_points">
                                                                <div className="accearn_pointtop">
                                                                    <i><img src={giftImage} alt="gift" /></i>
                                                                    <h4 className="text-uppercase">Total Points Earned</h4>
                                                                    <span>{activityPoints.reward_ponits || 0}</span>
                                                                </div>
                                                                <p>{(activityPoints.reward_ponits_monthly > 0 ? activityPoints.reward_ponits_monthly + ' Points Expiring In 30 Days' : '')} </p>
                                                            </div>
                                                        </div>
                                                        {*/}
                                                    </div>
                                                </div>
                                                <div className="setting_menu_list">
                                                    <ul>
                                                        <li>
                                                            <a href="#change-password-popup" className="open-popup-link"><i className="setting_menu_list_icon password_icon"></i>Change Password</a></li>
                                                       {/*} <li>
                                                            <a href="javascript:void(0)"><i className="setting_menu_list_icon setting_icon"></i>Settings</a>
                                                            <ul className="myacc_dropmenu">
                                                                <li>
                                                                    <a href="javascript:void(0);">Opt In For Promotions</a>
                                                                    <ul className="tg-list">
                                                                        <li className="tg-list-item">
                                                                            <input className="tgl tgl-light cb1" data-id="push" value="1" defaultChecked id="cb1" type="checkbox" />
                                                                            <label className="tgl-btn notification_settings" data-section="web" data-id="push" htmlFor="cb1"></label>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>*/}
                                                        <li>
                                                            <a href="/logout" ><i className="setting_menu_list_icon logout_icon"></i>Logout</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="accsec_right">
                                                <h1>Hello, {fields.firstname} {fields.lastname}</h1>
                                                <p>My Account Dashboard allows you to view your recent activities, check your reward points and update account information.</p>
                                                <div className="ttsplitter ttsplitter_md text-center">
                                                    <div className="ttsplitter_in">
                                                        <span className="text-uppercase">
                                                            Your Account Information
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="acc-inform">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className={(fields.firstname != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Name</label>
                                                                    <input type="input" id="firstname" className="form-control input-focus" value={fields.firstname || ''} {...$field('firstname', (e) => onChange('firstname', e.target.value)) } />
                                                                    {errMsgFirstname}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className={(fields.lastname != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Last Name</label>
                                                                    <input type="input" value={fields.lastname || ''} {...$field('lastname', (e) => onChange('lastname', e.target.value)) } className="form-control input-focus" />
                                                                    {errMsgFirstname}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className={(fields.customer_nick_name != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>PreferredName</label>
                                                                    <input type="input" name="customer_nick_name" value={fields.nickname || ''} {...$field('nickname', (e) => onChange('nickname', e.target.value)) } className="form-control input-focus" />
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">

                                                                <div className={(fields.mobile != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Mobile Number</label>
                                                                    <input type="input" name="customer_phone" value={fields.mobile || ''} {...$field('mobile', (e) => onChange('mobile', e.target.value)) } className="form-control input-focus" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className={(fields.email != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Email Address</label>
                                                                    <input type="email" name="customer_email" value={fields.email || ''} {...$field('email', (e) => onChange('email', e.target.value)) } className="form-control input-focus" />
                                                                    {errMsgEmail}
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">
																<div className="row">
																	<div className="col-md-6">

																		<div className={(fields.birthdate != '' ? 'focus-out focused' : 'focus-out')}>
																			<label>Birthday</label>
																		 <div className="react_datepicker">
                                                                               {/* <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" 
                                                                                className="form-control" selected={birthdate}   dateFormat="dd/MM/YYYY" onChange={this.handleChangeDate} />*/}
                                                                                
																		<MaskedInput 
																		mask={[ /[0-9]/, /\d/, '/', /\d/, /\dddd/]}
																		className="form-control"
																		guide='false'
																		
																		placeholder="DD/MM/YYYY" 
																		value={birthdate} 
																		onChange={this.handleChangeDate} 

																		/>
                                                                                
                                                                                
                                                                                
                                                                                
																				{errMsgbirthdate}
                                                                            </div>  
																		</div>
																	</div>
																	<div className="col-md-6">

																		<div className="re_select">

																			<Select
                                                                               
                                                                                defaultValue={{ label: genderlabel , value: gender}}
																				onChange={onChange.bind(this, 'gender')}
																				options={[
																					{ value: 'M', label: 'Male' },
																					{ value: 'F', label: 'Female' },
																					{ value: 'O', label: 'Unspecified'},
                                                                                ]}
                                                                               
																			/>

																		</div>

																	</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            

                                                        </div>
                                                    </div>
                                                    <h4 className="form_grouptt">Address</h4>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className={(fields.postal != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Postal Code</label>
                                                                    <input type="input" maxLength="6" id="postal-code" value={fields.postal || ''} {...$field('postal', (e) => onChange('postal', e.target.value)) } className="form-control input-focus customer_postal_code" />
                                                                    <div id="spn-postalcode-error"></div>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-6">

                                                                <div className={(fields.address1 != '' ? 'focus-out focused' : 'focus-out')}>
                                                                    <label>Address Line 1</label>
                                                                    <input type="input" id="customer_address_line1" className="form-control input-focus" value={fields.address1 || ''} {...$field('address1', (e) => onChange('address1', e.target.value)) } />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-6">

                                                                        <div className={(fields.unitnumber1 != '' ? 'focus-out focused' : 'focus-out')}>
                                                                            <label>Unit Number 1</label>
                                                                            <input type="input" className="form-control input-focus" value={fields.unitnumber1 || ''} {...$field('unitnumber1', (e) => onChange('unitnumber1', e.target.value)) } />
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">

                                                                        <div className={(fields.unitnumber2 != '' ? 'focus-out focused' : 'focus-out')}>
                                                                            <label>Unit Number 2</label>
                                                                            <input type="input" className="form-control input-focus" value={fields.unitnumber2 || ''} {...$field('unitnumber2', (e) => onChange('unitnumber2', e.target.value)) } />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn_sec">
                                                        <button type="button" className="btn btn_yellow btn_minwid myaccount_update" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Update</button>
                                                    </div>



                                                </div>
                                                <div className="other-add">
                                                    <div className="ttsplitter ttsplitter_md text-center">
                                                        <div className="ttsplitter_in">
                                                            <span className="text-uppercase">
                                                                Other Address
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="other-add-body mCustomScrollbar">
                                                        <div >
                                                            <ul className="other-add-row">

                                                                {this.secondaryAddresslist()}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    


                <div id="change-password-popup" className="white-popup mfp-hide popup_sec changepw_popup">
                    <div className="pouup_in">
                        <div id="form-msg"></div>
                        <h3 className="title1 text-center">Change Password</h3>
                        <Changepassword fields={this.state.fieldschpassword} onChange={this.fieldChange} onValid={this.changepassword} onInvalid={() => console.log('Form invalid!')} />
                    </div>
                </div>  
            </div>

        );
    }else{
        return <div id="dvLoading"></div>;
    }
    } 
}
Form = validated(validationConfig)(Form)


function mapStateToProps(appState, ownProps) {
    return {
        AllUserSecAddrData: appState.ui.AllUserSecAddrData,
        ChangePasswordData: appState.ui.ChangePasswordData
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      ...bindActionCreators({
        requestAllUserSecAddrData,
        requestChangePasswordData
      }, dispatch)
    }
}
Form.propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }) 
};
export default connect(mapStateToProps, mapDispatchToProps)(Form);


