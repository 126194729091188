import axios from 'axios';
import { appId, apiUrlV2, apiUrl } from "../../../Helpers/Config";

export async function getMenuNavigationData(slug){
  return await axios.get(apiUrl+'menu/menu?app_id='+appId+'&menu_slug='+slug)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error;
  });
  
}
