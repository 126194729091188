/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';

import { stripslashes } from "../../Helpers/SettingHelper";
import { CountryTxt } from "../../Helpers/Config";
var Parser = require('html-react-parser');

import ClockImg from "../../common/images/time.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestCatoutletData,
   requestCathallData,
  requestCatdateData,
  requestCattimeData
} from '../../modules/ui/actions';

class Datetimelist extends Component {

    constructor(props) {
        super(props);

        this.state = {
			catOutletLoading:false,
			CatOutletHtml:'',
			CatHallHtml:'',
			catHallLoading:false,
			catDateLoading:false,
			catTimeLoading:false,
			date_flag_key: "START",
			selected_outletid: '',
			available_datelist: [],
			common_datalist: [],
			time_slotlist: [],
			catering_date: '',
			common_timelist: [],
			timeslot_arr: [],
			time_flag_key: "START",
			interval_time_sat: '15',
			catering_time: '',
			date_errorHtml: '',
			time_errorHtml: '',
			break_timeHtml: '',
			breaktime_enable: 'No',
			breaktime_count: 1,
			breaktime_list: [],
			outletlist: [],
			is_single_outlet: 'No',

			catering_outlet_id:'',
			catering_outlet_name:'',
			catering_hall_id:'',
			catering_hall_name:'',
			catering_hall_postalcode:'',
			catering_halladdress_line:'',
			catering_halladdress:'',
			catering_hall_pax:'',
			catering_hall_charge:''
			
			};
        
        
    }

	componentDidMount()
	{
		/*this.props.requestCatoutletData();
		this.props.requestCathallData();*/

		cookie.save('catering_eventType', 'venue');
		
		this.getAvailabileDate();

	}
	
	componentDidUpdate(props) {
		
		

	}

	componentWillReceiveProps(nextProps) {

		 /*Get date list*/
		 if(typeof nextProps.catdateData != 'undefined') {

			if(nextProps.catdateData.status == 'success' && this.state.catDateLoading == false) {
			
				let dateArrList = [];
				let commonArrLt = [];
				let timeslotArrLt = [];
				let interval_time = 15;

				 dateArrList = nextProps.catdateData.result_set;
				 commonArrLt = nextProps.catdateData.common_data;
				 timeslotArrLt = nextProps.catdateData.timeslot_data;
				 interval_time = nextProps.catdateData.interval_time;
			

				this.setState({'available_datelist': dateArrList});
				this.setState({common_datalist: commonArrLt});
				this.setState({'time_slotlist': timeslotArrLt});
				this.setState({'interval_time_sat': interval_time});

				var catergDate = this.state.catering_date;
				if(catergDate == '' && Object.keys(dateArrList).length > 0) {
					$.each( dateArrList, function(i,e){ 
						 if(e.isclose == 'No') {
							 catergDate = e.fulldate;
							 return false;
						 }
					} );
					
				/*this.setState({'catering_date': catergDate});*/

					this.props.sateValChangefun('catering_date', catergDate);
				}

				var errorHtml = "";
				if(Object.keys(dateArrList).length > 0) {
					this.setState({'date_errorHtml': ''});
				} else {
					this.setState({'date_errorHtml': errorHtml});
				}
				
				this.setState({catDateLoading:true});

				this.setState( { catTimeLoading:false, catering_date: catergDate }, () => { this.getAvailabileTime()});

			}
		}
		
		
		/*Get time list*/ 
		  if(typeof nextProps.cattimeData != 'undefined') {
			  
			  this.setState({catTimeLoading:true});

				let timeArrList = [];
				let comnTmArrLt = [];
				
				if (nextProps.cattimeData.status === "success") {
					 timeArrList = nextProps.cattimeData.result_set;
					 comnTmArrLt = nextProps.cattimeData.common_data;
				}

				let cateringTime = (Object.keys(timeArrList).length > 0)?timeArrList[0].timetext:'';
				this.setState({'timeslot_arr': timeArrList});
				this.setState({'common_timelist': comnTmArrLt});
				
				
				if(this.state.catering_time == '') {
					this.setState({'catering_time': cateringTime});
					this.props.sateValChangefun('catering_time', cateringTime);
				}

				if(Object.keys(timeArrList).length > 0) {
					this.setState({'time_errorHtml': ''});
				}
				
				
		  } 
	}

	
	/*shouldComponentUpdate(nextProps, nextState) {
	  return true;
	}*/
	
	/*Date list*/
	getAvailabileDate() {

		var outletId = this.state.selected_outletid;
		var dateFlagkey = this.state.date_flag_key;
		var nextDate = '';
		var prevDate = '';
		var commonDataList = this.state.common_datalist;
		if(Object.keys(commonDataList).length > 0) {
			nextDate = commonDataList.next_date;
			prevDate = commonDataList.prev_date;
		}

		this.props.requestCatdateData({dateFlagkey:dateFlagkey,outletId:outletId,prevDate:prevDate,nextDate:nextDate});

	}

	getAvailabileDateHtml() {
		var dateList = this.state.available_datelist;
		var cateringDate = this.state.catering_date;

		if(Object.keys(dateList).length > 0) {

			const dateListHtml = this.state.available_datelist.map((dateLst, index) =>
								<div key={index}>
									{(index == 10) ? <li onClick={this.dateAction.bind(this, 'PREV')} className="days-pre-close-disable"><dl className="cl-next-days"><div><span>Go BAck To Previous</span></div></dl></li> : ''}
									<li key={index} className="catering_date" onClick={this.selectDateAction.bind(this, dateLst)}>
										<dl className={(cateringDate == dateLst.fulldate) ? 'date_inner active' : 'date_inner'}>
												{(dateLst.isclose === 'Yes') ? <div className="tag"><span>Not Available</span></div> : ''}
												<div><dt>{dateLst.daytext}</dt>
												<dd>{dateLst.datetext}</dd></div>
										</dl>		
									</li>
									{(index == 12) ? <li onClick={this.dateAction.bind(this, 'NEXT')} className="day_next"><dl className="cl-next-days"><div><span>Show Next Days</span></div></dl></li> : ''}
								</div>); 
             return dateListHtml;
	
		} else {
			var dateListHtml = "";
			return dateListHtml;
		}
	}

	/*Fetch timing list based on select date*/
	selectDateAction(seltDateArr) {

		var seltDate = seltDateArr.fulldate;
		var isclosetxt = seltDateArr.isclose;
		var cateringDate = this.state.catering_date;
		if(seltDate !== '' && cateringDate != seltDate && isclosetxt == 'No') {
			
			this.setState({'catering_time': ''});
				this.props.sateValChangefun('catering_time', '');
			
			this.setState({catTimeLoading:false,'catering_date': seltDate,'time_flag_key': 'START'}, function () { this.getAvailabileTime(); }.bind(this));
		}
	}
	
	/*Date fetching prev and Next*/
	dateAction(actiontxt) {

		var commonDataList = this.state.common_datalist;
		var nextDate = commonDataList.next_date;
		var prevDate = commonDataList.prev_date;
		var outletId = commonDataList.outlet_id;

		/*(outletId !== '') && */
		if(((actiontxt == 'NEXT' && nextDate !== '') || (actiontxt == 'PREV' && prevDate !== ''))) {

			this.setState({catDateLoading:false,'date_flag_key': actiontxt}, function () { this.getAvailabileDate(); }.bind(this));
		}
		
	}

	
	getAvailabileTime() {

		var timeSlotList = this.state.time_slotlist;
		var cateringDate = this.state.catering_date;
		var errorHtml = "";
		this.setState({'time_errorHtml': errorHtml});
		var breakTimeHtml = "";
		this.setState({break_timeHtml: breakTimeHtml});

		/*&& this.state.selected_outletid !== ''*/
		if(Object.keys(timeSlotList).length > 0 && cateringDate !== '') {

			var timeSlotArr = this.getdayBasedTimeList();

			if(Object.keys(timeSlotArr).length > 0) {
				timeSlotArr.map((timeLt, index) =>
					breakTimeHtml+="<option value='"+timeLt+"'>"+timeLt+"</option>"
				);
			}

			var breakTimeDropDown = Parser(breakTimeHtml);
			this.setState({break_timeHtml: breakTimeDropDown});
			this.props.sateValChangefun('break_timeHtml', breakTimeDropDown);

			var time_flagkey = this.state.time_flag_key;
			var intervalTime = this.state.interval_time_sat;

			var nextTime = '';
			var prevTime = '';
			var commonTimeList = this.state.common_timelist;
			if(Object.keys(commonTimeList).length > 0) {
				nextTime = commonTimeList.next_time;
				prevTime = commonTimeList.prev_time;
			}

			var postObject = {
				"time_slotdata": timeSlotArr,
				"interval_time": intervalTime,
				"time_flagkey": time_flagkey,
				"next_time": nextTime,
				"prev_time": prevTime
			};

		this.props.requestCattimeData({time_slotdata:timeSlotArr,interval_time:intervalTime,time_flagkey:time_flagkey,next_time:nextTime,prev_time:prevTime});

		}
	}

	getAvailabileTimeHtml() {
		var timeSlotArry = this.state.timeslot_arr;
		var cateringDate = this.state.catering_date;
		var cateringTime = this.state.catering_time;

		var outletIdTxt = this.state.selected_outletid; /*&& outletIdTxt !== ''*/
		if(Object.keys(timeSlotArry).length > 0 && cateringDate !== '') {
			const timeListHtml = timeSlotArry.map((timeLst, index) =>
								<div key={index}>
									{(index == 10) ? <li onClick={this.timeAction.bind(this, 'PREV')} className="time-pre-close-disable"><dl className="cl-next-days"><div><span>Go BAck To Previous</span></div></dl></li> : ''}
									<li key={index} className="catering_time" onClick={this.selectTimeAction.bind(this, timeLst)}>
										<dl className={(cateringTime == timeLst.timetext) ? 'time_inner active' : 'time_inner'}>
												<div>
													{(timeLst.isclose === 'Yes') ? <div className="tag"><span>Not Available</span></div> : ''}
												    <dt>Time Range</dt>
													<dd>{timeLst.timetext}</dd>
												</div>
										</dl>		
									</li>
									{(index == 12) ? <li onClick={this.timeAction.bind(this, 'NEXT')} className="catering_time"><dl className="cl-next-days"><div><span>Show Next Time Range</span></div></dl></li> : ''}
								</div>); 
             return timeListHtml;			
			
		} else {
			return (<div className="timelist_norecord"> No time slot available. </div>);
		}
	}
	
	timeAction(actiontxt) {
		
		var commonTimeList = this.state.common_timelist;
		var cateringDate = this.state.catering_date;
		var nextTime = commonTimeList.next_time;
		var prevTime = commonTimeList.prev_time;
		if((cateringDate !== '') && ((actiontxt == 'NEXT' && nextTime !== '') || (actiontxt == 'PREV' && prevTime !== ''))) {

			this.setState({catTimeLoading:false,'time_flag_key': actiontxt}, function () { this.getAvailabileTime(); }.bind(this));
		}
		
		/*catTimeLoading:false,*/
		
	}
	
	selectTimeAction(seltTimeArr) {

		var seltTime = seltTimeArr.timetext;
		var isclosetxt = seltTimeArr.isclose;
		var cateringTime = this.state.catering_time;

		if(seltTime !== '' && cateringTime != seltTime && isclosetxt == 'No') {
			this.setState({'catering_time': seltTime});

			this.props.sateValChangefun('catering_time', seltTime);
			this.resetErrorClass();
		}
	}

	getdayBasedTimeList() {
		var timeSltList = this.state.time_slotlist;
		var caterngDate = this.state.catering_date;
	    var selectedDate = new Date(caterngDate); 
		var dayintt = selectedDate.getDay();
		
		var currentslotData = [];
		if(dayintt == 0) {
			currentslotData = timeSltList.sun;
		}
		if(dayintt == 1) {
			currentslotData = timeSltList.mon;
		}
		if(dayintt == 2) {
			currentslotData = timeSltList.tue;
		}
		if(dayintt == 3) {
			currentslotData = timeSltList.wed;
		}
		if(dayintt == 4) {
			currentslotData = timeSltList.thu;
		}
		if(dayintt == 5) {
			currentslotData = timeSltList.fri;
		}
		if(dayintt == 6) {
			currentslotData = timeSltList.sat;
		}
		
		return currentslotData;
	}
	

	handleChangeBreak(event) 
	{
		 var brkCnt = event.target.value;
		 brkCnt = (brkCnt !== '') ? brkCnt : 0;
		 brkCnt = (parseInt(brkCnt) < 5) ? brkCnt : 5;
		 this.setState({breaktime_count: brkCnt});
		 this.props.sateValChangefun('breaktime_count', brkCnt);
		 this.resetErrorClass();
	}
	
	handleClickBreak(type) 
	{
		var brkTimeCnt = this.state.breaktime_count;
		if(type === 'incs') {
			var brkCnt = document.getElementById("brk_time_count").value;
			brkCnt = (brkCnt !== '') ? brkCnt : 0;
			if(parseInt(brkCnt) < 5) {
				brkTimeCnt = parseInt(brkCnt) + 1;
			}
		} else if(type === 'decs') {
			var brkCnt = document.getElementById("brk_time_count").value;
			brkCnt = (brkCnt !== '') ? brkCnt : 0;
			if(parseInt(brkCnt) > 1) {
				brkTimeCnt = parseInt(brkCnt) - 1;
			}
		}
		 this.setState({breaktime_count: brkTimeCnt});
		 this.props.sateValChangefun('breaktime_count', brkTimeCnt);
		 this.resetErrorClass();
	}
	
	handleClickBtn(type)
	{

		var NoBtnelement = document.getElementById("nobtn_id");
		var YesBtnelement = document.getElementById("yesbtn_id");
		NoBtnelement.classList.remove("yes");
		NoBtnelement.classList.remove("no");
		YesBtnelement.classList.remove("yes");
		YesBtnelement.classList.remove("no");
		if(type === 'No') {
			NoBtnelement.classList.add("yes");
			YesBtnelement.classList.add("no");
			document.getElementById("breaktime_div").style.display = "none";
		} else if(type === 'Yes') {
			NoBtnelement.classList.add("no");
			YesBtnelement.classList.add("yes");
			document.getElementById("breaktime_div").style.display = "block";
		}
		this.setState({'breaktime_enable': type});
		this.props.sateValChangefun('breaktime_enable', type);
		this.resetErrorClass();
	}
	
	
	chkBrkTmFun(type,value) {
		var breaktimeEnable = this.state.breaktime_enable;
		var divShowText = 'none';
		var NoBtnClass = 'btn yes';
		var YesBtnClass = 'btn no';
		if(breaktimeEnable === 'Yes') {
			divShowText = 'block';
			NoBtnClass = 'btn no';
			YesBtnClass = 'btn yes';
		}
		
		if(type === 'text') {
			return divShowText;
		} else if(type === 'class' && value === 'Yes') {
			return YesBtnClass;
		} else if(type === 'class' && value === 'No') {
			return NoBtnClass;
		}
	}
	
	getBreakTimeHtml() {
		var breaktime_count = this.state.breaktime_count;
		var breakTimeList = this.state.breaktime_list;
		var breakCountArr = Array()
		if(breaktime_count > 0) {
			var i;
			for (i = 0; i < breaktime_count; i++) {
			  var brkVl = (!(i in breakTimeList)) ? '' : breakTimeList[i];	
			  breakCountArr[i] = brkVl;
			} 
		}
		
		if(Object.keys(breakCountArr).length > 0) {
			const breakListHtml = breakCountArr.map((breakm, index) =>
												<div key={index} className="time">
												<label className="col-sm-6">Select Your Break Time { index + 1 }</label>
												<div className="col-sm-6">
													<div className="form-group">
													<div className="input_area input_lefticons custom_select">
													<span className="ileft_ico"><img src={ClockImg} /></span>
													<select name="breaktime_inp" value={breakm} className="form-control datetimepicker filled breaktime_listcls" onChange={this.handleBrkTimeCreation.bind(this,index)} id={"breaktime-"+index}>
														{this.state.break_timeHtml}
													</select>
													</div>
													</div>
												</div>
												</div>);
			 return breakListHtml;											
		} else {
			var breakListHtml = '';
			return breakListHtml;		
		}
	}
	
    
    handleBrkTimeCreation(indFlg,event) {
		var brkTmVal = event.target.value;
		var breakTimeList = this.state.breaktime_list;
		breakTimeList[indFlg] = brkTmVal;
		this.setState({breaktime_list: breakTimeList});
	}
	
	resetErrorClass() {
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('pkgerror_out');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('bdr_bf_select');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').addClass('bdr_bf_select');
		$('.venuehall_error, .datetimediv_error, .breaktimediv_error').hide();
	}

    submitVenueData() {
		
		var eventType = cookie.load('catering_eventType');
		var isError = "No"; 
		
		/* validation start 
		if(((eventType === "hall") && (this.state.catering_outlet_id === '' || this.state.catering_hall_id === '' || this.state.catering_hall_postalcode === '' || this.state.catering_hall_charge === '')) || ((eventType === "venue") && (this.state.catering_outlet_id === '' || this.state.catering_outlet_postalcode === '' || this.state.catering_outlet_address === ''))) {*/

		if(eventType !== "hall" && eventType !== "venue") {
				isError = "Yes";
				$('.choose_event_data').removeClass('bdr_bf_select');
				$('.choose_event_data').addClass('pkgerror_out');
				$('.venuehall_error').show();
				window.scrollTo(0, 500);
				return false;
		}

		if(this.state.catering_date === '' || this.state.catering_time === '') {
				isError = "Yes";
				$('.date_list_maindiv').removeClass('bdr_bf_select');
				$('.date_list_maindiv').addClass('pkgerror_out');
				$('.datetimediv_error').show();
				window.scrollTo(0, 630);
				return false;
		}	
		
		var breaktimeList = Array();

		if(this.state.breaktime_enable === 'Yes') {
			var listCtArr = Array(); var dulb_entry = 'no';
			$('.breaktime_listcls').each(function(index){
				var brkval = $(this).val();
				if(listCtArr.indexOf(brkval) != -1) {
					dulb_entry = 'yes';
				}
				if(brkval != '' && brkval !== null) {
					listCtArr.push(brkval);
					breaktimeList.push(brkval);
				}
			});
			
			var brkCountTxt = this.state.breaktime_count;
			var errorTxt = '';
			if(brkCountTxt !== listCtArr.length) {
				errorTxt = 'Please select Break Time';
			} else if(dulb_entry === 'yes') {
				errorTxt = 'Please select valide Break Time';
			}
			
			if(errorTxt !== '') {
				isError = "Yes";
				$('.breaktime_main_div').removeClass('bdr_bf_select');
				$('.breaktime_main_div').addClass('pkgerror_out');
				$('.breaktimediv_error').show();
				$('.breaktime_error_msg').html(errorTxt);
				return false;
			}
		}
		this.setState({'breaktime_list': breaktimeList});

		/* validation end */
		if(isError === "No") {
			
			this.props.sateValChangefun('catering_date', this.state.catering_date);
			this.props.sateValChangefun('catering_time', this.state.catering_time);
			this.props.sateValChangefun('breaktime_enable', this.state.breaktime_enable);
			this.props.sateValChangefun('breaktime_count', this.state.breaktime_count);
			this.props.sateValChangefun('breaktime_list', breaktimeList);
			this.props.sateValChangefun('break_timeHtml', this.state.break_timeHtml);
			this.props.sateValChangefun('outlet_id', this.state.catering_outlet_id);
			this.props.sateValChangefun('hall_id', this.state.catering_hall_id);
			
			/*cookie.save('cateringOutletId', this.state.catering_outlet_id);
			cookie.save('cateringOutletName', this.state.catering_outlet_name);
			
			cookie.save('cateringSelectedOutlet', this.state.catering_selected_outlet);
			cookie.save('cateringOutletPostalcode', this.state.catering_outlet_postalcode);
			cookie.save('cateringOutletaddressLine', this.state.catering_outletaddress_line);
			cookie.save('cateringOutletAddress', this.state.catering_outlet_address);*/
			
			cookie.save('cateringHallId', this.state.catering_hall_id);
			cookie.save('cateringHallName', this.state.catering_hall_name);
			cookie.save('cateringHallPostalcode', this.state.catering_hall_postalcode);
			cookie.save('cateringHalladdressLine', this.state.catering_halladdress_line);
			cookie.save('cateringHalladdress', this.state.catering_halladdress);
			cookie.save('cateringHallPax', this.state.catering_hall_pax);
			cookie.save('cateringHallCharge', this.state.catering_hall_charge);
			
			cookie.save('cateringDate', this.state.catering_date);
			cookie.save('cateringTime', this.state.catering_time);
			cookie.save('breaktimeEnable', this.state.breaktime_enable);
			cookie.save('breaktimeCount', this.state.breaktime_count);
			cookie.save('breaktimeList', breaktimeList);
			cookie.save('break_timeHtml',  this.state.break_timeHtml);
			
			this.props.setActiveTab(3);
		}
		
	}
    
    resetVenueHallDat() {
		
		var tempVal = '';
		
		this.setState({catering_selected_outlet: tempVal});
		this.setState({catering_outlet_id: tempVal});
		this.setState({catering_outlet_name: tempVal});
		this.setState({catering_outlet_postalcode: tempVal});
		this.setState({catering_outletaddress_line: tempVal});
		this.setState({catering_outlet_address: tempVal});
		
		this.setState({catering_hall_id: tempVal});
		this.setState({catering_hall_name: tempVal});
		this.setState({catering_hall_postalcode: tempVal});
		this.setState({catering_halladdress_line: tempVal});
		this.setState({catering_halladdress: tempVal});
		this.setState({catering_hall_pax: tempVal});
		this.setState({catering_hall_charge: tempVal});
		
		/*$('.hall-li').removeClass('active');*/
	}
	
	resetErrorClass() {
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('pkgerror_out');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').removeClass('bdr_bf_select');
		$('.choose_event_data, .date_list_maindiv, .breaktime_main_div').addClass('bdr_bf_select');
		$('.venuehall_error, .datetimediv_error, .breaktimediv_error').hide();
	}
    
    setPropActiveTab(tagvalue) {
		var tabflag = this.props.categoryState.active_tabflag;
		if(parseInt(tabflag) !== parseInt(tagvalue)) {
			this.props.setActiveTab(parseInt(tagvalue));
		} else {
			this.props.sateValChangefun('active_tabflag', 0);
		}
	}
    
    render() {

        return (<>
				{/* <h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(2)} onClick={this.setPropActiveTab.bind(this,2)} >VENUE DETAILS<i></i></h4>		 */}

				  <div className="container_720 tb-padding tab_mobrow" style={{display:this.props.showsActiveDiv(2)}}>

					{/*}

						<h4>Choose Event Location</h4>
							   
							   <div className="choose_location" pkgerror_out> 
								  
								  <div className="choose_left">
									 <div className="form-group">
										<div className="custom_radio"> 
										   <input type="radio" name="change_event_type" className="change_event_type venue_check" value="venue" defaultChecked={this.defaultEventType('venue')} onChange={this.changeEventType.bind(this,'venue')}/> <span>Own Venue</span>
										</div>
									 </div>
								  </div>
								  <div className="choose_right">
									 <div className="form-group">
										<div className="custom_radio"> 
										   <input type="radio" name="change_event_type" className="change_event_type hall_check" value="hall" defaultChecked={this.defaultEventType('hall')} onChange={this.changeEventType.bind(this,'hall')} /> <span>Ishiro Location</span>
										</div>
									 </div>
								  </div>
								  {}
								  <div className="pkgerror_txt">Please select Your Event Location</div>
								  {}
							   </div>

								<div className="outlet_select_sec outlet_sec_cls ownvenue_details" id="ownvenueDetails">
	
	
									<div id='no_outlet_info' style={{background: '#e6652c', border: '2px solid #ea3744', color:'#fff', borderRadius: '5px', padding: '5px', marginBottom:'10px'}}>
									<i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="info_span">Sorry!. Outlets didn't available for catering..</span>
									</div>
									<div id='error_info_div' style={{background: '#e6652c', border: '2px solid #ea3744', color:'#fff', borderRadius: '5px', padding: '5px', marginBottom:'10px', display: 'none'}}>
									<i className="fa fa-exclamation-triangle" aria-hidden="true"></i> <span id="error_span">Please select the outlet.</span>
									</div>
								
								   <div className="form-group" id="outlet_list_div">
									  <label className="col-sm-6">Choose Outlet</label>
									  <div className="col-sm-6 custom-select" >
										<select value={this.state.catering_selected_outlet} name="catering_outlet_id" className="form-control" onChange={this.handleChange.bind(this)}>
											{this.state.CatOutletHtml}
										</select>
										 <span className="cal_icon"><b></b></span>
									  </div>
								   </div>
								</div>

								<div className="outlet_select_sec hall_sec_cls catering_hall_list" id="cateringHallList">
								   <div className="form-group">
									  <label className="col-sm-6">Choose Your Ishiro Location</label>
									  <div className="col-sm-6 custom-select">
										<select value={this.state.catering_selected_outlet} name="catering_hall_id" className="form-control" >
										{this.state.CatHallHtml} 
										</select>
										 <span className="cal_icon"><b></b></span>
									  </div>
								   </div>
								</div>
					{*/}

					<h4>Choose Date & Time</h4>

				   <div className="date_list">

						<h3>Choose Event Date</h3>
						  <div className="res-datetime-picker">
							 <ul className="catering_days_ul">
								{this.getAvailabileDateHtml()}
							 </ul>
							 <div className="clearfix"></div>
							 <p>{/*}Catering not available fors 21st, please call 123444545 fors more informations{*/}</p> 
						  </div>

						  <h3>Choose Event Time</h3>
						  <div className="res-datetime-picker">
							 <ul className="catering_time_ul">
							 {this.getAvailabileTimeHtml()}
							 </ul>
							 <div className="clearfix"></div>
							 <p>{'Timing will change based on availability. please wait for confirmation from our staff.'}</p>
						  </div>
					</div>

					<div className="time_slot breaktime_main_div">
					  <h4>{'Ordering for more time slots in a day ?'}</h4>

						  <div className="order-btn">
							 <button type="button" id="nobtn_id" className={this.chkBrkTmFun('class','No')} onClick={this.handleClickBtn.bind(this,'No')}>No</button>

							 <button type="button" id="yesbtn_id" className={this.chkBrkTmFun('class','Yes')} onClick={this.handleClickBtn.bind(this,'Yes')}>Yes</button>
						  </div>

						 <div id="breaktime_div" style={{display:this.chkBrkTmFun('text','')}} >
							  <div className="add_time" >
								 <div className="label">Numbers of Break Time</div>
								 <div className="qty_bx catqty_bx"> 
									<span className="qty_minus" id="dec_cnt" onClick={this.handleClickBreak.bind(this,'decs')}>-</span>
									<input type="text" name="ksafj1" id="brk_time_count" value={this.state.breaktime_count} onChange={this.handleChangeBreak.bind(this)}/> 
									<span className="qty_plus" id="inc_cnt" onClick={this.handleClickBreak.bind(this,'incs')}>+</span> 

								 </div>
							  </div>

							 { this.getBreakTimeHtml() }
						</div>  
						  
				   </div>
				   
				   <div className="continue">
				   <a href="javascript:void(0);" className="btn btn_pink btn-block" onClick={this.submitVenueData.bind(this)}>continue</a>
				   </div>

					</div>

				</>);

     }

}

function mapStateToProps(appState, ownProps) {

  return {

    catoutletData: appState.ui.catoutletData,
    cathallData: appState.ui.cathallData,
    catdateData: appState.ui.catdateData,
    cattimeData: appState.ui.cattimeData

  }

}

function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({

     requestCatoutletData,
     requestCathallData,
	 requestCatdateData,
     requestCattimeData
    }, dispatch)
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(Datetimelist);
