/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";

import { stripslashes, hideLoader } from "../../Helpers/SettingHelper";

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

var Parser = require('html-react-parser');


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  requestPageData,
} from '../../modules/ui/actions';


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';



class Pages extends Component {

    constructor(props) {
        super(props);

        this.state = {pagedetail:''};

    }

     

    componentDidMount() {
		 const {page_slug}= this.props.match.params;
		 this.props.requestPageData(page_slug);
    }
    
    componentWillReceiveProps(nextProps) {
 
		if (this.props.match.params.page_slug !== nextProps.match.params.page_slug) {
			
			
			if ($(".trigger_menu").length > 0) {

				$('.trigger_menu').toggleClass('active');
				
				if ($('.hmenu_list').hasClass('open')) {
                $('.mega_menu').slideUp();
				}
				$('.hmenu_list').toggleClass('open');

			}
			
			
			this.props.requestPageData(nextProps.match.params.page_slug);
	  
			$('html, body').animate({ scrollTop: 0 }, 800); 
			
		}

	}


    render() {		
		 
			
			if(this.props.pageData && this.props.pageData.status==='ok'){
			const{pageData} = this.props;

		 
         
        return (
				
				 <div className="Pages">
		  
		  {/* Header start */}
		  <Header categoryState={this.state} setActiveTab={this.setActiveTab} />
		  {/* Header End */}
		  
		  <div className="container catering">
		  
		  <div className="breadcrumb">
			<div className="breadcrumb_inner">
			<div className="breadcrumb_left">
				<ul>
					<li><p><a href="/">Home</a><i className="fa fa-chevron-right"></i></p></li>
					<li><p><a href="javascript:void(0)">{pageData.result_set[0].cmspage_title && pageData.result_set[0].cmspage_title}</a></p></li>
				</ul>
			</div>
			</div><div className="clearfix"></div>
		  </div>
		  
		  </div>
	  
		  <div className="cms-page">
			<div className="container cms-content">
				<h3>{pageData.result_set[0].cmspage_title}</h3>
				{Parser(pageData.result_set[0].cmspage_description)}
			</div>
		  </div>	
	  
		 <Footer />	
		</div>
		
   );
			}else if(this.props.pageData && this.props.pageData.status==='error'){
				 return (<div>
					<h3>No match for <code>{location.pathname}</code></h3>
				</div>);
			}else{
				return (
				
					<div id="dvLoading"></div> );

			}
				

    }
}

function mapStateToProps(appState, ownProps) {
  return {
    pageData: appState.ui.PageData
    
   
  }
  
   
}


function mapDispatchToProps(dispatch) {

  return {
    ...bindActionCreators({
      requestPageData
    }, dispatch)
  }
}




Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
